import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const TxGroupValidator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
    let isError = false;
    const error = {
        noCardToken: false,
        noInstrToken: false,
        noTxType: false,
    }
    const txType = group.get('txIndicatorType');
    const cardToken = group.get('payCardToken');
    const instrToken = group.get('payInstrToken');

    if (txType.value === 'RECURRENT' || txType.value === 'NOSHOW' || txType.value === 'DELAYCHARGE') {
        if (!cardToken.value) {
            isError = true;
            error.noCardToken = true;
        }
        if (!instrToken.value) {
            isError = true;
            error.noInstrToken = true;
        }
    }

    if (cardToken.value && !instrToken.value) {
        isError = true;
        error.noInstrToken = true;
    }

    if (cardToken.value && !txType.value) {
        isError = true;
        error.noTxType = true;
    }

    return isError ? error : null;

};
