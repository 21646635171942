
export class Pdf {
    title_version_IT: string;
    title_version_EN: string;
    visibility: string;
    file: File;

    constructor(pdf) {
        this.title_version_IT = pdf.title_version_IT;
        this.title_version_EN = pdf.title_version_EN;
        this.visibility = pdf.visibility;
        this.file = pdf.file ? new File(pdf.file) : null;
    }

}

export class File {
    url: string;
    name: string;

    constructor(file){
        this.url = file.url;
        this.name = file.name;
    }
}

export class Sdk {

    title_version: string;
    file: File;
    sdk_type: string;

    constructor(sdk) {
        this.title_version = sdk.title_version;
        this.file = sdk.file ? new File(sdk.file) : null;
        this.sdk_type = sdk.sdk_type;
    }

}

export class Plugin {
    id: string;
    title_version: string;
    file: File;
    icon: File;
    plugin_versions: Array<Plugin>;

    constructor(plugin) {
        this.id = plugin.id;
        this.title_version = plugin.title_version;
        this.file = plugin.file ? new File(plugin.file) : null;
        this.icon = plugin.icon ? new File(plugin.icon) : null;
        this.plugin_versions = plugin.plugin_versions;
    }

}

export class TextIntro {
  textIntroIT: string;
  textIntroEN: string;

  constructor(textIntro) {
    this.textIntroEN = textIntro.text_Intro_EN;
    this.textIntroIT = textIntro.text_Intro_IT;
  }
}
