import { Component, Input,  Optional, Self, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgControl, ControlValueAccessor } from '@angular/forms';
import { MatFormFieldControl} from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-intl-phone',
  templateUrl: './intl-phone.component.html',
  styleUrls: ['./intl-phone.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: IntlPhoneComponent}],
})

export class IntlPhoneComponent  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
  static nextId = 0;

  parts: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'example-tel-input';
  id = `example-tel-input-${IntlPhoneComponent.nextId++}`;
  describedBy = '';
  onChange = (_: any) => {};
  onTouched = () => {};
  countries: { 'prefix': string; 'name': string; 'code': string; }[];

  get empty() {
    const {value: {area, subscriber}} = this.parts;

    return !area && !subscriber;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    return this.countries.find(x => x.code == this.parts.controls.area.value).prefix + ' ' + this.parts.controls.subscriber.value;
  }

  set value(tel: string | null) {
    if (tel.indexOf(' ') > 0){
      let valuesArray = tel.split(' ');
      this.parts.controls.area.setValue(this.countries.find(x => x.prefix == valuesArray[0]).code);
      this.parts.controls.subscriber.setValue(valuesArray[1]);
    }
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(tel: string | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  _handleInput(): void {
    if (this.parts.controls.area.value != '') {
      this.onChange(this.countries.find(x => x.code == this.parts.controls.area.value).prefix + ' ' + this.parts.controls.subscriber.value);
    } else {
      this.onChange(' ' + this.parts.controls.subscriber.value);
    }

  }

  constructor(
    formBuilder: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  )
  {

    this.parts = formBuilder.group({
      area: [''],
      subscriber: ['']
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.countries = [
      {
          'prefix': '+39',
          'name': 'ITALIA',
          'code': 'it'
      },
      {
          'prefix': '+93',
          'name': 'AFGHANISTAN',
          'code': 'af'
      },
      {
          'prefix': '+355',
          'name': 'ALBANIA',
          'code': 'al'
      },
      {
          'prefix': '+213',
          'name': 'ALGERIA',
          'code': 'dz'
      },
      {
          'prefix': '+376',
          'name': 'ANDORRA',
          'code': 'ad'
      },
      {
          'prefix': '+244',
          'name': 'ANGOLA',
          'code': 'ao'
      },
      {
          'prefix': '+966',
          'name': 'ARABIA SAUDITA',
          'code': 'sa'
      },
      {
          'prefix': '+54',
          'name': 'ARGENTINA',
          'code': 'ar'
      },
      {
          'prefix': '+374',
          'name': 'ARMENIA',
          'code': 'am'
      },
      {
          'prefix': '+297',
          'name': 'ARUBA',
          'code': 'aw'
      },
      {
          'prefix': '+61',
          'name': 'AUSTRALIA',
          'code': 'au'
      },
      {
          'prefix': '+43',
          'name': 'AUSTRIA',
          'code': 'at'
      },
      {
          'prefix': '+973',
          'name': 'BAHREIN',
          'code': 'bh'
      },
      {
          'prefix': '+880',
          'name': 'BANGLADESH',
          'code': 'bd'
      },
      {
          'prefix': '+32',
          'name': 'BELGIO',
          'code': 'be'
      },
      {
          'prefix': '+501',
          'name': 'BELIZE',
          'code': 'bz'
      },
      {
          'prefix': '+229',
          'name': 'BENIN',
          'code': 'bj'
      },
      {
          'prefix': '+975',
          'name': 'BHUTAN',
          'code': 'bt'
      },
      {
          'prefix': '+375',
          'name': 'BIELORUSSIA',
          'code': 'by'
      },
      {
          'prefix': '+591',
          'name': 'BOLIVIA',
          'code': 'bo'
      },
      {
          'prefix': '+387',
          'name': 'BOSNIA ERZEGOVINA',
          'code': 'ba'
      },
      {
          'prefix': '+267',
          'name': 'BOTSWANA',
          'code': 'bw'
      },
      {
          'prefix': '+55',
          'name': 'BRASILE',
          'code': 'br'
      },
      {
          'prefix': '+673',
          'name': 'BRUNEI',
          'code': 'bn'
      },
      {
          'prefix': '+359',
          'name': 'BULGARIA',
          'code': 'bg'
      },
      {
          'prefix': '+226',
          'name': 'BURKINA FASO',
          'code': 'bf'
      },
      {
          'prefix': '+257',
          'name': 'BURUNDI',
          'code': 'bi'
      },
      {
          'prefix': '+855',
          'name': 'CAMBOGIA',
          'code': 'kh'
      },
      {
          'prefix': '+237',
          'name': 'CAMERUN',
          'code': 'cm'
      },
      {
          'prefix': '+1',
          'name': 'CANADA',
          'code': 'ca'
      },
      {
          'prefix': '+238',
          'name': 'CAPO VERDE',
          'code': 'cv'
      },
      {
          'prefix': '+235',
          'name': 'CIAD',
          'code': 'td'
      },
      {
          'prefix': '+56',
          'name': 'CILE',
          'code': 'cl'
      },
      {
          'prefix': '+86',
          'name': 'CINA',
          'code': 'cn'
      },
      {
          'prefix': '+357',
          'name': 'CIPRO',
          'code': 'cy'
      },
      {
          'prefix': '+57',
          'name': 'COLOMBIA',
          'code': 'co'
      },
      {
          'prefix': '+269',
          'name': 'COMORE',
          'code': 'km'
      },
      {
          'prefix': '+242',
          'name': 'CONGO',
          'code': 'cg'
      },
      {
          'prefix': '+682',
          'name': 'COOK',
          'code': 'ck'
      },
      {
          'prefix': '+850',
          'name': 'COREA DEL NORD',
          'code': 'kp'
      },
      {
          'prefix': '+82',
          'name': 'COREA DEL SUD',
          'code': 'kr'
      },
      {
          'prefix': '+225',
          'name': 'COSTA D AVORIO',
          'code': 'ci'
      },
      {
          'prefix': '+506',
          'name': 'COSTARICA',
          'code': 'cr'
      },
      {
          'prefix': '+385',
          'name': 'CROAZIA',
          'code': 'hr'
      },
      {
          'prefix': '+53',
          'name': 'CUBA',
          'code': 'cu'
      },
      {
          'prefix': '+45',
          'name': 'DANIMARCA',
          'code': 'dk'
      },
      {
          'prefix': '+593',
          'name': 'ECUADOR',
          'code': 'ec'
      },
      {
          'prefix': '+20',
          'name': 'EGITTO',
          'code': 'eg'
      },
      {
          'prefix': '+503',
          'name': 'EL SALVADOR',
          'code': 'sv'
      },
      {
          'prefix': '+971',
          'name': 'EMIRATI ARABI UNITI',
          'code': 'ea'
      },
      {
          'prefix': '+291',
          'name': 'ERITREA',
          'code': 'er'
      },
      {
          'prefix': '+372',
          'name': 'ESTONIA',
          'code': 'ee'
      },
      {
          'prefix': '+251',
          'name': 'ETIOPIA',
          'code': 'et'
      },
      {
          'prefix': '+7',
          'name': 'FEDERAZIONE RUSSA',
          'code': 'ru'
      },
      {
          'prefix': '+679',
          'name': 'FIGI',
          'code': 'fj'
      },
      {
          'prefix': '+63',
          'name': 'FILIPPINE',
          'code': 'ph'
      },
      {
          'prefix': '+358',
          'name': 'FINLANDIA',
          'code': 'fi'
      },
      {
          'prefix': '+33',
          'name': 'FRANCIA',
          'code': 'fr'
      },
      {
          'prefix': '+241',
          'name': 'GABON',
          'code': 'ga'
      },
      {
          'prefix': '+220',
          'name': 'GAMBIA',
          'code': 'gm'
      },
      {
          'prefix': '+995',
          'name': 'GEORGIA',
          'code': 'ge'
      },
      {
          'prefix': '+49',
          'name': 'GERMANIA',
          'code': 'de'
      },
      {
          'prefix': '+233',
          'name': 'GHANA',
          'code': 'gh'
      },
      {
          'prefix': '+81',
          'name': 'GIAPPONE',
          'code': 'jp'
      },
      {
          'prefix': '+350',
          'name': 'GIBILTERRA',
          'code': 'gi'
      },
      {
          'prefix': '+253',
          'name': 'GIBUTI',
          'code': 'dj'
      },
      {
          'prefix': '+962',
          'name': 'GIORDANIA',
          'code': 'jo'
      },
      {
          'prefix': '+44',
          'name': 'GRAN BRETAGNA',
          'code': 'gb'
      },
      {
          'prefix': '+30',
          'name': 'GRECIA',
          'code': 'gr'
      },
      {
          'prefix': '+299',
          'name': 'GROENLANDIA',
          'code': 'gl'
      },
      {
          'prefix': '+590',
          'name': 'GUADALUPA',
          'code': 'gp'
      },
      {
          'prefix': '+671',
          'name': 'GUAM',
          'code': 'gu'
      },
      {
          'prefix': '+502',
          'name': 'GUATEMALA',
          'code': 'gt'
      },
      {
          'prefix': '+224',
          'name': 'GUINEA',
          'code': 'gn'
      },
      {
          'prefix': '+245',
          'name': 'GUINEA BISSAU',
          'code': 'gw'
      },
      {
          'prefix': '+240',
          'name': 'GUINEA ECUATORIALE',
          'code': 'gq'
      },
      {
          'prefix': '+592',
          'name': 'GUYANA',
          'code': 'gy'
      },
      {
          'prefix': '+594',
          'name': 'GUYANA FRANCESE',
          'code': 'gf'
      },
      {
          'prefix': '+509',
          'name': 'HAITI',
          'code': 'ht'
      },
      {
          'prefix': '+504',
          'name': 'HONDURAS',
          'code': 'hn'
      },
      {
          'prefix': '+852',
          'name': 'HONG KONG',
          'code': 'hk'
      },
      {
          'prefix': '+91',
          'name': 'INDIA',
          'code': 'in'
      },
      {
          'prefix': '+62',
          'name': 'INDONESIA',
          'code': 'id'
      },
      {
          'prefix': '+98',
          'name': 'IRAN',
          'code': 'ir'
      },
      {
          'prefix': '+964',
          'name': 'IRAQ',
          'code': 'iq'
      },
      {
          'prefix': '+353',
          'name': 'IRLANDA',
          'code': 'ie'
      },
      {
          'prefix': '+354',
          'name': 'ISLANDA',
          'code': 'is'
      },
      {
          'prefix': '+298',
          'name': 'ISOLE FæR OER',
          'code': 'fo'
      },
      {
          'prefix': '+500',
          'name': 'ISOLE FALKLAND',
          'code': 'fk'
      },
      {
          'prefix': '+670',
          'name': 'ISOLE MARIANNE',
          'code': 'mp'
      },
      {
          'prefix': '+692',
          'name': 'ISOLE MARSHALL',
          'code': 'mh'
      },
      {
          'prefix': '+672',
          'name': 'ISOLE NORFOLK',
          'code': 'nf'
      },
      {
          'prefix': '+677',
          'name': 'ISOLE SALOMONE',
          'code': 'sb'
      },
      {
          'prefix': '+972',
          'name': 'ISRAELE',
          'code': 'il'
      },
      {
          'prefix': '+996',
          'name': 'KAZAKISTAN',
          'code': 'kz'
      },
      {
          'prefix': '+254',
          'name': 'KENIA',
          'code': 'ke'
      },
      {
          'prefix': '+686',
          'name': 'KIRIBATI',
          'code': 'ki'
      },
      {
          'prefix': '+965',
          'name': 'KUWAIT',
          'code': 'kw'
      },
      {
          'prefix': '+856',
          'name': 'LAOS',
          'code': 'la'
      },
      {
          'prefix': '+266',
          'name': 'LESOTHO',
          'code': 'ls'
      },
      {
          'prefix': '+371',
          'name': 'LETTONIA',
          'code': 'lv'
      },
      {
          'prefix': '+961',
          'name': 'LIBANO',
          'code': 'lb'
      },
      {
          'prefix': '+231',
          'name': 'LIBERIA',
          'code': 'lr'
      },
      {
          'prefix': '+218',
          'name': 'LIBIA',
          'code': 'ly'
      },
      {
          'prefix': '+423',
          'name': 'LIECHTENSTEIN',
          'code': 'li'
      },
      {
          'prefix': '+370',
          'name': 'LITUANIA',
          'code': 'lt'
      },
      {
          'prefix': '+352',
          'name': 'LUSSEMBURGO',
          'code': 'lu'
      },
      {
          'prefix': '+853',
          'name': 'MACAO',
          'code': 'mo'
      },
      {
          'prefix': '+389',
          'name': 'MACEDONIA',
          'code': 'mk'
      },
      {
          'prefix': '+261',
          'name': 'MADAGASCAR',
          'code': 'mg'
      },
      {
          'prefix': '+265',
          'name': 'MALAWI',
          'code': 'mw'
      },
      {
          'prefix': '+960',
          'name': 'MALDIVE',
          'code': 'mv'
      },
      {
          'prefix': '+60',
          'name': 'MALESIA',
          'code': 'my'
      },
      {
          'prefix': '+223',
          'name': 'MALI',
          'code': 'ml'
      },
      {
          'prefix': '+356',
          'name': 'MALTA',
          'code': 'mt'
      },
      {
          'prefix': '+212',
          'name': 'MAROCCO',
          'code': 'ma'
      },
      {
          'prefix': '+596',
          'name': 'MARTINICA',
          'code': 'mq'
      },
      {
          'prefix': '+222',
          'name': 'MAURITANIA',
          'code': 'mr'
      },
      {
          'prefix': '+230',
          'name': 'MAURITIUS',
          'code': 'mu'
      },
      {
          'prefix': '+52',
          'name': 'MESSICO',
          'code': 'mx'
      },
      {
          'prefix': '+373',
          'name': 'MOLDAVIA',
          'code': 'md'
      },
      {
          'prefix': '+377',
          'name': 'MONACO (PRINCIPATO DI)',
          'code': 'mc'
      },
      {
          'prefix': '+976',
          'name': 'MONGOLIA',
          'code': 'mn'
      },
      {
          'prefix': '+258',
          'name': 'MOZAMBICO',
          'code': 'mz'
      },
      {
          'prefix': '+264',
          'name': 'NAMIBIA',
          'code': 'na'
      },
      {
          'prefix': '+977',
          'name': 'NEPAL',
          'code': 'np'
      },
      {
          'prefix': '+505',
          'name': 'NICARAGUA',
          'code': 'ni'
      },
      {
          'prefix': '+234',
          'name': 'NIGERIA',
          'code': 'ng'
      },
      {
          'prefix': '+683',
          'name': 'NIUE',
          'code': 'nu'
      },
      {
          'prefix': '+47',
          'name': 'NORVEGIA',
          'code': 'no'
      },
      {
          'prefix': '+687',
          'name': 'NUOVA CALEDONIA',
          'code': 'nc'
      },
      {
          'prefix': '+64',
          'name': 'NUOVA ZELANDA',
          'code': 'nz'
      },
      {
          'prefix': '+968',
          'name': 'OMAN',
          'code': 'om'
      },
      {
          'prefix': '+31',
          'name': 'PAESI BASSI (OLANDA)',
          'code': 'nl'
      },
      {
          'prefix': '+92',
          'name': 'PAKISTAN',
          'code': 'pk'
      },
      {
          'prefix': '+680',
          'name': 'PALAU',
          'code': 'pw'
      },
      {
          'prefix': '+507',
          'name': 'PANAMA',
          'code': 'pa'
      },
      {
          'prefix': '+675',
          'name': 'PAPUA NUOVA GUINEA',
          'code': 'pg'
      },
      {
          'prefix': '+595',
          'name': 'PARAGUAY',
          'code': 'py'
      },
      {
          'prefix': '+51',
          'name': 'PERù',
          'code': 'pe'
      },
      {
          'prefix': '+689',
          'name': 'POLINESIA FRANCESE',
          'code': 'pf'
      },
      {
          'prefix': '+48',
          'name': 'POLONIA',
          'code': 'pl'
      },
      {
          'prefix': '+351',
          'name': 'PORTOGALLO',
          'code': 'pt'
      },
      {
          'prefix': '+974',
          'name': 'QATAR',
          'code': 'qa'
      },
      {
          'prefix': '+420',
          'name': 'REP. CECA',
          'code': 'cz'
      },
      {
          'prefix': '+236',
          'name': 'REP. CENTRAFRICA',
          'code': 'cf'
      },
      {
          'prefix': '+243',
          'name': 'REP. DEMOCRATICA DEL CONGO',
          'code': 'cd'
      },
      {
          'prefix': '+1',
          'name': 'REP. DOMINICANA',
          'code': 'ro'
      },
      {
          'prefix': '+262',
          'name': 'REUNION (FRANCIA)',
          'code': 're'
      },
      {
          'prefix': '+40',
          'name': 'ROMANIA',
          'code': 'ro'
      },
      {
          'prefix': '+250',
          'name': 'RUANDA',
          'code': 'rw'
      },
      {
          'prefix': '+290',
          'name': 'SAINT ELENA',
          'code': 'sh'
      },
      {
          'prefix': '+508',
          'name': 'SAINT PIERRE E MIQUELON',
          'code': 'pm'
      },
      {
          'prefix': '+684',
          'name': 'SAMOA AMERICANE',
          'code': 'as'
      },
      {
          'prefix': '+685',
          'name': 'SAMOA OCCIDENTALE',
          'code': 'ws'
      },
      {
          'prefix': '+239',
          'name': 'SAO TOMè E PRINCIPE',
          'code': 'st'
      },
      {
          'prefix': '+221',
          'name': 'SENEGAL',
          'code': 'sn'
      },
      {
          'prefix': '+381',
          'name': 'SERBIA',
          'code': 'sr'
      },
      {
          'prefix': '+248',
          'name': 'SEYCHELLES',
          'code': 'sc'
      },
      {
          'prefix': '+232',
          'name': 'SIERRA LEONE',
          'code': 'sl'
      },
      {
          'prefix': '+65',
          'name': 'SINGAPORE',
          'code': 'sg'
      },
      {
          'prefix': '+963',
          'name': 'SIRIA',
          'code': 'sy'
      },
      {
          'prefix': '+421',
          'name': 'SLOVACCHIA',
          'code': 'sk'
      },
      {
          'prefix': '+386',
          'name': 'SLOVENIA',
          'code': 'si'
      },
      {
          'prefix': '+252',
          'name': 'SOMALIA',
          'code': 'so'
      },
      {
          'prefix': '+34',
          'name': 'SPAGNA',
          'code': 'es'
      },
      {
          'prefix': '+94',
          'name': 'SRI LANKA',
          'code': 'lk'
      },
      {
          'prefix': '+27',
          'name': 'SUDAFRICA',
          'code': 'za'
      },
      {
          'prefix': '+249',
          'name': 'SUDAN',
          'code': 'sd'
      },
      {
          'prefix': '+46',
          'name': 'SVEZIA',
          'code': 'se'
      },
      {
          'prefix': '+41',
          'name': 'SVIZZERA',
          'code': 'ch'
      },
      {
          'prefix': '+268',
          'name': 'SWAZILAND',
          'code': 'sz'
      },
      {
          'prefix': '+886',
          'name': 'TAIWAN',
          'code': 'tw'
      },
      {
          'prefix': '+737',
          'name': 'TAJIKISTAN',
          'code': 'tj'
      },
      {
          'prefix': '+255',
          'name': 'TANZANIA',
          'code': 'tz'
      },
      {
          'prefix': '+66',
          'name': 'THAILANDIA',
          'code': 'th'
      },
      {
          'prefix': '+228',
          'name': 'TOGO',
          'code': 'tg'
      },
      {
          'prefix': '+676',
          'name': 'TONGA',
          'code': 'to'
      },
      {
          'prefix': '+216',
          'name': 'TUNISIA',
          'code': 'tn'
      },
      {
          'prefix': '+90',
          'name': 'TURCHIA',
          'code': 'tr'
      },
      {
          'prefix': '+993',
          'name': 'TURKMENISTAN',
          'code': 'tm'
      },
      {
          'prefix': '+688',
          'name': 'TUVALU',
          'code': 'tv'
      },
      {
          'prefix': '+380',
          'name': 'UCRAINA',
          'code': 'ua'
      },
      {
          'prefix': '+256',
          'name': 'UGANDA',
          'code': 'ug'
      },
      {
          'prefix': '+36',
          'name': 'UNGHERIA',
          'code': 'hu'
      },
      {
          'prefix': '+598',
          'name': 'URUGUAY',
          'code': 'uy'
      },
      {
          'prefix': '+1',
          'name': 'USA',
          'code': 'us'
      },
      {
          'prefix': '+998',
          'name': 'UZBEKISTAN',
          'code': 'uz'
      },
      {
          'prefix': '+678',
          'name': 'VANUATO',
          'code': 'vu'
      },
      {
          'prefix': '+58',
          'name': 'VENEZUELA',
          'code': 've'
      },
      {
          'prefix': '+84',
          'name': 'VIETNAM',
          'code': 'vn'
      },
      {
          'prefix': '+681',
          'name': 'WALLIS E FUTUNA',
          'code': 'wf'
      },
      {
          'prefix': '+967',
          'name': 'YEMEN',
          'code': 'ye'
      },
      {
          'prefix': '+260',
          'name': 'ZAMBIA',
          'code': 'zm'
      },
      {
          'prefix': '+263',
          'name': 'ZIMBABWE',
          'code': 'zw'
      }
    ];
  }

}
