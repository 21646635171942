import { MatSnackBar } from '@angular/material/snack-bar';
import { Operation } from 'src/app/_models/operation';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-identifiers-dialog',
  templateUrl: './identifiers-dialog.component.html',
  styleUrls: ['./identifiers-dialog.component.scss']
})
export class IdentifiersDialogComponent implements OnInit {

  operationInfo: Operation;
  idCopied = $localize`:@@copiedId:ID copiato`;
  notCopied =  $localize`:@@notcopiedId:ID non copiato`;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MatDialogClose>
  ) {
    this.operationInfo = data;
  }

  ngOnInit(): void {
  }

  copyID(id: string){
    const listener = (e) => {
      e.clipboardData.setData('text/plain', (id ? id : ''));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.openSnackBar(id ? this.idCopied : this.notCopied, '');
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  close() {
    this.dialogRef.close();
  }

}
