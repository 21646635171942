<h1 class='save-line-head'>Test Area</h1>
<h4 i18n='@@text-testarea1'>In quest'area è possibile gestire le configurazioni e le transazioni del merchant in ambiente di test.</h4>
<h3 class="text-green-bold" i18n='@@subtitle-whatYouCanDo'>Cosa puoi fare</h3>
<h4 i18n='@@text-testarea2'>Creare e simulare un pagamento, visualizzare il riepilogo delle transazioni, configurare i metodi di pagamento, visualizzare le variabili dell'ambiente di test e produzione, generare un token di accesso e scaricare le integrazioni SDK.</h4>

<div class="flex-row">
  <div style="width: 50%; margin-right: 25px;">
    <a routerLink="transactions">
      <mat-card class="card">
        <mat-icon svgIcon='crea' style="margin-right: 15px;"></mat-icon>
        <ng-container i18n='@@itemMenu-Transaction'>Test transazioni</ng-container>
        <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
    </a>
  </div>
  <div style="width: 50%;">
    <a routerLink="transactions-summary">
      <mat-card class="card">
        <mat-icon svgIcon='elenco' style="margin-right: 15px;"></mat-icon>
        <ng-container i18n='@@transaction-summary'>Sommario transazioni</ng-container>
        <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
    </a>
  </div>
</div>
<div class="flex-row">
  <div style="width: 50%; margin-right: 25px;">
    <a routerLink="configuration">
      <mat-card class="card">
        <mat-icon svgIcon='configurazione' style="margin-right: 15px;"></mat-icon>
        <ng-container i18n='@@itemMenu-Configuration'>Configurazione</ng-container>
        <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
    </a>

  </div>
  <div style="width: 50%;">
    <a routerLink="downloads">
      <mat-card class="card">
        <mat-icon svgIcon='download' style="margin-right: 15px;"></mat-icon>
        <ng-container>Download</ng-container>
        <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
    </a>
  </div>
</div>