import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  currentLanguage: string;

  private currentLanguageSbj = new BehaviorSubject<string>('');
  currentLanguage$ = this.currentLanguageSbj.asObservable();

  constructor() {
    this.getCurrentLanguage();
  }

  getCurrentLanguage() {
    const myHref = window.location.href;
    const languages = ['it', 'en', 'fr'];
    languages.forEach(l => {
      if (myHref.search('/' + l + '/') !== -1) {
        this.currentLanguage = l;
        this.currentLanguageSbj.next(this.currentLanguage);
      }
    });
  }

  setLanguage(language) {
    this.currentLanguageSbj.next(language);
  }
}
