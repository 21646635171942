import { LoaderService } from './../../../../../_services/loader.service';
import { StrapiService } from './../../../../../_services/strapi.service';
import { IntegrationService } from './../../../../../_services/integration.service';
import { MerchantService } from './../../../../../_services/merchant.service';
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { File, Sdk } from 'src/app/_models/strapi-file';



@Component({
  selector: 'app-sdk-in-app',
  templateUrl: './sdk-in-app.component.html',
  styleUrls: ['./sdk-in-app.component.scss']
})
export class SdkInAppComponent implements OnInit {

  currentMerchantId: string;
  activeIntegrations: any;
  downloadableSdk: Array<Sdk>;
  isAndroidActive: boolean = false;
  isIosActive: boolean = false;

  constructor(
    private integrationService: IntegrationService,
    private merchantService: MerchantService,
    private strapiService: StrapiService,
    private loaderService: LoaderService
  ) {
    this.currentMerchantId = this.merchantService.currentMerchant._id;
  }

  ngOnInit(): void {
    this.strapiService.urlStrapi$.subscribe(endpoint => {
      if (endpoint) {
        this.loaderService.startLoader();
        this.strapiService.getSdks().subscribe(
          resp => {
            this.loaderService.stopLoader();
            this.downloadableSdk = resp;

            this.downloadableSdk.forEach(sdk => {

              this.merchantService.currentMerchant.integrations.forEach(integration => {
                if (sdk.sdk_type === 'ios' && integration.type === 'IOS') {
                  this.isIosActive = true;
                }

                if (sdk.sdk_type === 'android' && integration.type === 'ANDROID') {
                  this.isAndroidActive = true;
                }
              });

            });
          },
          error => this.loaderService.stopLoader()
        );
      }
    });
  }

  download(file: File) {
    if (!file) {
      return;
    } else {
      this.strapiService.downloadFile(file.url).subscribe(blob => saveAs(blob, file.name));
    }
  }

}
