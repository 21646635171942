import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-area',
  templateUrl: './test-area.component.html',
  styleUrls: ['./test-area.component.scss']
})
export class TestAreaComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {

  }

}
