<h1 class="save-line-head" i18n='@@itemMenu-Solution'>Soluzioni</h1>

<h3 i18n='@@subTitle-TestEnvironment'>Ambiente TEST</h3>
<app-license-container [licenseArray] = 'licenseObj'></app-license-container>

<button mat-button class="btn-active-green" style="margin: 20px 0;" i18n='@@btn-GeneratesAccessToken' (click)="redirect()">Genera Access Token</button>
<p i18n='@@text-EndpointTest'>Endpoint di test:</p>

  <!-- Table Shared -->
  <app-custom-data-table id="twoColumnTable" [tableData]='endpointsTest' [columnHeader]='displayedColumns' [ariaLabel]="'Endpoint di test'"></app-custom-data-table>
