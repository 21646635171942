import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { GatewayService } from 'src/app/_services/gateway.service';
import { MerchantService } from 'src/app/_services/merchant.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-prod-configurations-payment-methods',
  templateUrl: './prod-configurations-payment-methods.component.html',
  styleUrls: ['./prod-configurations-payment-methods.component.scss']
})
export class ProdConfigurationsPaymentMethodsComponent implements OnInit {

  gateways: any[] = [];
  typeList: any[] = [];
  currentMerchantSettings: any;
  loaded = false;
  merchantID: string;
  productionMerchantID: string;

  constructor(
    private gatewayService: GatewayService,
    private merchantService: MerchantService,
    private router: Router
  ) {
    if (!this.merchantService.currentProductionMerchant) {
      this.router.navigate(['production/configuration/solutions'], {});
    }
    this.productionMerchantID = this.merchantService.currentProductionMerchant ? this.merchantService.currentProductionMerchant._id : null;
    this.merchantID = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    this.merchantService.getSyncSettings(this.productionMerchantID).subscribe(res => {
      this.currentMerchantSettings = res.item;
      this.getGateways();
    }, err => this.loaded = true);
  }

  ngOnInit(): void {
  }

  getGateways() {
    this.gatewayService.getSyncGateways(this.productionMerchantID).subscribe((res: any) => {
      this.gateways = res.items;
      this.typeList = res.items.map(el => {
        const type = el.definitions.type ? el.definitions.type : el.type ? el.type : null;
        switch (type) {
          case 'SPAY':
            return this.currentMerchantSettings.isIGFSActive &&
                   el.definitions.mid && el.definitions.mid !== '' &&
                   el.definitions.tid && el.definitions.tid !== '' &&
                   el.definitions.ksig && el.definitions.ksig !== '' ? 'SPAY' : null;
          case 'APPLEPAY':
            return this.currentMerchantSettings.isIGFSActive ? false : null;
        }
      }).filter(el => el);
      this.currentMerchantSettings.isPAYPALActive ? this.typeList.push('PAYPAL') : null;
      this.loaded = true;
    }, (err) => this.loaded = true);
  }
}
