<!-- Menu Left-->
<mat-nav-list class='left-menu' style="margin-top:80px">
    <mat-list-item>
        <a routerLink="public" routerLinkActive="active" #publicRL="routerLinkActive">
            <h3 i18n='@@itemMenu-Documentation'>Documentazione
            </h3>
        </a>
    </mat-list-item>
    <!-- Sub Menu Documentation -->
    <ng-container *ngIf="publicRL?.isActive">
        <mat-list-item>
            <a class="secondLevel" routerLink="public/home" routerLinkActive="active">
                <h3>Home</h3>
            </a>
        </mat-list-item>

        <mat-list-item>
            <a class="secondLevel" routerLink="public/payment-methods" routerLinkActive="active">
                <h3 i18n='@@itemMenu-PaymentMethod'>Metodi di pagamento</h3>
            </a>
        </mat-list-item>
    </ng-container>

    <div *ngIf="merchantService.accessAllowed === true" style="margin-top: 20px;">
        <mat-list-item>
            <a routerLink="sandbox" routerLinkActive="active" #sandboxRL="routerLinkActive">
                <h3 i18n='@@itemMenu-AreaTest'>Test area</h3>
            </a>
        </mat-list-item>
        <!-- Sub Menu Areatest -->
        <ng-container *ngIf="sandboxRL?.isActive">
            <mat-list-item>
                <a class="secondLevel" routerLink="sandbox/transactions" routerLinkActive="active">
                    <h3 i18n='@@itemMenu-Transaction'>Test transazioni</h3>
                </a>
            </mat-list-item>
            <mat-list-item>
                <a class="secondLevel" routerLink="sandbox/transactions-summary" routerLinkActive="active">
                    <h3 i18n='@@transaction-summary'>Sommario transazioni</h3>
                </a>
            </mat-list-item>
            <mat-list-item>
                <a class="secondLevel" routerLink="sandbox/configuration" routerLinkActive="active"
                    #configRL="routerLinkActive">
                    <h3 i18n='@@itemMenu-Configuration'>Configurazione</h3>
                </a>
            </mat-list-item>
            <ng-container *ngIf="configRL?.isActive">
                <mat-list-item>
                    <a routerLink="sandbox/configuration/solutions" routerLinkActive="active" class="thirdLevel">
                        <h4 i18n='@@itemMenu-Solution'>Soluzioni</h4>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a routerLink="sandbox/configuration/payment-methods" routerLinkActive="active" class="thirdLevel">
                        <h4 i18n='@@itemMenu-PaymentMethod'>Metodi di pagamento</h4>
                    </a>
                </mat-list-item>
            </ng-container>
            <mat-list-item>
                <a class="secondLevel" routerLink="sandbox/downloads" routerLinkActive="active"
                    #downloadRL="routerLinkActive">
                    <h3 i18n='@@itemMenu-Download'>Download</h3>
                </a>
            </mat-list-item>
            <ng-container *ngIf="downloadRL?.isActive">
                <mat-list-item>
                    <a routerLink="sandbox/downloads/plugin" routerLinkActive="active" class="thirdLevel">
                        <h4>Plugin</h4>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a routerLink="sandbox/downloads/sdk" routerLinkActive="active" class="thirdLevel">
                        <h4 i18n='@@itemMenu-SdkInApp'>SDK per In-App</h4>
                    </a>
                </mat-list-item>
            </ng-container>
        </ng-container>

        <div *ngIf="merchantService.currentMerchant?.role === 'USER'" style="margin-top: 20px;">
            <mat-list-item>
                <a routerLink="production/configuration" routerLinkActive="active" #prodRL="routerLinkActive">
                    <h3 i18n='@@production-configuration'>Configura produzione</h3>
                </a>
            </mat-list-item>
            <!-- SubMenu Production -->
            <ng-container *ngIf="prodRL?.isActive">
                <mat-list-item>
                    <a routerLink="production/configuration/solutions" routerLinkActive="active" class="secondLevel">
                        <h3 i18n='@@itemMenu-Solution'>Soluzioni</h3>
                    </a>
                </mat-list-item>
                <div *ngIf="merchantService.currentProductionMerchant">
                  <mat-list-item>
                    <a routerLink="production/configuration/payment-methods" routerLinkActive="active" class="secondLevel">
                      <h3 i18n='@@itemMenu-PaymentMethod'>Metodi di pagamento</h3>
                    </a>
                  </mat-list-item>
                  <mat-list-item>
                    <a class="secondLevel" routerLink="production/configuration/transactions-summary" routerLinkActive="active">
                      <h3 i18n='@@transaction-summary'>Sommario transazioni</h3>
                    </a>
                  </mat-list-item>
                </div>
            </ng-container>
        </div>
    </div>
</mat-nav-list>
