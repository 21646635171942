<!-- Header Buttons -->
<div mat-dialog-title class="dialog-header" style="box-shadow: 0px 0px 5px #00000029 !important;">
    <div class="row-header">
        <div class="button-group">
            <button *ngIf="data.licenses.wizard" mat-button (click)="btnActive='Wizard'"[ngClass]="{'btn-active-green': btnActive==='Wizard'}">Wizard</button>
            <button *ngIf="data.licenses.js && merchantService.currentMerchant?.settings.isSDKJSActive" mat-button (click)="btnActive='JS'; btnJsActive = 'default'" [ngClass]="{'btn-active-green': btnActive==='JS'}">Smart(JS)</button>
            <button *ngIf="data.licenses.android && merchantService.currentMerchant?.settings.isSDKANDROIDActive" mat-button (click)="btnActive='Android' "[ngClass]="{'btn-active-green': btnActive==='Android'}">Android</button>
            <button *ngIf="data.licenses.ios && merchantService.currentMerchant.settings.isSDKIOSActive" mat-button (click)="btnActive='Ios'" [ngClass]="{'btn-active-green': btnActive==='Ios'}">iOS</button>
        </div>
        <mat-icon style="cursor: pointer;" (click)='close()'>close</mat-icon>
    </div>
    <div *ngIf="btnActive==='JS' && merchantService.currentMerchant?.settings.isSDKJSActive" class="button-group">
        <mat-divider style="margin: 8px 0;"></mat-divider>
        <button mat-button (click)="btnJsActive= 'default'" [ngClass]="{'btn-active-green': btnJsActive==='default'}">Default</button>
        <button mat-button (click)="btnJsActive= 'buttonless'" [ngClass]="{'btn-active-green': btnJsActive==='buttonless'}">Buttonless</button>
        <button mat-button (click)="btnJsActive= 'compact'" [ngClass]="{'btn-active-green': btnJsActive==='compact'}">Compact</button>
        <button mat-button (click)="btnJsActive= 'inline'" [ngClass]="{'btn-active-green': btnJsActive==='inline'}">Inline (CreditCard)</button>                         
    </div>
</div>

<!-- Content Dialog -->
<mat-dialog-content>
    <!-- Cotent -->
    <ng-container *ngIf="btnActive==='Wizard'">
        <ngx-codemirror [(ngModel)]='htmlWizard'
            [options]="{lineNumbers: true, theme: codeMirrorTheme, readOnly: true, mode : 'xml', htmlMode: true, scrollbarStyle: 'overlay'}">
        </ngx-codemirror>
    </ng-container>

    <ng-container *ngIf="btnJsActive === 'default' && btnActive==='JS'">
        <ngx-codemirror [(ngModel)]='htmlJS'
            [options]="{lineNumbers: true, theme: codeMirrorTheme, readOnly: true, mode : 'xml', htmlMode: true, scrollbarStyle: 'overlay'}">
        </ngx-codemirror>
    </ng-container>

    <ng-container *ngIf="btnJsActive === 'buttonless' && btnActive==='JS'">
        <ngx-codemirror [(ngModel)]='htmlJsButtonless'
            [options]="{lineNumbers: true, theme: codeMirrorTheme, readOnly: true, mode : 'xml', htmlMode: true, scrollbarStyle: 'overlay'}">
        </ngx-codemirror>
    </ng-container>

    <ng-container *ngIf="btnJsActive === 'compact' && btnActive==='JS'">
        <ngx-codemirror [(ngModel)]='htmlJSCompact'
            [options]="{lineNumbers: true, theme: codeMirrorTheme, readOnly: true, mode : 'xml', htmlMode: true, scrollbarStyle: 'overlay'}">
        </ngx-codemirror>
    </ng-container>

    <ng-container *ngIf="btnJsActive === 'inline' && btnActive==='JS'">
        <ngx-codemirror [(ngModel)]='htmlJSinline'
            [options]="{lineNumbers: true, theme: codeMirrorTheme, readOnly: true, mode : 'xml', htmlMode: true, scrollbarStyle: 'overlay'}">
        </ngx-codemirror>
    </ng-container>

    <ng-container *ngIf="btnActive==='Android'">
        <ngx-codemirror [(ngModel)]='htmlAndroid' [options]="{lineNumbers: true, theme: codeMirrorTheme, readOnly: true,scrollbarStyle: 'overlay'}">
        </ngx-codemirror>
    </ng-container>

    <ng-container *ngIf="btnActive==='Ios'">
        <ngx-codemirror [(ngModel)]='htmlIos' [options]="{lineNumbers: true, theme: codeMirrorTheme, readOnly: true, scrollbarStyle: 'overlay'}">
        </ngx-codemirror>
    </ng-container>
</mat-dialog-content>