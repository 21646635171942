<div mat-dialog-title>
  <h2 align="center">INFORMATIVA SULLA PRIVACY</h2>
  <mat-icon style="cursor: pointer;" (click)='close()'>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
  <p>
    Gentile Utente,<br>
    La presente informativa fornisce informazioni dettagliate sulla protezione e il trattamento dei tuoi dati personali.<br>
    <br/>
    <b>Il Titolare del trattamento è Worldline Merchant Services Italia S.p.A.</b>, di seguito “Worldline MS Italia o “Società” o “noi”, con sede in Roma, Via Degli Aldobrandeschi n. 300.
    Lo scopo di questo documento è di informarti, in particolare, di quali dati trattiamo, le finalità per cui li trattiamo e li condividiamo, per quanto tempo li conserviamo, quali sono i tuoi diritti e come possono essere esercitati in base alla normativa vigente.
    <br/>
  </p>
  <h3>1. QUALI DATI TRATTIAMO?</h3>
  <p>
    Per l’accesso all’area sviluppo Phey, trattiamo una serie di dati da te forniti o alcuni dati raccolti automaticamente. Tali dati comprendono:
    <br>• Nome Merchant (può essere anche la denominazione sociale);
    <br>• Numero telefonico
    <br>• Indirizzo mail
    <br>• Log applicativi
  </p>

  <h3>2. FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO DEI DATI</h3>
  <p>
  <b>a)Esecuzione del contratto o eseguire misure precontrattuali:</b>
  <br>Il trattamento dei tuoi dati personali necessario per consentirti di testare i nostri prodotti in area di
  sviluppo, al fine di consentirti di valutare la possibilità di sottoscrivere un contratto di convenzionamento.

  </p>
  <h3>3. CONSERVAZIONE DEI DATI PERSONALI</h3>
  <p>
  I tuoi dati personali saranno conservati solo per il tempo necessario al perseguimento di ciascuna delle finalità
  sopra menzionate.
  <br>Una volta avvenuta la registrazione alla sandbox:
  <br>- ove alla registrazione non segua la sottoscrizione del contratto di convenzionamento, i tuoi dati saranno
  conservati per 12 mesi dalla data della registrazione stessa;
  <br>- ove alla registrazione segua la sottoscrizione del contratto, i tuoi dati saranno conservati per il tempo
  indicato nell’informativa esercenti, disponibile in versione aggiornata al seguente link:
  https://www.worldlineitalia.it/privacy/ sezione “Informativa Esercenti”.

  </p>
  <h3>4. SOGGETTI O CATEGORIE DI SOGGETTI AI QUALI I DATI POSSONO ESSERE COMUNICATI</h3>
  <p>
    Ove strettamente necessario al perseguimento delle finalità del trattamento sopra descritte, i tuoi dati personali
    possono essere comunicati ai seguenti soggetti:
    <br>• società del Gruppo;
    <br>• fornitori con i quali abbiamo un rapporto regolare;
    <br>• autorità finanziarie o giudiziarie, agenzie statali o enti pubblici come richiesto e ove consentito dalla
    legge.
  </p>

  <h3>5.TRASFERIMENTO DEI DATI PERSONALI EXTRA UE</h3>
  <p>
    I tuoi dati non saranno trasferiti fuori dallo Spazio Economico Europeo.
  </p>

  <h3>6.DIRITTI DELL’INTERESSATO</h3>
  <p>
    In relazione ai trattamenti descritti nella presente informativa, le persone fisiche, possono esercitare i diritti
    sanciti dagli artt. da 15 a 22 del GDPR e, in particolare, i seguenti diritti:
    <br>• diritto di accesso – diritto di ottenere conferma che sia o meno in corso un trattamento di dati personali che
    ti riguardano e, in tal caso, ottenere l'accesso ai tuoi dati personali;
    <br>• diritto di rettifica – diritto di ottenere, senza ingiustificato ritardo, la rettifica dei dati personali
    inesatti che ti riguardano e/o l’integrazione dei dati personali incompleti;
    <br>• diritto alla cancellazione – diritto di ottenere, senza ingiustificato ritardo, la cancellazione dei dati
    personali che ti riguardano. Come previsto dalla normativa in materia di protezione dei dati personali, il diritto
    alla cancellazione dei dati non è esercitabile se il trattamento è necessario per l’adempimento di un obbligo legale
    o per l’esecuzione di un compito svolto nel pubblico interesse o per l’accertamento, l’esercizio o la difesa di un
    diritto in sede giudiziaria;
    <br>• diritto alla limitazione di trattamento – diritto di limitare il trattamento dei tuoi dati;
    <br>• diritto alla portabilità dei dati – diritto di ricevere, in un formato strutturato, di uso comune e leggibile
    da un dispositivo automatico, i dati personali che ti riguardano e di fornirli ad altro Titolare;
    <br>• diritto di opposizione – diritto di opporsi al trattamento dei dati personali che ti riguardano per motivi
    legati alla Sua situazione particolare e in ogni caso all’uso dei tuoi dati a fini di marketing diretto;
    <br>• diritto a non essere sottoposto a un processo decisionale automatizzato – diritto di non essere sottoposto a
    una decisione basata unicamente sul trattamento automatizzato, compresa la profilazione, che produca effetti
    giuridici che lo riguardano o che incida in modo analogo significativamente sulla sua persona;
    <br>• diritto di proporre reclamo all’Autorità Garante per la protezione dei dati personali:
    http://www.garanteprivacy.it;
    <br>• diritto di revocare il consenso, ove il consenso costituisca la base giuridica del trattamento.

    <br>Tutte le richieste relative all’esercizio dei diritti possono essere indirizzate a Worldline MS Italia
    attraverso il modulo all’interno della sezione privacy del nostro sito internet
    https://www.worldlineitalia.it/privacy/ oppure al DPO, all’indirizzo dataprotectionofficer.italia@worldline.com.
  </p>

  <h3>7. COME CONTATTARCI </h3>

  <p>
    Worldline Merchant Services Italia S.p.A. con sede in Roma, Via degli Aldobrandeschi n. 300, sito internet
    https://www.worldlineitalia.it/privacy/.
    Ai sensi dell’art. 38 del GDPR forniamo inoltre i <b>dati di contatto del Responsabile della Protezione dei Dati
    (DPO)</b>, raggiungibile all’indirizzo e-mail: dataprotectionofficer.italia@worldline.com
  </p>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close (click)="openPdf(privacyPdf.file)">Scarica</button>
</mat-dialog-actions>

