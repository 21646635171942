import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Merchant } from '../_models/merchant';
import { of } from 'rxjs';
import { ConfirmDialogComponent } from '../_components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  
  private _currentMerchant: Merchant;
  private _currentMerchantRole: string;
  private _accessAllowed: boolean;
  currentProductionMerchant: any;
  unauthorizedAccess = $localize `:@@unauthorizedAccess: Accesso non autorizzato.`;
  error = $localize `:@@error: Errore`;
  genericError = $localize `:@@interceptor-genericError: Errore Generico`;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  get currentMerchant() {
    return this._currentMerchant ? this._currentMerchant : null;
  }

  get currentMerchantRole() {
    return this._currentMerchantRole;
  }

  
  get accessAllowed() {
    return this._accessAllowed != null ? this._accessAllowed : false;
  }


    currentMerchantRoleOneOf(roles: string[]) {
      return roles.indexOf(this._currentMerchantRole) >= 0;
    }
    
    checkSession() {
      const date = localStorage.getItem('sessionTime');
      this._accessAllowed = !!localStorage.getItem('accessAllowed');
      this._currentMerchantRole = localStorage.getItem("currentMerchantRole");

      const duration = (1000 * 60 * (60 * 4)); // 4 Hours session
      const localSessionExpired = new Date().getTime() > Number(date) + duration;
      if (localSessionExpired && date) {
        this.logout();
      }
    }

    isLoggedIn() {
      return this.http.get('/develop/v1/merchants/auth').pipe(
        map((res: any) => {
          if (res.item) {
            this._currentMerchant = res.item;
            this._currentMerchantRole = this._currentMerchant.role;
            this._accessAllowed = true;

            localStorage.setItem('accessAllowed', 'true');
            localStorage.setItem('sessionTime', new Date().getTime().toString());
            localStorage.setItem('currentMerchantRole', this._currentMerchantRole);

            console.log("isLoggedIn function result: ", res);
            return true;
          } else {
            this.resetCurrentMerchant();

            return false;
          }          
        }), catchError(error => {
          
          const url = `${window.location.href}?cacheFix=${Date.now()}`;
          const counter = url.split('cacheFix').length;
          if (counter > 2) {
            this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
              width: '450px',
              data: { type: 'ALERT', title: this.error, message: this.unauthorizedAccess }
            });
            setTimeout(() => {
              this.logout();
              return of(false);
            }, 2000);
          } else {
            location.replace(url);
            return of(false);
          }
        })
      );
    }

    resetCurrentMerchant() {
      this._accessAllowed = false;
      this._accessAllowed = false;
      this._currentMerchant = null;
      this._currentMerchantRole = null;
      
      localStorage.removeItem('accessAllowed');
      localStorage.removeItem('sessionTime');
      localStorage.removeItem('currentMerchantRole');
    }

    register(data) {
      return this.http.post('/public_develop/v1/merchants/register', data);
    }

    newGateway(elem: object){
      return this.http.post<any>('/develop/v1/gateways', elem, {});
    }

    pushGateway(merchantID: string, gatewayID: string) {
      return this.http.put<any>('/develop/v1/merchants/' + merchantID + '/push/gateways/' + gatewayID, {});
    }

    modifyGatewayById(gatewayID: string, elem: object){
      return this.http.put<any>('/develop/v1/gateways/'+ gatewayID, elem, {});
    }

    filterGatewaysByQuery(objectsArray: object[], populate: boolean = false, sort?: string, skip?: number, limit?: number) {
      let headers = new HttpHeaders({
        'Accept': 'application/json',
        'populate': populate.toString()
      })

      let queryString: string = objectsArray.length > 0 ? '?' : '';
      objectsArray.forEach((query, index) => {
        let and = (index !== objectsArray.length - 1) ? '&' : '';
        queryString += query['key'] + '=' + query['value'] + and;
      });
      return this.http.get<any>('/develop/v1/gateways'+  queryString, { headers })
    }

    getSyncSettings(merchantId: string) {
      return this.http.get<any>(`/develop/v1/sync/merchants/${merchantId}/settings`);
    }

    logout() {
      return this.http.get<any>('/public_develop/v1/merchants/logout').subscribe(
        resp => {
          this.resetCurrentMerchant();
          window.location.href = resp.url;
        }
      );
    }

    leads() {
      return this.http.get<any>('/develop/v1/merchants/leads');
    }

}
