import { SatispayTestAreaComponent } from './pages/private/test-area/configurations/configurations-payment-methods/satispay-test-area/satispay-test-area.component';
import { AuthSettingsGuard } from './_guards/auth-settings.guard';
import { ProdTransactionsSummaryComponent } from './pages/private/prod-configurations/prod-transactions-summary/prod-transactions-summary.component';
import { TransactionSummaryComponent } from './pages/private/transaction-summary/transaction-summary.component';
import { ApplepayConfigurationComponent } from './pages/private/test-area/configurations/configurations-payment-methods/applepay-configuration/applepay-configuration.component';
import { ApplepayComponent } from './pages/public/payment-methods-pages/wallet/applepay/applepay.component';
import { PaypalComponent } from './pages/private/test-area/configurations/configurations-payment-methods/paypal/paypal.component';
import { SatispayComponent } from './pages/private/test-area/configurations/configurations-payment-methods/satispay/satispay.component';
import { PluginDownloadComponent } from './pages/private/test-area/downloads/plugin-download/plugin-download.component';
import { ConfigurationsPaymentMethodsComponent } from './pages/private/test-area/configurations/configurations-payment-methods/configurations-payment-methods.component';
import { ConfigurationsSolutionsComponent } from './pages/private/test-area/configurations/configurations-solutions/configurations-solutions.component';
import { SdkInAppComponent } from './pages/private/test-area/downloads/sdk-in-app/sdk-in-app.component';
import { TransactionsComponent } from './pages/private/test-area/transactions/transactions.component';
import { DownloadsComponent } from './pages/private/test-area/downloads/downloads.component';
import { ConfigurationsComponent } from './pages/private/test-area/configurations/configurations.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { WechatpayComponent } from './pages/public/payment-methods-pages/alternative-payments/wechatpay/wechatpay.component';
import { AlipayComponent } from './pages/public/payment-methods-pages/alternative-payments/alipay/alipay.component';
import { AlternativePaymentsComponent } from './pages/public/payment-methods-pages/alternative-payments/alternative-payments.component';
import { WalletComponent } from './pages/public/payment-methods-pages/wallet/wallet.component';
import { PaymentMethodsComponent } from './pages/public/documents/payment-methods/payment-methods.component';
import { TestAreaComponent } from './pages/private/test-area/test-area.component';
import { DocumentsComponent } from './pages/public/documents/documents.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthPrivateGuard } from './_guards/auth-private.guard';
import { AuthPublicGuard } from './_guards/auth-public.guard';
import { ProdConfigurationsComponent } from './pages/private/prod-configurations/prod-configurations.component';
import { ProdConfigurationsSolutionsComponent } from './pages/private/prod-configurations/prod-configurations-solutions/prod-configurations-solutions.component';
import { ProdConfigurationsPaymentMethodsComponent } from './pages/private/prod-configurations/prod-configurations-payment-methods/prod-configurations-payment-methods.component';
import { ProdGuard } from './_guards/prod.guard';
import { RegistrationGuard } from './_guards/registration.guard';
import { PluginTableComponent } from './pages/private/test-area/downloads/plugin-table/plugin-table.component';

const routerOptions: ExtraOptions = {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
}

const routes: Routes = [
  { path: '', redirectTo: 'public/home', pathMatch: 'full' },
  { path: 'public/home', canActivate: [ AuthPublicGuard ], component: DocumentsComponent},
  { path: 'sandbox', canActivate: [ AuthPrivateGuard ], component: TestAreaComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: ''}]}},
  { path: 'sandbox/configuration', canActivate: [ AuthPrivateGuard ], component: ConfigurationsComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@itemMenu-Configuration:Configurazione`, url: ''}]}},
  { path: 'sandbox/configuration/solutions', canActivate: [ AuthPrivateGuard ], component: ConfigurationsSolutionsComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@itemMenu-Configuration:Configurazione`, url: 'sandbox/configuration'}, {label: $localize`:@@itemMenu-Solution:Soluzioni`, url: ''}]}},
  { path: 'sandbox/configuration/payment-methods', canActivate: [ AuthPrivateGuard ], component: ConfigurationsPaymentMethodsComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@itemMenu-Configuration:Configurazione`, url: 'sandbox/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: ''}]}},
  { path: 'sandbox/configuration/payment-methods/paypal', canActivate: [ AuthPrivateGuard, AuthSettingsGuard ], component: PaypalComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@itemMenu-Configuration:Configurazione`, url: 'sandbox/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: 'sandbox/configuration/payment-methods'}, {label: 'PayPal', url:''}]}},
  { path: 'sandbox/configuration/payment-methods/paypal/:query', component: PaypalComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@itemMenu-Configuration:Configurazione`, url: 'sandbox/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: 'sandbox/configuration/payment-methods'}, {label: 'PayPal', url:''}]}},
  { path: 'sandbox/configuration/payment-methods/satispay', canActivate: [ AuthPrivateGuard, AuthSettingsGuard ], component: SatispayTestAreaComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@itemMenu-Configuration:Configurazione`, url: 'sandbox/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: 'sandbox/configuration/payment-methods'}, {label: 'Satispay', url:''}]}},
  { path: 'sandbox/downloads', canActivate: [ AuthPrivateGuard ], component: DownloadsComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: 'Download', url: ''}]}},
  { path: 'sandbox/downloads/plugin', canActivate: [ AuthPrivateGuard ], component: PluginDownloadComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: 'Download', url: 'sandbox/downloads'}, {label: 'Plugin', url: ''}]}},
  { path: 'sandbox/downloads/plugin/:name/:id', canActivate: [ AuthPrivateGuard ], component: PluginTableComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: 'Download', url: 'sandbox/downloads'}, {label: 'Plugin', url: 'sandbox/downloads/plugin'}, {label: '{{name}}', url: ''}]}},
  { path: 'sandbox/downloads/sdk', canActivate: [ AuthPrivateGuard ], component: SdkInAppComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: 'Download', url: 'sandbox/downloads'}, {label: 'SDK per In-App', url: ''}]}},
  { path: 'sandbox/transactions', canActivate: [ AuthPrivateGuard ], component: TransactionsComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@itemMenu-Transaction:Transazioni`, url: ''}]}},
  { path: 'sandbox/transactions-summary', canActivate: [ AuthPrivateGuard ], component: TransactionSummaryComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-AreaTest:Area test`, url: 'sandbox'}, {label: $localize`:@@transaction-summary:Sommario transazioni`, url: ''}]}},
  { path: 'production/configuration', canActivate: [ AuthPrivateGuard, ProdGuard ], component: ProdConfigurationsComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: ''}]}},
  { path: 'production/configuration/solutions', canActivate: [ AuthPrivateGuard, ProdGuard ], component: ProdConfigurationsSolutionsComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: 'production/configuration'}, {label: $localize`:@@itemMenu-Solution:Soluzioni`, url: ''}]}},
  { path: 'production/configuration/payment-methods', canActivate: [ AuthPrivateGuard, ProdGuard ], component: ProdConfigurationsPaymentMethodsComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: 'production/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: ''}]}},
  { path: 'production/configuration/transactions-summary', canActivate: [ AuthPrivateGuard, ProdGuard ], component: ProdTransactionsSummaryComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: 'production/configuration'}, {label: $localize`:@@transaction-summary:Sommario transazioni`, url: ''}]}},
  { path: 'production/configuration/payment-methods/satispay', canActivate: [ AuthPrivateGuard, ProdGuard, AuthSettingsGuard ], component: SatispayComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: 'production/configurations'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: 'production/configuration/payment-methods'}, {label: 'Satispay', url:''}]}},
  { path: 'production/configuration/payment-methods/applepay', canActivate: [ AuthPrivateGuard, ProdGuard, AuthSettingsGuard ], component: ApplepayConfigurationComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: 'production/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: 'production/configuration/payment-methods'}, {label: 'Apple Pay', url:''}]}},
  { path: 'production/configuration/payment-methods/paypal', canActivate: [ AuthPrivateGuard, ProdGuard, AuthSettingsGuard ], component: PaypalComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: 'production/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: 'production/configuration/payment-methods'}, {label: 'PayPal', url:''}]}},
  { path: 'production/configuration/payment-methods/paypal/:query', canActivate: [ AuthPrivateGuard, ProdGuard ], component: PaypalComponent, data: { breadcrumb: [{label: $localize`:@@production-configuration:Configura produzione`, url: 'production/configuration'}, {label: $localize`:@@itemMenu-PaymentMethod: Metodi di pagamento`, url: 'production/configuration/payment-methods'}, {label: 'PayPal', url:''}]}},
  { path: 'public/payment-methods', canActivate: [ AuthPublicGuard ], component: PaymentMethodsComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-PaymentMethod:Metodo di pagamento`, url: ''}]}},
  { path: 'public/payment-methods/wallet', canActivate: [ AuthPublicGuard ], component: WalletComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-PaymentMethod:Metodo di pagamento`, url: 'public/payment-methods'}, {label: 'Wallet', url: ''}]}},
  { path: 'public/payment-methods/alternative-payment', canActivate: [ AuthPublicGuard ], component: AlternativePaymentsComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-PaymentMethod:Metodo di pagamento`, url: 'public/payment-methods'}, {label: $localize`:@@subtitle-AlternativePayments:Pagamenti alternatvi`, url: ''}]}},
  { path: 'public/payment-methods/wallet/apple-pay', canActivate: [ AuthPublicGuard ], component: ApplepayComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-PaymentMethod:Metodo di pagamento`, url: 'public/payment-methods'}, {label: 'Wallet', url: 'public/payment-methods/wallet'}, {label: 'Apple Pay', url: ''}]}},
  { path: 'public/payment-methods/alternative-payment/alipay', canActivate: [ AuthPublicGuard ], component: AlipayComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-PaymentMethod:Metodo di pagamento`, url: 'public/payment-methods'}, {label: $localize`:@@subtitle-AlternativePayments:Pagamenti alternatvi`, url: 'public/payment-methods/alternative-payment'}, {label: 'Alipay', url: ''}]}},
  { path: 'public/payment-methods/alternative-payment/wechat-pay', canActivate: [ AuthPublicGuard ], component: WechatpayComponent, data: { breadcrumb: [{label: $localize`:@@itemMenu-PaymentMethod:Metodo di pagamento`, url: 'public/payment-methods'}, {label: $localize`:@@subtitle-AlternativePayments:Pagamenti alternatvi`, url: 'public/payment-methods/alternative-payment'}, {label: 'WeChat Pay', url: ''}]}},
  { path: 'public/registration', canActivate: [ RegistrationGuard ], component: RegistrationComponent, data: { breadcrumb: [{label: 'Registrazione', url: ''}]}},
  { path: '**', redirectTo: 'public/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
