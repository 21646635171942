import { FormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-applepay-configuration',
  templateUrl: './applepay-configuration.component.html',
  styleUrls: ['./applepay-configuration.component.scss']
})
export class ApplepayConfigurationComponent implements OnInit {

  fileData: File = null;
  file: UntypedFormControl;
  listUpload = [];

  constructor() { }

  ngOnInit(): void {
    this.file = new UntypedFormControl('');
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0];
    if (this.fileData) {
      this.file = new UntypedFormControl(this.fileData.name);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.fileData);
  }
}
