import { LoaderService } from './../../../_services/loader.service';
import { LocationService } from './../../../_services/location.service';
import { StrapiService } from './../../../_services/strapi.service';
import { MerchantService } from 'src/app/_services/merchant.service';
import { Component, OnInit } from '@angular/core';
import { File, TextIntro, Pdf } from 'src/app/_models/strapi-file';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})

export class DocumentsComponent implements OnInit {

  listPdfHome: Array<Pdf>;
  currentLanguage: string;
  textIntro: TextIntro;

  constructor(
    public merchantService: MerchantService,
    private strapiService: StrapiService,
    private locationService: LocationService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.strapiService.urlStrapi$.subscribe(endpoint => {
      if (endpoint) {
        this.loaderService.startLoader();
        this.strapiService.getDocuments().subscribe(
          response => {
            this.listPdfHome = response.filter(pdf => pdf.visibility === 'home');
            this.loaderService.stopLoader();
          },
          error => this.loaderService.stopLoader()
        );

        this.strapiService.getTextIntro().subscribe(
          (resp: any) => this.textIntro = resp
        );
      }
    });
    this.locationService.currentLanguage$.subscribe(resp => this.currentLanguage = resp);
  }

  openPdf(file: File) {

    if(!file) {
      return;
    } else {
      this.strapiService.downloadFile(file.url).subscribe(blob => {
        saveAs(blob, `${file.name}`);
      }
    );
    }

  }

  navigateToSandbox() {
    let href = window.location.href.split('/');
    let ix = href.indexOf('public');
    if (ix === -1) {return; }
    href.splice(ix);
    href.push('sandbox');
    window.location.href = href.join('/');
  }

}
