import { ApicheckoutComponent } from './pages/public/solutions-pages/apicheckout/apicheckout.component';
import { CustomMaterialModule } from './_sharedModules/custom-material/custom-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TestAreaComponent } from './pages/private/test-area/test-area.component';
import { TransactionsComponent } from './pages/private/test-area/transactions/transactions.component';
import { ConfigurationsComponent } from './pages/private/test-area/configurations/configurations.component';
import { DownloadsComponent } from './pages/private/test-area/downloads/downloads.component';
import { DocumentsComponent } from './pages/public/documents/documents.component';
import { SolutionsComponent } from './pages/public/documents/solutions/solutions.component';
import { PaymentMethodsComponent } from './pages/public/documents/payment-methods/payment-methods.component';
import { BeyondPaymentsComponent } from './pages/public/documents/beyond-payments/beyond-payments.component';
import { ApiDocsComponent } from './pages/public/documents/api-docs/api-docs.component';
import { HomeComponent } from './pages/home/home.component';
import { IntlPhoneComponent } from './_components/intl-phone/intl-phone.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { IntegrationDialogComponent } from './_components/integration-dialog/integration-dialog.component';
import { CustomDataTableComponent } from './_components/custom-data-table/custom-data-table.component';
import { EasyComponent } from './pages/public/solutions-pages/easy/easy.component';
import { PluginComponent } from './pages/public/solutions-pages/plugin/plugin.component';
import { SmartComponent } from './pages/public/solutions-pages/smart/smart.component';
import { InAppComponent } from './pages/public/solutions-pages/in-app/in-app.component';
import { ContainerCodeComponent } from './_components/container-code/container-code.component';
import { RightDrawerMenuComponent } from './_components/right-drawer-menu/right-drawer-menu.component';
import { WalletComponent } from './pages/public/payment-methods-pages/wallet/wallet.component';
import { AlternativePaymentsComponent } from './pages/public/payment-methods-pages/alternative-payments/alternative-payments.component';
import { BancomatpayComponent } from './pages/public/payment-methods-pages/wallet/bancomatpay/bancomatpay.component';
import { ApplepayComponent } from './pages/public/payment-methods-pages/wallet/applepay/applepay.component';
import { AlipayComponent } from './pages/public/payment-methods-pages/alternative-payments/alipay/alipay.component';
import { WechatpayComponent } from './pages/public/payment-methods-pages/alternative-payments/wechatpay/wechatpay.component';
import { LeftDrawerMenuComponent } from './_components/left-drawer-menu/left-drawer-menu.component';
import { SearchBarComponent } from './_components/search-bar/search-bar.component';
import { ConfirmDialogComponent } from './_components/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import {NgDynamicBreadcrumbModule} from 'ng-dynamic-breadcrumb';
import { RegistrationComponent } from './pages/registration/registration.component';
import { SdkInAppComponent } from './pages/private/test-area/downloads/sdk-in-app/sdk-in-app.component';
import { ConfigurationsSolutionsComponent } from './pages/private/test-area/configurations/configurations-solutions/configurations-solutions.component';
import { ConfigurationsPaymentMethodsComponent } from './pages/private/test-area/configurations/configurations-payment-methods/configurations-payment-methods.component';
import { TransactionsListComponent } from './_components/transactions-list/transactions-list.component';
import { InitFormComponent } from './_components/init-form/init-form.component';
import { PluginDownloadComponent } from './pages/private/test-area/downloads/plugin-download/plugin-download.component';
import { SatispayComponent } from './pages/private/test-area/configurations/configurations-payment-methods/satispay/satispay.component';
import { PaypalComponent } from './pages/private/test-area/configurations/configurations-payment-methods/paypal/paypal.component';
import { ApplepayConfigurationComponent } from './pages/private/test-area/configurations/configurations-payment-methods/applepay-configuration/applepay-configuration.component';
import { LicenseContainerComponent } from './_components/license-container/license-container.component';
import { ClickPayComponent } from './pages/public/beyond-payments/click-pay/click-pay.component';
import { NotificationsComponent } from './pages/public/beyond-payments/notifications/notifications.component';
import { SecurityComponent } from './pages/public/beyond-payments/security/security.component';
import { PreAuthorizationsComponent } from './pages/public/beyond-payments/pre-authorizations/pre-authorizations.component';
import { RefundsComponent } from './pages/public/beyond-payments/refunds/refunds.component';
import { OutcomeComponent } from './pages/public/beyond-payments/outcome/outcome.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ProdConfigurationsComponent } from './pages/private/prod-configurations/prod-configurations.component';
import { ProdConfigurationsSolutionsComponent } from './pages/private/prod-configurations/prod-configurations-solutions/prod-configurations-solutions.component';
import { ProdConfigurationsPaymentMethodsComponent } from './pages/private/prod-configurations/prod-configurations-payment-methods/prod-configurations-payment-methods.component';
import { TransactionSummaryComponent } from './pages/private/transaction-summary/transaction-summary.component';
import { ProdTransactionsSummaryComponent } from './pages/private/prod-configurations/prod-transactions-summary/prod-transactions-summary.component';
import { ActionsComponent } from './_components/actions/actions.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { OperationButtonsComponent } from './_components/operation-buttons/operation-buttons.component';
import { PrivacyDialogComponent } from './_components/privacy-dialog/privacy-dialog.component';
import { IdentifiersDialogComponent } from './_components/identifiers-dialog/identifiers-dialog.component';
import { PluginTableComponent } from './pages/private/test-area/downloads/plugin-table/plugin-table.component';
import { SelectMultimerchantComponent } from './_components/select-multimerchant/select-multimerchant.component';
import { LicenseContainerProdComponent } from './_components/license-container-prod/license-container-prod.component';
import { SatispayTestAreaComponent } from './pages/private/test-area/configurations/configurations-payment-methods/satispay-test-area/satispay-test-area.component';
import { ShowdownModule } from 'ngx-showdown';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '',
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    TestAreaComponent,
    TransactionsComponent,
    ConfigurationsComponent,
    DownloadsComponent,
    DocumentsComponent,
    SolutionsComponent,
    PaymentMethodsComponent,
    BeyondPaymentsComponent,
    ApiDocsComponent,
    HomeComponent,
    IntlPhoneComponent,
    IntegrationDialogComponent,
    CustomDataTableComponent,
    EasyComponent,
    PluginComponent,
    SmartComponent,
    InAppComponent,
    ContainerCodeComponent,
    RightDrawerMenuComponent,
    WalletComponent,
    AlternativePaymentsComponent,
    BancomatpayComponent,
    ApplepayComponent,
    AlipayComponent,
    WechatpayComponent,
    LeftDrawerMenuComponent,
    SearchBarComponent,
    ConfirmDialogComponent,
    LoaderComponent,
    RegistrationComponent,
    SdkInAppComponent,
    ConfigurationsSolutionsComponent,
    ConfigurationsPaymentMethodsComponent,
    TransactionsListComponent,
    InitFormComponent,
    PluginDownloadComponent,
    SatispayComponent,
    PaypalComponent,
    ApplepayConfigurationComponent,
    LicenseContainerComponent,
    ApicheckoutComponent,
    ClickPayComponent,
    NotificationsComponent,
    SecurityComponent,
    PreAuthorizationsComponent,
    RefundsComponent,
    OutcomeComponent,
    ProdConfigurationsComponent,
    ProdConfigurationsSolutionsComponent,
    ProdConfigurationsPaymentMethodsComponent,
    TransactionSummaryComponent,
    ProdTransactionsSummaryComponent,
    ActionsComponent,
    OperationButtonsComponent,
    PrivacyDialogComponent,
    IdentifiersDialogComponent,
    PluginTableComponent,
    SelectMultimerchantComponent,
    LicenseContainerProdComponent,
    SatispayTestAreaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ReactiveFormsModule,
    FormsModule,
    CustomMaterialModule,
    DragDropModule,
    HttpClientModule,
    CodemirrorModule,
    NgDynamicBreadcrumbModule,
    ShowdownModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
