import { IdentifiersDialogComponent } from './../identifiers-dialog/identifiers-dialog.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { IconRegistryService } from './../../_services/icon-registry.service';
import { IntegrationDialogComponent } from './../integration-dialog/integration-dialog.component';
import { ConfigurationService } from './../../_services/configuration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IntegrationService } from './../../_services/integration.service';
import { LoaderService } from './../../_services/loader.service';
import { PaymentService } from './../../_services/payment.service';
import { PaymentsDataSource } from './../../_cdk/payments-data-source';
import { environment } from './../../../environments/environment.prod';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatSort } from '@angular/material/sort';
import { Integration } from '../../_models/integration';
import { IQParameter } from '../../_models/iqparameter';

import { map, startWith } from 'rxjs/operators';
import { DrawerService } from '../../_services/drawer.service';
import { merge, Subscription } from 'rxjs';

export type License = {server: string, wizard: string, html: string, connect: string, js: string, ios: string, android: string}
import {
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
  animations: [
    trigger('inOutButtons', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('600ms ease-in', style({ opacity: 1, transform: 'translateX(0%)' })),
      ]),
    ]),
  ]
})
export class TransactionsListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[];
  paymentsSource: PaymentsDataSource;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  gateways: Array<string> = ['IGFS', 'Computop', 'MyBank '];
  circuiti: Array<string> = ['MasterCard', 'Visa', 'Diners', 'American Express'];
  filters: Array<string> = ['amount', 'shop_id', '_id'];

  qry: Array<IQParameter> = [];

  licenses: License = {server: null, wizard: null, html: null, connect: null, js: null, ios: null, android: null};


  host: string = environment.host;
  redirect_host: string = environment.redirect_host;

  copiedKey = $localize`:@@snackBarMessage-CopiedKey:Chiave copiata`;

  form: UntypedFormGroup;
  filterBy: string;
  mapFilter: any;

  activeMediaQuery: string;

  drawerSubscription: Subscription;

  constructor(
    private paymentService: PaymentService,
    private loader: LoaderService,
    public breakpointObserver: BreakpointObserver,
    private integrationService: IntegrationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private configurationService: ConfigurationService,
    private iconRegistryService: IconRegistryService,
    private fb: UntypedFormBuilder,
    private drawerService: DrawerService,
  ) {
    this.iconRegistryService.registryIconTransaction();
    this.setHost();
  }

  ngOnInit(): void {
    this.paymentsSource = new PaymentsDataSource(this.paymentService, this.loader);
    this.getIntegrations();
    this.form = this.buildForm();
    this.genLabels();
    this.resizeTable();
    this.subscribeToDrawer();
  }
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      map(() => this.loadPayments())
    ).subscribe()
  }

  ngOnDestroy() {
    this.drawerSubscription.unsubscribe();
    this.breakpointObserver.ngOnDestroy();
  }

  loadPayments() {
    const sort = this.sort.direction === 'desc' ? '-' + this.sort.active : this.sort.active;
    this.paymentsSource.loadPayments(
      this.qry,
      sort,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      cerca: [''],
      data: [''],
    });
  }

  applicaFiltri() {
    this.qry = [];
    this.paginator.pageIndex = 0;
    if (this.form.value.data) {
      let date = this.form.value.data.format('YYYY-MM-DD');
      this.qry.push({ key: 'from_date', value: date });
    }
    if (!this.form.value.cerca) { 
      return this.loadPayments(); 
    }
    this.qry.push({ key: this.filterBy, value: this.filterBy === 'amount' ? this.form.value.cerca.toFixed(2) : this.form.value.cerca });
    this.loadPayments();
  }

  selectChange(event) {
    this.form.controls.cerca.setValue(null);
  }

  showIntegrationDialog(id: string) {
    this.paymentService.getPaymentID(this.licenses.server, id).subscribe(encryptedID => {
      if (encryptedID) {
        const tabsData = {
          licenses: this.licenses,
          paymentID: encryptedID,
        }
        this.dialog.open(IntegrationDialogComponent, {
          panelClass: 'integration-dialog-container',
          width: '58%',
          data: tabsData
        });
      }
    });
  }

  setHost() {
    const filters = [{ key: 'tenant', value: 'AXEPTA' }];
    this.configurationService.getConfig(filters).subscribe(
      (data: any) => {
        if (data.items.length > 0) {
          let setting = data.items[0] && data.items[0].settings ? data.items[0].settings : null;
          if(setting){
            for (const iterator of setting) {
              this.host = iterator.key === 'host' ? iterator.value : this.host;
              this.redirect_host = iterator.key === 'host_redirect' ? iterator.value : this.redirect_host;
            }
          }
        }
    })
  }

  getIntegrations() {
    this.integrationService.getIntegrationsByMerchant().subscribe((data: any) => {
      if (data && data.items) {
        data.items.forEach((element: Integration) => {
          this.licenses[element.type.toLowerCase()] = element.license_key;
        });
      }
    });
  }

  resizeTable() {
    let myBreakPoints = [Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge];
    this.breakpointObserver.observe(myBreakPoints)
    .subscribe((bpState: BreakpointState) => {
      if (bpState.breakpoints[Breakpoints.XSmall]) {
        this.activeMediaQuery = Breakpoints.XSmall;
        this.displayedColumns = ['state', 'amount', 'actions'];
      }
      if (bpState.breakpoints[Breakpoints.Small]) {
        this.activeMediaQuery = Breakpoints.Small;
        this.displayedColumns = ['createdAt', 'state', 'amount', 'identificativi', 'actions'];
      }
      if (bpState.breakpoints[Breakpoints.Medium]) {
        this.activeMediaQuery = Breakpoints.Medium;
        this.displayedColumns = ['createdAt', 'state', 'state_3ds', 'amount', 'identificativi', 'actions'];
      }
      if (bpState.breakpoints[Breakpoints.Large]) {
        this.activeMediaQuery = Breakpoints.Large;
        this.displayedColumns = ['createdAt', 'state', 'state_3ds', 'amount', 'circuit', 'wallet_token', 'card_token', 'number', 'transactionID', 'identificativi', 'actions'];
        if (this.drawerService.isOpen) {
          this.displayedColumns = ['createdAt', 'state', 'state_3ds', 'amount', 'circuit', 'wallet_token', 'card_token', 'identificativi', 'actions'];
        }
      }
      if (bpState.breakpoints[Breakpoints.XLarge]) {
        this.activeMediaQuery = Breakpoints.XLarge;
        this.displayedColumns = ['createdAt', 'state', 'state_3ds', 'amount', 'circuit', 'wallet_token', 'card_token', 'number', 'transactionID', 'identificativi', 'actions'];
      }
    });
  }

  subscribeToDrawer() {
    this.drawerSubscription = this.drawerService.isOpen$.subscribe(isOpen => {
      if (this.activeMediaQuery !== Breakpoints.Large) { return; }
      isOpen ?
      this.displayedColumns = ['createdAt', 'state', 'state_3ds', 'amount', 'circuit', 'wallet_token', 'card_token', 'number', 'identificativi', 'actions']
      : this.displayedColumns = ['createdAt', 'state', 'state_3ds', 'amount', 'circuit', 'wallet_token', 'card_token', 'number', 'transactionID', 'identificativi', 'actions'];
    })
  }

  copyToken(token: string) {
    const listener = (e) => {
      e.clipboardData.setData('text/plain', (token));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.openSnackBar(this.copiedKey, '');
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  redirect(id: string) {
    let encryptedID: string;
    this.paymentService.getPaymentID(this.licenses.wizard, id).subscribe(res => {
      if (res) {
        encryptedID = res;
        const host = this.redirect_host ? this.redirect_host : environment.redirect_host;
        const urlPath = encryptedID + '/' + this.licenses.wizard;
        const redirectUrl = host + urlPath;
        window.open(
          redirectUrl,
          '_blank'
        );
      }
    });
  }

  genLabels() {
    this.paginator._intl.itemsPerPageLabel = $localize`:@@table-itemsPerPageLabel:Elementi per pagina`;
    this.paginator._intl.nextPageLabel = $localize`:@@table-nextPageLabel:Pagina successiva`;
    this.paginator._intl.previousPageLabel = $localize`:@@table-previousPageLabel:Pagina precedente`;
    this.paginator._intl.firstPageLabel = $localize`:@@table-firstPageLabel: Prima pagina`;
    this.paginator._intl.lastPageLabel = $localize`:@@table-lastPageLabel: Ultima pagina`;

    this.mapFilter = {
      amount: $localize`:@@placeholder-Amount:Importo`,
      shop_id: 'Shop ID',
      _id: $localize`:@@idpayment:ID pagamento`
    };
  }

  openDialogIdentifiers(operation) {
    this.dialog.open(IdentifiersDialogComponent, {
      panelClass: 'identifiers-dialog-container',
      width: '58%',
      data: operation
    });
  }
}
