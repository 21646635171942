import { Component, OnInit } from '@angular/core';
import { IntegrationService } from '../../../../_services/integration.service';
import { MerchantService } from '../../../../_services/merchant.service';
import { EndPoints } from '../../test-area/configurations/configurations-solutions/configurations-solutions.component';
import {ConfigurationService} from '../../../../_services/configuration.service';

@Component({
  selector: 'app-prod-configurations-solutions',
  templateUrl: './prod-configurations-solutions.component.html',
  styleUrls: ['./prod-configurations-solutions.component.scss']
})
export class ProdConfigurationsSolutionsComponent implements OnInit {

  currentMerchant: any;
  qry: Array<object>;
  accessEndpoint: string;
  merchantID: string;
  productionLicense: any;
  productionMerchantsLicense: any;
  host: string = '{{host_production}}';
  displayedColumnsProduction = { soluzione: $localize`:@@th-Solution:Soluzioni`, collaudo: $localize`:@@th-Production:Produzione`, };
  endpointsProduction: EndPoints[] = [
    {soluzione: 'API Checkout', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
    {soluzione: 'EASY', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
    {soluzione: 'SMART', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
    {soluzione: 'In-App IOS', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
    {soluzione: 'In-App ANDROID', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}}
  ];


  constructor(
    private integrationService: IntegrationService,
    private merchantService: MerchantService,
    private configurationService: ConfigurationService
  ) {
    this.setHost();
    this.configurationService.accessEnv$.subscribe((env) => {
      this.accessEndpoint = env;
    });
  }

  ngOnInit(): void {
    this.merchantID = this.merchantService.currentMerchant._id;
    this.qry = [{ key: 'merchant', value: this.merchantID }];
    this.integrationService.getProductionLicense().subscribe((license: any) =>  this.productionMerchantsLicense = license.items);
  }

  setHost() {
    const filters = [{key: 'tenant', value: 'AXEPTA'}];
    this.configurationService.getConfig(filters).subscribe((data: any) => {
      if (data.items.length > 0) {
        const host = data.items[0].settings.find((conf: any) => conf.key === 'host_production');
        this.host = host ? host.value : this.host;
        this.endpointsProduction = [
          {soluzione: 'API Checkout', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
          {soluzione: 'EASY', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
          {soluzione: 'SMART', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
          {soluzione: 'In-App IOS', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
          {soluzione: 'In-App ANDROID', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}}
        ];
      }
    });
  }

  redirect() {
    let url = this.accessEndpoint ? this.accessEndpoint : "https://pay.worldlineitalia.it/access"
    if(!this.accessEndpoint) console.log('no access endpoint found');
    window.open(url, '_blank');
  }

  setCurrentMerchant(currentMerchant) {
    this.currentMerchant = currentMerchant;
    this.productionLicense = currentMerchant.integrations;
    this.merchantService.currentProductionMerchant = currentMerchant;
  }

}
