export interface IProduct {
    image: string;
    quantity: string;
    description: string;
}

export interface IProductFormValue extends IProduct {
    price: number,
}

export class ProductDTO implements IProduct {
    price: string;
    constructor(
        public image: string,
        public quantity: string,
        public description: string,
        price: number,
    ) {
        this.price = price.toFixed(2);
    }
}