<h1 class="save-line-head">Satispay</h1>
<form [formGroup]="form">
  <div formGroupName="definitions">
    <mat-form-field floatLabel="never" appearance="outline" style="width: 100%;">
        <input matInput placeholder="SECURITY_TOKEN" formControlName="parameters" required>
    </mat-form-field>
  </div>
  <div>
    <button mat-flat-button i18n='@@btn-Save' [disabled]='!form.valid' class="save-line-button btn-active-green" (click)='save()'>Salva</button>
  </div>
</form>
