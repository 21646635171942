<!-- Content Icon Right -->
<div class="right-buttons">
    <div>
      <mat-icon *ngIf='merchantService.accessAllowed !== true' class='dark-icon menuIconRight' svgIcon='user'
        (click)="navigateToSandbox()"></mat-icon>
      <mat-icon *ngIf='merchantService.accessAllowed' class='dark-icon menuIconRight' svgIcon='logout'
        (click)="logout()"></mat-icon>
      <mat-icon class='dark-icon menuIconRight' svgIcon='email'  style="margin-right: 0;"
        (click)="redirect('https://www.worldlineitalia.it/contatti/')"></mat-icon>
    </div>

    <div>
      <button *ngIf='merchantService.accessAllowed === true' mat-raised-button class="btn-active-green"
        style="margin: 0px 5px;;" i18n='@@btn-RequestNow'
        (click)="leads()">Richiedi ora</button>
      <button *ngIf='merchantService.accessAllowed !== true' mat-raised-button class="btn-active-green"
        style="margin: 0px 5px;;" i18n='@@btn-Register'
        (click)="navigateTo('public/registration')">Registrati</button>
    </div>

    <div style="margin: 10px 0;">
      <button class="lang-button" style="margin-right: 5px;" (click)="onChangeLanguage('it')"
        [ngStyle]="{'opacity' : currentLanguage === 'it' ? '1' : '0.5'}">
        <img alt="Italiano" src="assets/flags/it-flag.png" class="flag-lang">
      </button>
      <button class="lang-button" (click)="onChangeLanguage('en')"
        [ngStyle]="{'opacity' : currentLanguage === 'en' ? '1' : '0.5'}">
        <img alt="English" src="assets/flags/en-flag.png" class="flag-lang">
      </button>
    </div>
  </div>
