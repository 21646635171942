import { Payment } from './payment';

export const MOCKPAYMENT: Payment = {
  transaction_type: 'PURCHASE',
  transaction_timeout: '30000',
  brand: 'CREDITCARD',
  shopID: '',
  currency: 'EUR',
  language: 'IT',
  amount: 0.01,
  notifications: {
    name: 'Mario',
    email: 'mario@email.com',
    //smartphone: "+39",
  },
  addresses: [
    {
      type: 'SPEDIZIONE',
      addresseeName: 'Nome1',
      streetAddress_1: 'Via Condotti 33',
      streetAddress_2: '',
      zip: '00184',
      city: 'Roma',
      provinceState: 'Roma',
      country: 'Italia',
    },
  ],
  products: [
    {
      image: 'https:/www.immagine.it/immagine-prodotto',
      quantity: '1',
      description: 'Giacca in pelle',
      price: 199.0,
    },
  ],
  redirect_successUrl: 'https://www.merchant.it/transazione-eseguita',
  redirect_failureUrl: 'https://www.merchant.it/errore',
  callback_url: 'https://www.merchant.it/callback',
  additionals: [
    {
      key: $localize`:@@placeholder-Key:Chiave` + '1',
      value: $localize`:@@placeholder-Value:Valore` + '1',
    },
  ],
  addressesURI: null,
  txGroup: {
    tokenize: false,
    payCardToken: null,
    txIndicatorType: null,
    payInstrToken: null,
  },
};
