<h1 class="save-line-head" i18n='@@title-registration'>Registrazione</h1>
<h4 i18n='@@registration-intro'>Abbiamo bisogno di alcuni dati per iniziare…</h4>
<form [formGroup]="registrationForm" style="display: flex; justify-content: space-around; flex-flow: row wrap;">
  <mat-form-field floatLabel="never" appearance="outline" class="leftInputField" class="registration-input">
    <mat-icon matPrefix svgIcon='user' class="dark-icon"></mat-icon>
    <input matInput i18n-placeholder="@@placeholder-name-merchant" placeholder="Nome merchant" formControlName="name">
  </mat-form-field>

  <mat-form-field floatLabel="never" appearance="outline" class="leftInputField" class="registration-input">
    <mat-icon matPrefix svgIcon='email' class="dark-icon" style="margin-right: 4px;"></mat-icon>
    <input matInput placeholder="Email" formControlName="email">
  </mat-form-field>

  <mat-form-field floatLabel="never" appearance="outline" class="leftInputField" class="registration-input">
    <mat-icon matPrefix svgIcon='password' class="dark-icon"></mat-icon>
    <app-intl-phone i18n-placeholder="@@placeholder-phone" placeholder="Telefono" formControlName="phone"></app-intl-phone>
  </mat-form-field>
</form>

<div style="text-align: center; margin-bottom: 15px;">
  <button mat-flat-button [disabled]='!registrationForm.valid' class="save-line-button btn-active-green" (click)='registration()' i18n='@@btn-registration'>Inizia</button>
</div>

<h4 i18n='@@text-registration'>Cliccando sul tasto “Inizia” dichiari di aver letto e compreso <span style="font-weight: 400; cursor: pointer;" (click)='openDialogPrivacy()'>l’informativa privacy</span> nel rispetto della quale Worldline tratterà, in qualità di Titolare, tutti i dati che verranno da te forniti.</h4>
