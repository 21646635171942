<div class="save-line">
  <h3 i18n='@@filter'>Filtro</h3>
  <mat-divider style="flex: 1 1 auto; margin: 0 25px;"></mat-divider>
  <button mat-flat-button class="btn-active-green" [disabled]="!form.valid" (click)='applicaFiltri()'><span
      i18n='@@apply'>Applica</span></button>
</div>

<!--FILTER FORM-->
<form [formGroup]="form" style="display: flex; justify-content: space-around; flex-flow: row wrap;">
  <mat-form-field appearance="outline" style="flex-grow: 2; margin-right: 10px;">
    <input matInput [matDatepicker]="datePick" placeholder="Data transazione" i18n-placeholder="@@dataOperations"
      formControlName="data">
    <mat-datepicker-toggle matSuffix [for]="datePick" appearance=legacy>
      <mat-icon matDatepickerToggleIcon svgIcon="calendario" class="stats-icon dark-icon"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datePick></mat-datepicker>
  </mat-form-field>

  <mat-form-field floatLabel="never" appearance="outline" style="flex-grow: 2; margin-right: 10px;">
    <input *ngIf="filterBy !== 'amount'" matInput type='text' placeholder="Cerca" i18n-placeholder="@@placeholder-Search"
      formControlName="cerca">
    <input *ngIf="filterBy === 'amount'" matInput type='tel' currencyMask placeholder="Cerca"
      i18n-placeholder="@@placeholder-Search" formControlName="cerca">
  </mat-form-field>

  <mat-form-field appearance="outline" style="flex-grow: 0; margin-right: 10px;">
    <mat-select placeholder="Filtro" i18n-placeholder="@@filter" [(ngModel)]="filterBy"
      [ngModelOptions]="{standalone: true}" (selectionChange)="selectChange($event)" appearance="outline">
      <mat-option *ngFor='let f of filters' [value]='f'>{{ mapFilter[f] }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>

<!--TABLE-->
<div style="margin: 15px 0; ">
  <table aria-describedby="operations table" class='transactionTable' mat-table [dataSource]="paymentsSource" matSort
   matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
    <!--DATE-->
    <ng-container matColumnDef="createdAt">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header i18n='@@th-Date'> Data </th>
      <td mat-cell *matCellDef="let operation" class="date" style="word-spacing: 5px;"> {{operation.date.date}} <span>{{operation.date.time}}</span> <span class="hide-ms" style="font-size: 11px">:{{operation.date.ms}}</span></td>
    </ng-container>
    <!--STATE-->
    <ng-container matColumnDef="state">
      <th scope="col" mat-header-cell *matHeaderCellDef i18n='@@th-State'> Stato </th>
      <td mat-cell *matCellDef="let operation">
        <mat-icon class="svg-icon-status" *ngIf="operation.state === 'MISSING_DATA'" [svgIcon]="'failed'"
          matTooltip="{{ operation.errorDesc }}" matTooltipClass="tooltip" matTooltipPosition='before'></mat-icon>
        <mat-icon class="svg-icon-status" *ngIf="operation.state === 'failed'" [svgIcon]="operation.state"
          matTooltip="Pagamento fallito: {{ operation.errorDesc }}" matTooltipClass="tooltip"
          matTooltipPosition='before' i18n-matTooltip='@@matTooltip-PaymentFailed'></mat-icon>
        <mat-icon class="svg-icon-status" *ngIf="operation.state === 'INIT'" [svgIcon]="operation.state"
          matTooltip="Pagamento inizializzato" matTooltipClass="tooltip" matTooltipPosition='before'
          i18n-matTooltip='@@matTooltip-InitializedPayment'></mat-icon>
        <mat-icon class="svg-icon-status" *ngIf="operation.state === 'PENDING'" [svgIcon]="operation.state"
          matTooltip="Pagamento in attesa" matTooltipClass="tooltip" matTooltipPosition='before'
          i18n-matTooltip='@@matTooltip-PaymentonHold'></mat-icon>
        <mat-icon class="svg-icon-status" *ngIf="operation.state === 'PROGRESS'" [svgIcon]="operation.state"
          matTooltip="Pagamento preso in carico dal gateway" matTooltipClass="tooltip" matTooltipPosition='before'
          i18n-matTooltip='@@matTooltip-PaymentTakeninCharge '></mat-icon>
        <mat-icon class="svg-icon-status" *ngIf="operation.state === 'success'" [svgIcon]="operation.state"
          matTooltip="Pagamento completato" matTooltipClass="tooltip" matTooltipPosition='before'
          i18n-matTooltip='@@matTooltip-PayCompleted'></mat-icon>
        <mat-icon class="svg-icon-status" *ngIf="operation.state === 'RECURRENT'" [svgIcon]="operation.state"
          matTooltip="Pagamento ricorrente" matTooltipClass="tooltip" matTooltipPosition='before'></mat-icon>
      </td>
    </ng-container>
    <!--3DS-->
    <ng-container matColumnDef="state_3ds">
      <th scope="col" mat-header-cell *matHeaderCellDef> 3DS </th>
      <td mat-cell *matCellDef="let operation">
        <mat-icon *ngIf="operation?.is3dEnabled" class="svg-icon-3ds" [svgIcon]="operation.state_3ds">
        </mat-icon>
      </td>
    </ng-container>
    <!--AMOUNT-->
    <ng-container matColumnDef="amount">
      <th scope="col" mat-header-cell *matHeaderCellDef i18n='@@th-Amount' class="centredContent" style="padding:0 8px;"> Importo </th>
      <td mat-cell *matCellDef="let operation" class="centredContent"> {{operation.amount }} {{operation.currency }}</td>
    </ng-container>
    <!--CIRCUIT-->
    <ng-container matColumnDef="circuit">
      <th scope="col" mat-header-cell *matHeaderCellDef style="min-width: 60px;" i18n='@@th-Circuit' class="centredContent"> Circuito </th>
      <td mat-cell *matCellDef="let operation" class="centredContent">
        <div>
          <img alt="circuit-icon" *ngIf='operation.circuit' class='circuiti'
            src="assets/images/icons/{{operation.circuit}}.png">
        </div>
      </td>
    </ng-container>
    <!--GATEWAY-->
    <!-- <ng-container matColumnDef="gateway">
      <th scope="col" mat-header-cell *matHeaderCellDef style="min-width: 60px;"> Gateway </th>
      <td mat-cell *matCellDef="let operation"> {{operation.gateway}} </td>
    </ng-container> -->
    <!--WALLET-TOKEN-->
    <ng-container matColumnDef="wallet_token">
      <th scope="col" mat-header-cell *matHeaderCellDef style="min-width: 70px; padding: 0 5px; text-align: center;"> Token Wallet</th>
      <td mat-cell *matCellDef="let operation" class="centredContent">
        <mat-icon *ngIf='operation.wallet_token && operation.wallet_token != ""' svgIcon='token'
          class='tokenSvg dark-icon' matTooltip="{{ operation.wallet_token }}" matTooltipClass="tooltip"
          matTooltipPosition='after' (click)='copyToken(operation.wallet_token)'></mat-icon>
      </td>
    </ng-container>
    <!--CARD TOKEN-->
    <ng-container matColumnDef="card_token">
      <th scope="col" mat-header-cell *matHeaderCellDef style="min-width: 70px; padding: 0 5px; text-align: center;" i18n="@@th-tokenCard"> Token Carta</th>
      <td mat-cell *matCellDef="let operation" class="centredContent">
        <mat-icon *ngIf='operation.card_token' svgIcon='token' class='tokenSvg dark-icon'
          matTooltip="{{ operation.card_token }}" matTooltipClass="tooltip" matTooltipPosition='after'
          (click)='copyToken(operation.card_token)'></mat-icon>
      </td>
    </ng-container>
    <!--NUMBER-->
    <ng-container matColumnDef="number">
      <th scope="col" mat-header-cell *matHeaderCellDef i18n='@@th-Number' class="card-number"> Numero </th>
      <td mat-cell *matCellDef="let operation"> <div>{{operation.number}}</div> </td>
    </ng-container>
    <!--TRANSACTION ID-->
    <ng-container matColumnDef="transactionID">
      <th scope="col" mat-header-cell *matHeaderCellDef i18n='@@th-idTransaction' style="padding:0 8px;"> ID TRANSAZIONE
      </th>
      <td mat-cell *matCellDef="let operation"> {{ operation.transactionID }} </td>
    </ng-container>

    <!-- IDENTIFIERS -->
    <ng-container matColumnDef="identificativi">
      <th scope="col" mat-header-cell *matHeaderCellDef i18n='@@th-identifiers'> IDENTIFICATIVI </th>
      <td mat-cell *matCellDef="let operation" align='center'>
        <mat-icon class="dark-icon" matTooltip='Payment ID, Shop ID' (click)='openDialogIdentifiers(operation)' svgIcon='open-in-new-tab' style="cursor:pointer;"></mat-icon>
      </td>
    </ng-container>

    <!--ACTIONS-->
    <ng-container matColumnDef="actions">
      <th scope="col" mat-header-cell *matHeaderCellDef style="width: 130px;"></th>
      <td mat-cell *matCellDef="let operation">
        <ng-container *ngIf="operation.state !== 'RECURRENT'">

          <ng-container *ngIf='!operation.completed && operation.state !== "PROGRESS"'>
            <div @inOutButtons>
            <!--BUTTON SHOW INTEGRATION DIALOG (CODEMIRROR)-->
            <button mat-icon-button (click)='showIntegrationDialog(operation.id)'
            matTooltip="Visualizza esempi di integrazione per questo pagamento" matTooltipClass="tooltip"
            matTooltipPosition='above' i18n-matTooltip='@@matTooltip-TypeIntegrations' [disableRipple]="true"
            [disabled]="!licenses?.wizard && !licenses?.js && !licenses?.android && !licenses?.ios">
              <mat-icon class='actions-icon'
              svgIcon="integrazioni"></mat-icon>
            </button>
            <!--BUTTON REDIRECT TO TEST-->
            <button mat-icon-button (click)='redirect(operation.id)' matTooltip="Test di integrazione con Wizard" matTooltipClass="tooltip"
            matTooltipPosition='above' i18n-matTooltip='@@matTooltip-IntegrationWizard' [disableRipple]="true"
            [disabled]="!licenses?.wizard">
              <mat-icon class='actions-icon' svgIcon="test"
              ></mat-icon>
            </button>
            </div>
          </ng-container>

          <!--BUTTONS CONFIRM/VOID/CREDIT-->
          <!-- <ng-container *ngIf="operation.gateway !== 'MYBANK' && operation.completed
          && operation.state !== 'failed' && operation.state !== 'PROGRESS'
          && !operation?.isCreditExpired">
            <app-operation-buttons [operation]="operation" [licenseServer]="licenses?.server"></app-operation-buttons>
          </ng-container> -->

        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-container *ngIf="paymentsSource.count <= 0 || paymentsSource.count == undefined">
    <h3 i18n='@@text-noTransactions'
      style="display: flex; min-height: 250px; align-items: center; justify-content: center;">Nessuna transazione
      presente</h3>
  </ng-container>

  <mat-paginator [length]="paymentsSource ? paymentsSource.count : 0" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true">
  </mat-paginator>
</div>

<div style="text-align: end;">
  <a routerLink="/sandbox/transactions" mat-icon-button class="add-payment" aria-label="Create payment"
    matTooltip="Inizializza pagamento" i18n-matTooltip='@@title-InitializePayment' matTooltipClass="tooltip" matTooltipPosition='above'>
    <mat-icon>add</mat-icon>
</a>
</div>
