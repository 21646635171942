import { TextIntro } from './../_models/strapi-file';
import { Pdf, Plugin, Sdk } from 'src/app/_models/strapi-file';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StrapiService {

  strapiEndpoint: string;

  private strapiUrlSbj = new BehaviorSubject(null);
  urlStrapi$ = this.strapiUrlSbj.asObservable();

  constructor(
    private http: HttpClient,
  ) {
     this.getStrapiEnv();
  }

  getStrapiEnv(){
    return this.http.get('/public_develop/v1/strapi/env', {}).subscribe((env: any) => {
      this.strapiEndpoint = env.strapiEnv;
      this.strapiUrlSbj.next(env.strapiEnv);
    });
  }


  getPlugins() {
    return this.http.get(this.strapiEndpoint + 'plugins')
    .pipe(
      map((response: Array<any>) => response.map(plugin => new Plugin(plugin)))
    );
  }

  getPlugin(id) {
    return this.http.get(`${this.strapiEndpoint}plugins/${id}`)
    .pipe(
      map((plugin: Plugin) => new Plugin(plugin))
    );
  }


  getSdks() {
    return this.http.get(this.strapiEndpoint + 'sdks')
    .pipe(
      map((response: Array<any>) => response.map(sdk => new Sdk(sdk)))
    );
  }

  getDocuments() {
    return this.http.get(this.strapiEndpoint + 'documents')
    .pipe(
      map((response: Array<any>) => response.map(pdf => new Pdf(pdf)))
    );
  }

  getTextIntro() {
    return this.http.get(this.strapiEndpoint + 'text-intro').pipe(
      map((response => new TextIntro(response)))
    );
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(`${this.strapiEndpoint}${url.substring(1)}` , {responseType: 'blob'});
  }

}
