import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {
  constructor(private http: HttpClient) { }

  getSyncGateways(merchantId: string){
    return this.http.get<any>(`/develop/v1/sync/merchants/${merchantId}/gateways`);
  }

  updateSyncGateways(data: any, merchantId: string){
    return this.http.put(`/develop/v1/sync/merchants/${merchantId}/gateways`, data);
  }

  getGateways(id){
    return this.http.get<any>(`/develop/v1/gateways?merchant=${id}&type=IGFS`);
  }

  getGateway(id){
    return this.http.get<any>('/develop/v1/gateways/' + id);
  }

  updateGateways(data: any, id: string){
    return this.http.put('/develop/v1/gateways/' + id, data);
  }

  creategateway(data: any) {
    return this.http.post('/develop/v1/gateways/', data);
  }

}
