import { Component, OnInit } from '@angular/core';
import { MerchantService } from 'src/app/_services/merchant.service';
import { IntegrationService } from 'src/app/_services/integration.service';

@Component({
  selector: 'app-prod-configurations',
  templateUrl: './prod-configurations.component.html',
  styleUrls: ['./prod-configurations.component.scss']
})
export class ProdConfigurationsComponent implements OnInit {

  constructor(
    private merchantService: MerchantService,
    private integrationService: IntegrationService
  ) { }

  ngOnInit(): void {
    this.integrationService.getProductionLicense().subscribe((license: any) =>  this.merchantService.currentProductionMerchant = license.items[0] ? license.items[0] : null);
  }

}
