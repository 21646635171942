import { getCurrencySymbol } from '@angular/common';
import { IOperation } from './ioperation';
import { IOperationDTO } from './ioperation-dto';
import * as moment from 'moment';
import { Transaction } from './transaction';

type TGridDate = { date: string; time: string; ms: string };

const ERROR_NOT_DEFINED = $localize`:@@error_not_defined:Errore non definito`;
const TRANSACTION_NOT_FOUND = $localize`:@@transaction_not_found:Transazione non trovata`;

export class Operation {
  public id: string;
  public date: TGridDate;
  public state: string;
  public state_3ds: string;
  public amount: string;
  public currency: string;
  public circuit: string;
  public gateway: string;
  public shop_id: string;
  public paymentID: string;
  public wallet_token: string;
  public card_token: string;
  public traceChainId: string;
  public number: string;
  public transaction_type: string;
  public transactionID: string;
  public errorDesc: string;
  public createdAt: string;
  public completed: boolean;
  public is3dEnabled: boolean;
  public isCreditExpired: boolean;
  public score: number;
  public decision: string;
  public challengeIndicator: string;
  public challengeIndicatorDescription: string;
  public mode: string;
  public txIndicatorTypeOrigin: string;
  public txIndicatorType: string;
  public info3ds: {
    transStatus: string;
    transStatusReason: string;
    transStatusReasonDescription: string;
  };
  public reportData: { fraudRate: string; score: string; SCA: string };
  public scaExemptionType: string;
  constructor(
    data: IOperationDTO,
    state: string,
    transaction?: Transaction,
    circuit?: string,
    _3ds?: string,
    number?: string,
    transactionID?: string,
    errorDesc?: string
  ) {
    this.id = data._id;
    this.createdAt = data.createdAt;
    this.circuit = circuit ? circuit : '';
    this.state_3ds = _3ds;
    this.txIndicatorTypeOrigin = data.txIndicatorTypeOrigin;
    this.txIndicatorType = data.txIndicatorType;
    this.info3ds = data.info3ds;
    this.number = number;
    this.transaction_type = data.transaction_type;
    this.transactionID = transactionID;
    this.date = data.createdAt ? formatDate(data.createdAt) : null;
    this.is3dEnabled = is3dEnabled(this.circuit);
    this.isCreditExpired = isCreditExpired(this);
    this.state = state;
    this.paymentID = data.paymentID;
    this.shop_id = data.shop_id;
    this.wallet_token = data.payInstrToken;
    this.card_token = data.payCardToken;
    this.traceChainId = data.traceChainId;
    if (data.payment_type === 'RECURRENT') {
      return;
    }
    this.amount = data.amount;
    this.currency = getCurrencySymbol(data.currency, 'narrow');
    this.completed = data.state === 'COMPLETED';
    this.errorDesc = errorDesc ? errorDesc : TRANSACTION_NOT_FOUND;
    this.gateway = transaction ? transaction.gateway : null;
    if (data.fraud && data.fraud.feedzai) {
      this.score = data.fraud.feedzai.score;
      this.decision = data.fraud.feedzai.decision;
    }
    if (data.fraud && data.fraud.fraudChoose) {
      this.challengeIndicator = data.fraud.fraudChoose.challengeIndicator;
      this.challengeIndicatorDescription =
        data.fraud.fraudChoose.challengeIndicatorDescription;
      this.mode = data.fraud.fraudChoose.mode;
      this.reportData = data.fraud.fraudChoose.reportData;
      this.scaExemptionType = data.fraud.fraudChoose.scaExemptionType;
    }
  }

  static createFromIGFS(
    dto: IOperationDTO,
    transaction: Transaction,
    state: string
  ) {
    return new Operation(
      dto,
      state,
      transaction,
      transaction.brand,
      stati_3ds(transaction.outcome),
      transaction.outcome.maskedPan,
      transaction.outcome.tranID,
      transaction.outcome.errorDesc
        ? transaction.outcome.errorDesc
        : ERROR_NOT_DEFINED
    );
  }

  static createFromIGFS2(
    dto: IOperationDTO,
    transaction: Transaction,
    state: string
  ) {
    const number = transaction.outcome.cardInfo
      ? transaction.outcome.cardInfo.maskedpan
      : null;
    const tranId = transaction.outcome.txRes
      ? transaction.outcome.txRes.tranId
      : null;
    const error =
      transaction.outcome.txHead && transaction.outcome.txHead.errDescription
        ? transaction.outcome.txHead.errDescription
        : ERROR_NOT_DEFINED;
    return new Operation(
      dto,
      state,
      transaction,
      transaction.brand,
      'verde',
      number,
      tranId,
      error
    );
  }

  static createFromComputop(
    dto: IOperationDTO,
    transaction: Transaction,
    state: string
  ) {
    return new Operation(
      dto,
      state,
      transaction,
      transaction.brand,
      stati_3ds(transaction.outcome),
      transaction.outcome.maskedPan,
      transaction.outcome.TransID,
      transaction.outcome.Description
    );
  }

  static createFromMyBank(
    dto: IOperationDTO,
    transaction: Transaction,
    state: string
  ) {
    return new Operation(
      dto,
      state,
      transaction,
      'MYBANK',
      null,
      transaction.outcome.iban,
      transaction.outcome.transactionID,
      transaction.outcome.transactionStatus
    );
  }

  static createFromPayPal(
    dto: IOperationDTO,
    transaction: Transaction,
    state: string
  ) {
    const tranId = transaction.outcome.captureID
      ? transaction.outcome.captureID
      : transaction.outcome.authorizationID;
    return new Operation(
      dto,
      state,
      transaction,
      'PAYPAL',
      null,
      null,
      tranId,
      transaction.outcome.status
        ? transaction.outcome.status
        : ERROR_NOT_DEFINED
    );
  }
}

function formatDate(date: string): TGridDate {
  return {
    date: moment(date).format('DD.MM.YYYY'),
    time: moment(date).format('HH:mm:ss'),
    ms: moment(date).get('milliseconds').toString(),
  };
}

function stati_3ds(obj: any) {
  return obj.authStatus === 'Y' && !obj.error ? 'verde' : 'rosso';
}

function is3dEnabled(circuit: string) {
  return ['SATISPAY', 'BANCOMATPAY', 'APPLEPAY', 'WECHAT', 'ALIPAY', ''].every(
    (item) => item !== circuit
  );
}

function isCreditExpired(operation: IOperation) {
  if (operation.circuit !== 'SATISPAY' && operation.circuit !== 'BANCOMATPAY') {
    return false;
  }
  const numberOfDays = operation.circuit === 'SATISPAY' ? 60 : 30;
  const expiresAt = moment(operation.createdAt).add(numberOfDays, 'days');
  const now = moment();
  return expiresAt < now;
}
