import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MerchantService } from './merchant.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
    private http: HttpClient,
    private merchantService: MerchantService,
    ) { }

  filterIntegrationsByQuery(objectsArray: object[], populate: boolean = false, sort?: string, skip?: number, limit?: number) {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'populate': populate.toString()
    });

    let queryString: string = objectsArray.length > 0 ? '?' : '';
    objectsArray.forEach((query) => queryString += query['key'] + '=' + query['value']);
    return this.http.get<any>('/develop/v1/integrations' +  queryString, { headers });
  }

  getIntegrationsByMerchant() {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'populate': 'false',
    });

    let integrations = this.http.get<any>('/develop/v1/integrations?merchant=' + this.merchantService.currentMerchant._id, { headers });
    console.log('integrations: ', integrations);
    
    return integrations;
  }

  getServerLicense(merchantId: string) {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'populate': 'false',
    });

    return this.http.get<any>('/develop/v1/integrations?merchant=' +  merchantId, { headers }).pipe(
      map(res => res.items.find(el => el.type === 'SERVER'))
    );
  }

  getProductionLicense() {
    return this.http.get(`/develop/v1/sync/integrations`);
  }

  getProdServerLicense() {
    return this.http.get<any>('/develop/v1/sync/integrations').pipe(
      map(res => res.items.find(el => el.type === 'SERVER'))
    );
  }

  downloadSDKs(id: string, type: string){
    return this.http.get('/develop/v1/integrations/' + id + '/download/' + type, {responseType: 'blob'});
  }
}
