<div mat-dialog-content class="dialog-content">
    <h3 mat-dialog-title *ngIf="title" style="text-align: center;">{{title}}</h3>
    <h4 *ngIf="message">{{ message }}</h4>
</div>
<div mat-dialog-actions class="dialog-actions">
    <div *ngIf="type === 'ALERT'" class="dialog-actions">
        <button button mat-flat-button class="cancel btn-active-green" (click)="onNoClick()">
            <ng-container i18n='@@dialogbtn-Close'>Chiudi</ng-container>
        </button>
    </div>
    <div *ngIf="type === 'CONFIRM'" class="dialog-actions">
        <button mat-flat-button class="cancel btn-active-green" (click)="onNoClick()">
            <ng-container i18n='@@dialogbtn-Cancel'>Annulla</ng-container>
        </button>
        <button mat-flat-button class="confirm btn-active-green" [mat-dialog-close]="true" cdkFocusInitial>
            <ng-container i18n='@@dialogbtn-Confirm'>Conferma</ng-container>
        </button>
    </div>
</div>
