import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MerchantService } from '../_services/merchant.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanActivate {

  constructor(
    private router: Router,
    private merchantService: MerchantService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.merchantService.checkSession();
    if (!this.merchantService.accessAllowed) {
      return true;
    } else {
      this.router.navigate(['public/home'], {});
      return false;
    }
  }

}
