import { of, Observable, BehaviorSubject } from 'rxjs';
import { PaymentService } from './../_services/payment.service';
import { DataSource } from '@angular/cdk/table';
import { catchError, finalize } from 'rxjs/operators';
import { CollectionViewer } from '@angular/cdk/collections';
import { IQParameter } from '../_models/iqparameter';
import { IOperationDTO } from '../_models/ioperation-dto';
import { PaymentMap } from '../_models/payment-map';


export class PaymentsDataSource implements DataSource<any> {

  private paymentsSubject = new BehaviorSubject<any[]>([]);
  count: number;

  constructor(
    private paymentService: PaymentService,
    private loader,
  ) { }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.paymentsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.paymentsSubject.complete();
  }

  loadPayments(filter: IQParameter[], sort: string = 'asc', pageIndex: number = 0, pageSize: number) {
    this.loader.startLoader();
    this.paymentService.filterPaymentsByQuery(filter, true, sort, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loader.stopLoader())
      )
      .subscribe(payments => {
        const payload = payments.items.map((el: IOperationDTO) => PaymentMap.toView(el));
        this.count = payments.meta.count;
        this.paymentsSubject.next(payload);
      });
  }

}
