import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  accessEnv$ : BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private http: HttpClient) { }

  getConfig(objectsArray: object[] = [], populate: boolean = false){
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'populate': populate.toString()
    });
    let queryString: string = '';
    if (objectsArray) {
      queryString = objectsArray.length > 0 ? '?' : '';
      objectsArray.forEach((query) => queryString += query['key'] + '=' + query['value']);
    }
    return this.http.get<any>('/develop/v1/environments' + queryString, { headers });
  }

  newEnvironments(body: any) {
    return this.http.post<any>('/develop/v1/environments', body, {});
  }

  putConfig(id: string, body: any) {
    return this.http.put(`/develop/v1/environments/${id}`, body, {});
  }

  deleteEnvironmentById(id: string) {
    return this.http.delete<any>(`/develop/v1/environments/${id}`, {});
  }

  getAccessEnv(){
    let access = this.http.get('/public_develop/v1/access/env', {});
    console.log('access: ', access);
    
    return access;
  }

 
}
