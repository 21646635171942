<h1 class='save-line-head' i18n='@@transaction-summary'>Sommario transazioni</h1>
<h4 i18n='@@text-transumm'>Per vedere le transazioni in ambiente di produzione accedi a Byond</h4>

<div class="flex-row">
    <div style="width: 50%; margin-right: 25px;">
            <mat-card class="card" (click)="redirect('https://merchant.worldlineitalia.it/src/merchant.html#!/login')" >
                <mat-icon svgIcon='outlink' style="margin-right: 15px;"></mat-icon>
                <ng-container i18n='@@showtransaction-summary'>Vedi transazioni</ng-container>
                <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right
                </mat-icon>
            </mat-card>
    </div>
    <div style="width: 50%;">
    </div>
</div>