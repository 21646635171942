import { MerchantService } from './../../_services/merchant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-license-container-prod',
  templateUrl: './license-container-prod.component.html',
  styleUrls: ['./license-container-prod.component.scss']
})
export class LicenseContainerProdComponent implements OnInit {

  _licenseArray: any;

  @Input() set licenseArray(arr) {
    if (!arr) { return; }
    this._licenseArray = arr;
    if (this.licenseArray) {
      this.activeKey = null;
      this.activeLicenseDiv = true;
      this.setIntegrationType(this.licenseArray);
    }
  }

  get licenseArray() {return this._licenseArray}

  activeLicenseDiv: boolean = false;
  licenseServer: string;
  licenseWizard: string;
  licenseJS: string;
  licenseAndroid: string;
  licenseIos: string;

  activeKey: string = null;

  licenseCopied = $localize`:@@snackBarMessage-CopiedLicense:Licenza copiata`;
  licenseNotEnable = $localize`:@@snackBarMessage-LicenseNotEnable:Licenza non abilitata`;

  constructor(
    private snackBar: MatSnackBar,
    public merchantService: MerchantService
  ) {

  }

  ngOnInit(): void { }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  copyLicense(license: string){
    const listener = (e) => {
      e.clipboardData.setData('text/plain', (license ? license : ''));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.openSnackBar(license ? this.licenseCopied : this.licenseNotEnable, '');
  }

  setIntegrationType(integrations) {
    this.licenseServer = null;
    this.licenseWizard = null;
    this.licenseJS = null;
    this.licenseAndroid = null;
    this.licenseIos = null;

    integrations.forEach((element: any) => {
      switch (element.type) {
        case 'SERVER':
          this.licenseServer = element.license_key;
          break;
        case 'WIZARD':
          this.licenseWizard = element.license_key;
          break;
        case 'JS':
          this.licenseJS = element.license_key;
          break;
        case 'ANDROID':
          this.licenseAndroid = element.license_key;
          break;
        case 'IOS':
          this.licenseIos = element.license_key;
          break;
      }
    });
  }

  setActiveKey(key: string) {
    this.activeKey = key;
  }
}
