
  <!-- Sidebar Left -->
  <app-loader></app-loader>
  <mat-drawer-container class="containedrawer">
    <!-- Left Drawer -->
    <mat-drawer mode="side" opened class="left-drawer">
      <div style="display: flex; width: 20%;">
        <button mat-icon-button class="changethememode" (click)='toggleTheme()'>
          <mat-icon *ngIf="theme !== 'dark-theme'" svgIcon='dark'></mat-icon>
          <mat-icon *ngIf="theme === 'dark-theme'" svgIcon='light'></mat-icon>
        </button>
        <a routerLink="/public/home" style="display: flex; align-items: center;">
          <img  class='logo' alt="Worldline Logo" 
          [src]=" 'assets/logo/worldline-mint-horizontal_6_3.png' " />
          <div class='logoDivider'></div>
          
          <img class='areaDev' alt="AreaDev Logo" [src]=" 'assets/logo/AreaDev.png' " />
        </a>
        
      </div>
      <div style="width: 80%; margin: auto;">
        <!-- Left Menu -->
        <app-left-drawer-menu></app-left-drawer-menu>
      </div>
    </mat-drawer>

    <!-- Right Drawer -->
    <mat-drawer #rightDrawer opened mode="side" position="end" class="right-drawer">
      <div style="display: flex; flex-direction: row; height: 100%;">
        <!-- Arrow for opentoggle drawer -->
        <div *ngIf="myRoute[1] !== 'public'" style="display: flex; align-items: center;">
          <button mat-icon-button [disableRipple]="true" (click)="drawerToggle()" style="width: 21px;">
            <mat-icon class="material-icons">keyboard_arrow_right</mat-icon>
          </button>
          
        </div>
        <div [ngStyle]="{'padding' : myRoute[1] !== 'public' ? '19px 10px 0 0' : '19px 10px 0 21px'}">
          <app-actions></app-actions>
        </div>
      </div>
    </mat-drawer>

    <!-- Central container -->
    <mat-drawer-content class="drawer-content" [ngClass]="{'noBorder': !drawerService?.isOpen}"
      id="wrapper-scroll">
      <div style="display: flex; flex-direction: row;">
        <!-- Main Container (wraps everything except closed sidebar on the right to toggle drawer) -->
        <div style="width: 100%; margin-top: 100px;">
          <!-- Search Bar and actions -->
          <div class="toolbarContainer"
            [ngStyle]="{ 'width': !drawerService?.isOpen ? '77.9%' : '59.8%' }">
            <div class="search-container">
              <app-search-bar></app-search-bar>
            </div>
            <app-actions *ngIf="!drawerService?.isOpen"></app-actions>
          </div>
          <!-- BreadCrumbs -->
          <div class="breadcrumb-container" style="height: 24px;">
            <a routerLink="/public/home">
              <mat-icon svgIcon='home' *ngIf="myRoute[2] !=='home'" class="dark-icon"></mat-icon>
            </a>
            
            <ng-container *ngIf="myRoute[2] !== 'home'">&nbsp;-&nbsp;</ng-container>
            <app-ng-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="16" [symbol]="'&nbsp; - '">
            </app-ng-dynamic-breadcrumb>
          </div>
          <!-- Content -->
          <div style="width: 93%; margin: 15px auto; padding-bottom: 50px;">
            <router-outlet>
            </router-outlet>
          </div>
        </div>
        <!-- Closed sidebar on the right, click to open-->
        <div *ngIf="showOpener" class="rigth-drawer" id="opener">
          <button mat-icon-button *ngIf="myRoute[1] !== 'public'" (click)="drawerToggle()" [disableRipple]="true" style="width: 21px;">
            <mat-icon class="material-icons">keyboard_arrow_left</mat-icon>
          </button>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>

  <!-- Message small screen -->
  <div class="smallScreenMessage smallScreenContent">
    <div>
      <img alt="Worldline Logo" [src]=" 'assets/logo/worldline-mint-horizontal_6_3.png' " width="250px" />  
      <h3 i18n='@@message-smallScreen'>Il contenuto non è disponibile da sito mobile, accedi da desktop per visualizzarlo</h3>  
    </div>
  </div>

