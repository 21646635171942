import { MerchantService } from 'src/app/_services/merchant.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configurations-payment-methods',
  templateUrl: './configurations-payment-methods.component.html',
  styleUrls: ['./configurations-payment-methods.component.scss']
})
export class ConfigurationsPaymentMethodsComponent implements OnInit {

  isPayPalActive: boolean = false;
  isIGFSActive: boolean = false;

  constructor(
    private merchantService: MerchantService
  ) {}

  ngOnInit(): void {
    this.isPayPalActive = this.merchantService.currentMerchant.settings.isPAYPALActive;
    this.isIGFSActive = this.merchantService.currentMerchant.settings.isIGFSActive;
  }
}
