<!-- Init Form payment -->
<div class="line-button">
  <button i18n='@@fill-in-fields' style="margin-right: 20px;" mat-flat-button [disabled]='form.valid'
    class="save-line-button btn-active-green" (click)='accordion.openAll(); demoPayment()'>Compila campi</button>
  <button mat-flat-button [disabled]='!form.valid' class="save-line-button btn-active-green"
    (click)='accordion.closeAll(); creaPagamento()'>
    <ng-container i18n='@@button-Create'>Crea</ng-container></button>
</div>

<form [formGroup]="form" style='width: 100%;'>
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title><span i18n='@@title-InitializePayment'>Inizializza pagamento </span><mat-icon
            matTooltip="Info">help</mat-icon></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex-row">
        <span class="flex-col">
          <mat-form-field appearance="outline" class="leftInputField">
            <input matInput currencyMask type='tel' placeholder="Importo" i18n-placeholder='@@placeholder-Amount'
              formControlName="amount">
          </mat-form-field>
        </span>
        <span class="flex-col">
          <mat-form-field floatLabel="never" appearance="outline" class="rightInputField">
            <mat-select placeholder="Currency" i18n-placeholder='@@placeholder-Currency' formControlName="currency"
              appearance="outline">
              <mat-option *ngFor='let currency of currencies' [value]='currency'>{{ currency }}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="flex-row">
        <span class="flex-col">
          <mat-form-field appearance="outline" class="leftInputField">
            <mat-select placeholder="Lingua" i18n-placeholder='@@placeholder-Language' formControlName="language"
              appearance="outline">
              <mat-option *ngFor='let language of languages' [value]='language'>{{ language }}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span class="flex-col">
          <mat-form-field appearance="outline" class="rightInputField">
            <mat-select placeholder="Tipo Transazione" i18n-placeholder='@@placeholder-TypeTransaction'
              formControlName="transaction_type" appearance="outline">
              <mat-option *ngFor='let trType of transactTypeArr' [value]='trType'>{{trType}}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <div class="flex-row">
        <span class="flex-col">
          <mat-form-field floatLabel="never" appearance="outline" class="leftInputField">
            <input matInput placeholder="Success Redirect Url" i18n-placeholder='@@placeholder-SuccessRedirectUrl'
              formControlName="redirect_successUrl">
          </mat-form-field>
        </span>
        <span class="flex-col">
          <mat-form-field floatLabel="never" appearance="outline" class="rightInputField">
            <input matInput placeholder="Failure Redirect Url" i18n-placeholder='@@placeholder-FailureRedirectUrl'
              formControlName="redirect_failureUrl">
          </mat-form-field>
        </span>
      </div>

      <div class='flex-row'>
        <span class="flex-col">
          <mat-form-field appearance="outline" class="leftInputField">
            <input matInput placeholder="Callback Url" formControlName="callback_url">
          </mat-form-field>
        </span>
        <span class="flex-col">
          <mat-form-field appearance="outline" class="rightInputField"
            [ngClass]="{'disabled': form.get('txGroup.tokenize').value }">
            <mat-select id="brandSelect" placeholder="Brand" formControlName="brand" appearance="outline"
              floatlLabel="never">
              <mat-option *ngFor='let brand of brands' [value]='brand'>{{ getBrand(brand) }}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <div formGroupName="txGroup">
        <div class="flex-row" *ngIf="merchantSettings['isTokenizationActive']">
          <span class="flex-col">
            <mat-form-field floatLabel="never" appearance="outline" class="leftInputField">
              <input matInput placeholder="PayInstrToken" formControlName="payInstrToken"
                [errorStateMatcher]="instrTokenErrorMatcher">
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field floatLabel="never" appearance="outline" class="rightInputField">
              <input matInput placeholder="PayCardToken" formControlName="payCardToken"
                [errorStateMatcher]="cardTokenErrorMatcher">
            </mat-form-field>
          </span>
        </div>

        <div class="flex-row" *ngIf="merchantSettings['isTokenizationActive']">
          <span class="flex-col">
            <mat-form-field appearance="outline" class="leftInputField">
              <mat-select placeholder="Indicatore Tipologia di Transazione"
                i18n-placeholder='@@placeholder-txIndicatorType' formControlName="txIndicatorType" appearance="outline"
                [errorStateMatcher]="txTypeErrorMatcher">
                <mat-option *ngFor='let type of transactionIndicatorType' [value]='type'>{{ type }}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <span class="flex-col tokenize tokenize-radio" *ngIf="merchantSettings['isTokenizationImplicitActive']">
            <mat-checkbox class="checkbox-tokenize" color="primary" aria-label="Tokenizza" formControlName="tokenize"
              (change)="setTokenizeMode($event)"></mat-checkbox> <span class='tokenizespan' style="margin-left: 10px;"
              i18n='@@label-tokenize'>Tokenizza carte (Modalità implicita)</span>
          </span>
        </div>
      </div>

      <div class="flex-row">
        <span class="flex-col">
          <mat-form-field floatLabel="never" appearance="outline" class="leftInputField">
            <input matInput placeholder="Shop ID" formControlName="shopID">
          </mat-form-field>
        </span>
        <span class="flex-col"> </span>
      </div>
    </mat-expansion-panel>

    <!--************NOTIFICHE***************-->
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title><span i18n='@@infoNotifications'>Info notifiche</span><mat-icon
            matTooltip="Info">help</mat-icon></mat-panel-title>
      </mat-expansion-panel-header>
      <div formGroupName="notifications">
        <div class="flex-row">
          <span class="flex-col">
            <mat-form-field floatLabel="never" appearance="outline" class="leftInputField">
              <input matInput placeholder="Nome" formControlName="name" i18n-placeholder='@@placeholder-Name'>
            </mat-form-field>
          </span>
          <span class="flex-col">
            <mat-form-field appearance="outline" class="rightInputField">
              <input matInput placeholder="Email" formControlName="email">
            </mat-form-field>
          </span>
        </div>
        <!--  <div class="flex-row">
            <span class="flex-col">
              <mat-form-field floatLabel="never" appearance="outline" class="leftInputField">
                  <app-intl-phone placeholder="Smartphone"  formControlName="smartphone"></app-intl-phone>
              </mat-form-field>
            </span>
          </div> -->
      </div>
    </mat-expansion-panel>

    <!--****************INDIRIZZI******************************-->
    <mat-expansion-panel class="mat-elevation-z0" *ngIf="this.merchantSettings['isAddressesActive']">
      <mat-expansion-panel-header>
        <mat-panel-title><span i18n='@@label-Addresses'>Indirizzi</span><mat-icon
            matTooltip="Info">help</mat-icon></mat-panel-title>
      </mat-expansion-panel-header>
      <div style='margin-bottom: 20px; text-align: end;'>
        <button *ngIf='addressCtrls && addressCtrls.length > 0' mat-raised-button class="btn-add-remove"
          (click)='removeAddresses()'>-</button>
        <button mat-raised-button class="btn-add-remove" (click)='addAddresses()'>+</button>
      </div>
      <div formArrayName="addresses">
        <div style='margin-right: 0;' *ngFor='let adr of addressCtrls; let i=index'>
          <div [formGroupName]="i">
            <div *ngIf='i != 0'> <span style='font-size: 11px; font-weight: normal; margin-right: 25px;'>{{i+1}}°
                Indirizzo</span></div>
            <div class="flex-row">
              <span class="flex-col">
                <mat-form-field floatLabel="never" appearance="outline" class="leftInputField">
                  <mat-select placeholder="Tipo Indirizzo" i18n-placeholder='@@placeholder-TypeAddress'
                    appearance="outline" formControlName="type">
                    <mat-option *ngFor='let adType of addressesTypeArr'
                      [value]='adType.key'>{{adType.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="rightInputField" style='margin-right: 35px'>
                  <input matInput placeholder="Nome del destinatario" i18n-placeholder='@@placeholder-AddresseeName'
                    formControlName="addresseeName">
                </mat-form-field>
              </span>
            </div>
            <div class='flex-row'>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="leftInputField">
                  <input matInput placeholder="Indirizzo 1" formControlName="streetAddress_1"
                    i18n-placeholder='@@palceholder-Address_1'>
                </mat-form-field>
              </span>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="rightInputField">
                  <input matInput placeholder="Indirizzo 2" formControlName="streetAddress_2"
                    i18n-placeholder='@@palceholder-Address_2'>
                </mat-form-field>
              </span>
            </div>
            <div class='flex-row'>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="leftInputField" style='margin-right: 35px'>
                  <input matInput type='tel' placeholder="CAP" formControlName="zip"
                    i18n-placeholder='@@placeholder-ZipCode'>
                </mat-form-field>
              </span>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="rightInputField">
                  <input matInput placeholder="Città" formControlName="city" i18n-placeholder='@@placeholder-City'>
                </mat-form-field>
              </span>
            </div>
            <div class='flex-row'>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="leftInputField">
                  <input matInput placeholder="Provincia" formControlName="provinceState"
                    i18n-placeholder='@@placeholder-Province'>
                </mat-form-field>
              </span>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="rightInputField" style='margin-right: 35px'>
                  <input matInput placeholder="Stato" formControlName="country"
                    i18n-placeholder='@@placeholder-Country'>
                </mat-form-field>
              </span>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <!--****************CARRELLO******************************-->
    <mat-expansion-panel class="mat-elevation-z0" *ngIf="merchantSettings['isShoppingCartActive']">
      <mat-expansion-panel-header>
        <mat-panel-title><span i18n='@@label-Products'>Prodotti</span><mat-icon
            matTooltip="Info">help</mat-icon></mat-panel-title>
      </mat-expansion-panel-header>
      <div style='margin-bottom: 20px; text-align: end;'>
        <button *ngIf='productCtrls && productCtrls.length > 0' mat-raised-button class="btn-add-remove"
          (click)='removeProducts()'>-</button>
        <button mat-raised-button class="btn-add-remove" (click)='addProducts()'>+</button>
      </div>
      <div formArrayName="products">
        <div style='margin-right: 0;' *ngFor='let c of productCtrls; let x=index'>
          <div [formGroupName]="x">
            <div *ngIf='x != 0'> <span style='font-size: 11px; font-weight: normal; margin-right: 25px;'>{{x+1}}°
                Prodotto</span></div>
            <div class="flex-row">
              <span class="flex-col">
                <mat-form-field appearance="outline" class="leftInputField">
                  <input matInput placeholder="URL Immagine" formControlName="image"
                    i18n-placeholder='@@placeholder-URLImage'>
                </mat-form-field>
              </span>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="rightInputField">
                  <input matInput type='number' placeholder="Quantità" formControlName="quantity"
                    i18n-placeholder='@@placeholder-Quantity'>
                </mat-form-field>
              </span>
            </div>
            <div class="flex-row">
              <span class="flex-col">
                <mat-form-field appearance="outline" class="leftInputField">
                  <input matInput placeholder="Descrizione" formControlName="description"
                    i18n-placeholder='@@placeholder-Description'>
                </mat-form-field>
              </span>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="rightInputField">
                  <input matInput currencyMask type='tel' placeholder="Prezzo" formControlName="price"
                    i18n-placeholder='@@placeholder-Price'>
                </mat-form-field>
              </span>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <!--************ADDIZIONALI***************-->
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title><span i18n='@@label-AdditionalInfo'>Info addizionali</span><mat-icon
            matTooltip="Info">help</mat-icon></mat-panel-title>
      </mat-expansion-panel-header>
      <div style='margin-bottom: 20px; text-align: end;'>
        <button *ngIf='additionalCtrls && additionalCtrls.length > 0' mat-raised-button class="btn-add-remove"
          (click)='removeAdditionals()'>-</button>
        <button mat-raised-button class="btn-add-remove" (click)='addAdditionals()'>+</button>
      </div>
      <div formArrayName="additionals">
        <div style='margin-right: 0;' *ngFor='let adr of additionalCtrls; let y=index'>
          <div [formGroupName]="y">
            <div *ngIf='y != 0'> <span style='font-size: 11px; font-weight: normal; margin-right: 25px;'>{{y+1}}°
                Info</span></div>
            <div class="flex-row">
              <span class="flex-col">
                <mat-form-field appearance="outline" class="leftInputField">
                  <input matInput placeholder="Chiave" formControlName="key" i18n-placeholder='@@placeholder-Key'>
                </mat-form-field>
              </span>
              <span class="flex-col">
                <mat-form-field appearance="outline" class="rightInputField">
                  <input matInput placeholder="Valore" formControlName="value" i18n-placeholder='@@placeholder-Value'>
                </mat-form-field>
              </span>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>