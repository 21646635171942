<h1 class="save-line-head">PayPal</h1>

<div *ngIf='!partnerMerchantId'>
  <h3 i18n='@@subtitle-Onboarding'>Per effettuare l'onboarding con PayPal, fare click sul bottone e compilare i campi:</h3>
  <div style="display: flex; justify-content: center; margin-top:20px">
    <div class='paypalSpinner'>
      <div *ngIf="!action_url && !partnerMerchantId" class="general-loader-custom-container">
        <div class="general-loader-pre-container"> 
          <mat-spinner diameter="30"></mat-spinner>
        </div>
      </div>
      <a class="btnPayPal" mat-raised-button data-paypal-button="true" href={{action_url}}>
        <img src="assets/images/paypal-inline.png" width="95px" alt="">
      </a>
    </div>
  </div>
  
  <div style='margin-top: 40px'>
      <h4 i18n='@@text-partnerMerchant'>Nel caso in cui si disponesse già di un account business associato a Worldline, sarà possibile inserire qui il PartnerMerchantID fornito da PayPal:</h4>
  
      <form [formGroup]="form">
          <div formGroupName="definitions">
              <mat-form-field floatLabel="never" appearance="outline" style="width: 100%;">
                  <input matInput placeholder="Client ID" formControlName="client_id">
                  </mat-form-field>
          </div>
        </form>
        <div>
          <button mat-flat-button i18n='@@btn-Save' [disabled]='!form.valid' class="save-line-button btn-active-green" 
          (click)='gatewayID ? updateGateway() : createGateway()'>Salva</button>  
        </div>
</div>
<div *ngIf='partnerMerchantId' >
  <h3 i18n='@@subtitle-OnboardingComplete' style="display: flex; justify-content: center; ">Onboarding completato!</h3>
  <h4 i18n='@@text-verifyEmail' style="display: flex; justify-content: center;">Per abilitare le transazioni sarà necessario verificare l'indirizzo email inserito in fase di registrazione.</h4>
</div>



