import { LocationService } from './../../_services/location.service';
import { StrapiService } from './../../_services/strapi.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { File, Pdf } from 'src/app/_models/strapi-file';
import { MerchantService } from '../../_services/merchant.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  options: Array<Pdf>;
  currentLanguage: string;

  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;

  constructor(
    private strapiService: StrapiService,
    public merchantService: MerchantService,
    private locationService: LocationService
  ) { }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();
    if (this.currentLanguage === 'it') {
      return this.options.filter(option => option.title_version_IT.toLowerCase().includes(filterValue));
    } else {
      return this.options.filter(option => option.title_version_EN.toLowerCase().includes(filterValue));
    }
  }

  ngOnInit(): void {
    this.strapiService.urlStrapi$.subscribe(endpoint => {
      if(endpoint) {
        this.strapiService.getDocuments().subscribe(res => {
          this.options = res.filter(pdf => pdf.visibility !== 'privacy_policy');
          this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        }
        );
      }
    });

    this.locationService.currentLanguage$.subscribe(resp => this.currentLanguage = resp);

  }

  openPdf(file: File) {
    if(!file) {
      return;
    } else {
      this.strapiService.downloadFile(file.url).subscribe(blob => {
        saveAs(blob, `${file.name}`);
      }
    );
    }
  }

}
