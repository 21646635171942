import { LoaderService } from './../../../../../_services/loader.service';
import { saveAs } from 'file-saver';
import { StrapiService } from './../../../../../_services/strapi.service';
import { Component, OnInit } from '@angular/core';
import { File, Plugin } from 'src/app/_models/strapi-file';

@Component({
  selector: 'app-plugin-download',
  templateUrl: './plugin-download.component.html',
  styleUrls: ['./plugin-download.component.scss']
})

export class PluginDownloadComponent implements OnInit {

  pluginsVersion: any;
  plugins: Array<Plugin>;
  urlStrapi: string;

  constructor(
    private strapiService: StrapiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.strapiService.urlStrapi$.subscribe(endpoint => {
      if(endpoint) {
        this.loaderService.startLoader();
        this.strapiService.getPlugins().subscribe(
          resp => {
          this.plugins = resp;
          this.loaderService.stopLoader();
        },
        error => this.loaderService.stopLoader());
        this.urlStrapi = endpoint;
      }
    });
  }

  downloadPlugin(file: File) {
    if (!file) {
      return;
    } else {
      this.strapiService.downloadFile(file.url).subscribe(blob => saveAs(blob, `${file.name}`));
    }
  }

}
