<h1 class="save-line-head" i18n='@@itemMenu-Solution'>Soluzioni</h1>

<h3 i18n='@@subTitle-EnvironmentProduction' style="margin-top: 20px;">Ambiente PRODUZIONE</h3>
<!-- Select merchants  -->
<app-select-multimerchant [licenseArray]='productionMerchantsLicense' (slectMerchant)='setCurrentMerchant($event)'></app-select-multimerchant>

<!-- License subTitle-EnvironmentProduction -->
<h4 *ngIf="currentMerchant">License Key: {{currentMerchant?.name}} - {{currentMerchant?.signboard}} - MID {{currentMerchant?.merid}}</h4>
<app-license-container-prod [licenseArray]='productionLicense'></app-license-container-prod>

<button mat-button class="btn-active-green" style="margin: 20px 0;" i18n='@@btn-GeneratesAccessToken'
    (click)="redirect()">Genera Access Token</button>
<p>Endpoint di produzione:</p>

<!-- Table Shared -->
<app-custom-data-table id="twoColumnTable" [tableData]='endpointsProduction' [columnHeader]='displayedColumnsProduction'
    [ariaLabel]="'Endpoint di produzione'"></app-custom-data-table>
