import {Component, OnInit, Input, IterableDiffers, DoCheck} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-custom-data-table',
  templateUrl: './custom-data-table.component.html',
  styleUrls: ['./custom-data-table.component.scss']
})
export class CustomDataTableComponent implements OnInit, DoCheck {

  @Input() tableData;
  @Input() columnHeader;
  @Input() ariaLabel;
  objectKeys = Object.keys;
  dataSource;
  iterableDiffer: any;

  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource( this.tableData );
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.tableData);
    if (changes) {
      this.dataSource = new MatTableDataSource( this.tableData );
    }
  }
}
