import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  type: string = '';
  title: string = '';
  message: string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data ) { }

  ngOnInit() {
    if (this.data){
      this.type = this.data['type'];
      this.title = this.data['title'];
      this.message = this.data['message'];
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
