<button mat-button class='btn-configurations-test' (click)="btnActive='Easy'" [ngClass]="{'btn-active-green': btnActive==='Easy'}">Easy</button>
<button *ngIf="merchantService.currentMerchant?.settings.isSDKJSActive" mat-button class='btn-configurations-test' (click)="btnActive='Smart'" [ngClass]="{'btn-active-green': btnActive==='Smart'}">Smart</button>
<button *ngIf="merchantService.currentMerchant?.settings.isSDKANDROIDActive || merchantService.currentMerchant.settings.isSDKIOSActive" mat-button class='btn-configurations-test' (click)="btnActive='In-App'" [ngClass]="{'btn-active-green': btnActive==='In-App'}">In-App</button>
<div class="content-test">
    <div class="row-content-test">
        <h4>License Key API:
            <ng-container *ngIf="activeKey"> {{licenseServer ? licenseServer : ' - '}}</ng-container>
            <ng-container *ngIf="!activeKey"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon i18n-matTooltip='@@matTooltip-ShowApi-Key' matTooltip="Visualizza API-KEY " matTooltipPosition="above" (click)='activeKey =!activeKey' *ngIf='!activeKey' svgIcon='show' style="margin-right: 8px;">
            </mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='activeKey =!activeKey' *ngIf='activeKey' svgIcon='hide' style="margin-right: 8px;">
            </mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseServer)'></mat-icon>
        </div>
    </div>
    <!-- licenseWizard -->
    <div *ngIf="btnActive==='Easy'" class="row-content-test">
        <h4>License Key Easy Checkout:
            <ng-container *ngIf="activeKeyEasy">{{licenseWizard ? licenseWizard : ' - '}}</ng-container>
            <ng-container *ngIf="!activeKeyEasy"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon i18n-matTooltip='@@matTooltip-ShowApi-Key' matTooltip="Visualizza API-KEY " matTooltipPosition="above" (click)='activeKeyEasy =!activeKeyEasy' *ngIf='!activeKeyEasy' svgIcon='show'
                style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='activeKeyEasy =!activeKeyEasy' *ngIf='activeKeyEasy' svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseWizard)'></mat-icon>
        </div>
    </div>
    <!-- Smart -->
    <div *ngIf="btnActive==='Smart'" class="row-content-test">
        <h4>License Key Smart Checkout:
            <ng-container *ngIf="activeKeyEasy">{{licenseJS ? licenseJS : ' - '}}</ng-container>
            <ng-container *ngIf="!activeKeyEasy"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon (click)='activeKeyEasy =!activeKeyEasy' *ngIf='!activeKeyEasy' svgIcon='show'
                style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='activeKeyEasy =!activeKeyEasy' *ngIf='activeKeyEasy' svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseJS)'></mat-icon>
        </div>
    </div>
    <!-- In-App -->
    <div *ngIf="btnActive==='In-App' && merchantService.currentMerchant?.settings.isSDKIOSActive" class="row-content-test">
        <h4>License Key In-App Checkout(SDK iOS):
            <ng-container *ngIf="activeKeyEasy">{{licenseIos ? licenseIos : ' - '}}</ng-container>
            <ng-container *ngIf="!activeKeyEasy"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon i18n-matTooltip='@@matTooltip-ShowApi-Key' matTooltip="Visualizza API-KEY " matTooltipPosition="above" (click)='activeKeyEasy =!activeKeyEasy' *ngIf='!activeKeyEasy' svgIcon='show'
                style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='activeKeyEasy =!activeKeyEasy' *ngIf='activeKeyEasy' svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseIos)'></mat-icon>
        </div>
    </div>
    <div *ngIf="btnActive==='In-App' && merchantService.currentMerchant?.settings.isSDKANDROIDActive" class="row-content-test">
        <h4>License Key In-App Checkout(SDK Android):
            <ng-container *ngIf="activeKeyApp">{{licenseAndroid ? licenseAndroid : ' - '}}</ng-container>
            <ng-container *ngIf="!activeKeyApp"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon i18n-matTooltip='@@matTooltip-ShowApi-Key' matTooltip="Visualizza API-KEY " matTooltipPosition="above" (click)='activeKeyApp =!activeKeyApp' *ngIf='!activeKeyApp' svgIcon='show'
                style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='activeKeyApp =!activeKeyApp' *ngIf='activeKeyApp' svgIcon='hide'style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseAndroid)'></mat-icon>
        </div>
    </div>
</div>
