import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MerchantService } from './merchant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IQParameter } from '../_models/iqparameter';
import { PaymentDTO } from '../_models/payment-dto';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {



  constructor(
    private http: HttpClient,
    private merchantService: MerchantService,
    ) {}

  getLicenseHeader(license: string) {
    return { 'x-license-key': license };
  }

  getBody(id: string, transactionID: string, amount: string) {
    return {
      'paymentId' : id,
      'transactionID': transactionID,
      'amount': amount
    };
  }

  /* ====== SERVICES API ====== */

  filterPaymentsByQuery(filters: IQParameter[], populate: boolean = false, sort?: string, skip?: number, limit?: number) {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'sort': sort,
      'limit': limit.toString(),
      'skip': (skip * limit).toString(),
      'populate': 'true'
    });

    let queryParams = filters.map(el => el);
    queryParams.unshift({key: 'merchant', value: this.merchantService.currentMerchant._id})

    let queryString: string = queryParams.length > 0 ? '?' : '';
    queryParams.forEach((query) => queryString += query['key'] + '=' + query['value'] + '&');
    return this.http.get<any>('/develop/v1/payments' + queryString, { headers });
  }

  getPaymentById(id: string){
    return this.http.get('/develop/v1/payments/' + id, {});
  }

  // Inizializzazione pagamento
  initPayment(license: string, data: PaymentDTO){
    const headers = this.getLicenseHeader(license);
    return this.http.post('/develop/v1/payments/initPayment', data, { headers });
  }

  getPaymentID(license: string, id: string): Observable<any> {
    const headers = this.getLicenseHeader(license);
    return this.http.get<any>('/develop/v1/payments/initPayment/' + id, { headers }).pipe(
      map(res => res.paymentID)
    )
  }

  credit(license: string, id: string, transactionID: string, amount: string): Observable<any> {
    const headers = this.getLicenseHeader(license);
    const body = this.getBody(id, transactionID, amount);
    return this.http.post('/develop/v1/payments/credit', body, { headers });
  }

  void(license: string, id: string, transactionID: string, amount: string): Observable<any> {
    const headers = this.getLicenseHeader(license);
    const body = this.getBody(id, transactionID, amount);
    return this.http.post('/develop/v1/payments/void', body, { headers });
  }

  confirm(license: string, id: string, transactionID: string, amount: string): Observable<any> {
    const headers = this.getLicenseHeader(license);
    const body = this.getBody(id, transactionID, amount);
    return this.http.post('/develop/v1/payments/confirm', body, { headers });
  }

}


