<h1 class="save-line-head" i18n='@@title-PaymentMethods'>Metodi di pagamento</h1>
<h4 i18n='@@textint-IntroPaymentMethods'>Worldline dispone di diversi metodi di pagamento che possono essere accettati con una sola integrazione, tra cui carte di credito come VISA, VISA Electron, Vpay, Mastercard, Maestro, American Express e Diners e metodi di pagamento aggiuntivi non basati su carta</h4>

<div class="row">
    <ng-container *ngFor="let pdf of listPdf">
        <div class="col-6" *ngIf="showPdf(pdf)">
            <mat-card class="card" (click)="openPdf(pdf.file)">
                <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
                <ng-container *ngIf="currentLanguage === 'it'">{{pdf.title_version_IT}}</ng-container>
                <ng-container *ngIf="currentLanguage === 'en'">{{pdf.title_version_EN}}</ng-container>
                <ng-container *ngIf="!currentLanguage">{{pdf.title_version_EN}}</ng-container>
                <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
            </mat-card>
        </div>
    </ng-container>
</div>
