import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GatewayService } from 'src/app/_services/gateway.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Merchant} from '../../../../../../_models/merchant';
import {MerchantService} from '../../../../../../_services/merchant.service';

@Component({
  selector: 'app-satispay',
  templateUrl: './satispay.component.html',
  styleUrls: ['./satispay.component.scss']
})
export class SatispayComponent implements OnInit {

  gateway: any[] = [];
  form: UntypedFormGroup;
  merchantID: string;
  productionMerchantID: string;

  constructor(
    private fb: UntypedFormBuilder,
    private gatewayService: GatewayService,
    private snackBar: MatSnackBar,
    private merchantService: MerchantService
  ) {
    this.form = this.buildForm(null);
  }

  ngOnInit(): void {
    this.productionMerchantID = this.merchantService.currentProductionMerchant ? this.merchantService.currentProductionMerchant._id : null;
    this.merchantID = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    this.gatewayService.getSyncGateways(this.productionMerchantID).subscribe((gateways: any) => {
      this.gateway = gateways.items.find(el => el.definitions.type === 'SPAY');
      this.form = this.buildForm(this.gateway);
    });
  }

  buildForm(formValues): UntypedFormGroup {
    return this.fb.group({
      merchant: [(formValues ? formValues.merchant : '')],
      type : [(formValues ? formValues.type : '')],
      definitions: this.fb.group({
        mid: [(formValues && formValues.definitions ? formValues.definitions.mid : '')],
        type: [(formValues && formValues.definitions ? formValues.definitions.type : ''), ],
        tid: [(formValues && formValues.definitions ? formValues.definitions.tid : ''), ],
        ksig: [(formValues && formValues.definitions ? formValues.definitions.ksig : ''), ],
        simulator: [(formValues && formValues.definitions ? formValues.definitions.simulator : false)],
        parameters: [this.getParametersValue(formValues)]
      })
    });
  }

  getParametersValue(formValues): string | null {
    if (formValues) {
      return (formValues.definitions && formValues.definitions.parameters ?
               formValues.definitions.parameters[0] && formValues.definitions.parameters[0].value ?
                 formValues.definitions.parameters[0].value : '' : '');
    } else {
      return '';
    }
  }

  save() {
    if (this.form.valid) {
      let tmpForm: any = {...this.form.value};
      if (tmpForm.definitions.parameters) {
        tmpForm = { ...tmpForm,
          definitions: { ...tmpForm.definitions,
            parameters: { key: 'SECURITY_TOKEN', value: tmpForm.definitions.parameters } } };
      }
      this.gatewayService.updateSyncGateways(tmpForm, this.productionMerchantID).subscribe(
        (res: any) => this.snackBar.open(res.message, null, { duration: 2000 }));
    }
  }

}
