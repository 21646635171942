import { MerchantService } from './../../_services/merchant.service';
import { ConfigurationService } from './../../_services/configuration.service';
import { MatDialogRef, MatDialogClose, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import 'codemirror/addon/scroll/simplescrollbars';
import { License } from '../transactions-list/transactions-list.component';

@Component({
  selector: 'app-integration-dialog',
  templateUrl: './integration-dialog.component.html',
  styleUrls: ['./integration-dialog.component.scss']
})
export class IntegrationDialogComponent implements OnInit {

  data: {licenses: License, paymentID: string};
  btnActive: string = 'Wizard';
  btnJsActive: string;
  codeMirrorTheme: string;

  htmlWizard: string;
  htmlJS: string;
  htmlJsButtonless: string;
  htmlJSinline: string;
  htmlJSCompact: string;
  htmlAndroid: string;
  htmlIos: string;
  host: string;
  commentExemple: string;
  commentScript: string;
  commentInitialization: string;
  commentTag: string;
  commentBtnSubmit: string;

  constructor(
    public dialogRef: MatDialogRef<MatDialogClose>,
    @Inject(MAT_DIALOG_DATA) public tabsData,
    private configurationService: ConfigurationService,
    public merchantService: MerchantService
    ) {
      this.data = tabsData;
      this.setHostAndTemplate();
      this.commentExemple = $localize `:@@commentCode-ExempleBtnCheckout: ESEMPIO DI PULSANTE CHECKOUT `;
      this.commentScript = $localize `:@@commentCode-ScriptCdn: LO SCRIPT DA RECUPERARE TRAMITE CDN `;
      this.commentInitialization = $localize `:@@commentCode-Initialization: INIZIALIZZAZIONE CLIENT E UTILIZZO `;
      this.commentTag = $localize `:@@commentCode-Tag: IL TAG DOVE VERRA' VISUALIZZATO L'HOSTED FORM `;
      this.commentBtnSubmit = $localize `:@@commentCode-ExempleBtnSubmit: ESEMPIO DI PULSANTE SUBMIT `;
    }

    ngOnInit() {
      if (localStorage.getItem('theme') === 'light-theme') {
        this.codeMirrorTheme = 'default';
      } else {this.codeMirrorTheme = 'material'; }
    }

    close() {
      this.dialogRef.close();
    }

    htmlScripts(integrazione: string){
    const htmlWizard = `<!DOCTYPE html>
    <html>
      <head>
        <title>SDK Redirect Integration Example</title>
        <meta name="viewport" content="initial-scale=1.0">
        <meta charset="utf-8">
      </head>
      <body>
        <!-- ${this.commentExemple} -->
        <button type="button" onClick="axeptaClient.proceedToPayment('${this.data.paymentID}')">
          Checkout
        </button>

        <!-- ${this.commentScript} -->
        <script src="${this.host}/sdk/axepta-pg-redirect.js"></script>

        <!-- ${this.commentInitialization} -->
        <script type="text/javascript">
          let axeptaClient = new AxeptaSDKClient("${this.host}", "${this.data.licenses.wizard}");
        </script>
      </body>
    </html>`;

    const htmlJS = `<!DOCTYPE html>
    <html>
      <head>
        <title>SDK JS Integration Example</title>
        <meta name="viewport" content="initial-scale=1.0">
        <meta charset="utf-8">
      </head>
      <body>
        <!-- ${this.commentExemple} -->
        <button type="button" onClick="axeptaClient.preparePayment('${this.data.paymentID}')">Checkout</button>

        <!-- ${this.commentTag} -->
        <div id="my-axepta-sdk-pg"></div>

        <!-- ${this.commentScript} -->
        <script src="${this.host}/sdk/axepta-pg-sdk.js"></script>

        <!-- ${this.commentInitialization} -->
          <script type="text/javascript">
            let axeptaClient = new AxeptaSDKClient("${this.host}", "${this.data.licenses.js}");
          </script>
      </body>
    </html>`;

    const htmlJSButtonless = `<!DOCTYPE html>
    <html>
      <head>
        <title>SDK JS Integration Example</title>
        <meta name="viewport" content="initial-scale=1.0">
        <meta charset="utf-8">
      </head>
      <body>
        <!-- ${this.commentExemple} -->
        <button type="button" onClick="axeptaClient.preparePayment('${this.data.paymentID}', 'buttonless');">Checkout</button>
        <!-- ${this.commentTag} -->
        <div id="my-axepta-sdk-pg"></div>

        <!-- ${this.commentBtnSubmit} -->
        <button id="submit" type="button" style='display: none;' onClick="axeptaClient.submit()">Submit</button>

        <!-- ${this.commentScript} -->
        <script src="${this.host}/sdk/axepta-pg-sdk.js"></script>

        <!-- ${this.commentInitialization} -->
        <script type="text/javascript">
          let axeptaClient = new AxeptaSDKClient("${this.host}", "${this.data.licenses.js}");
        </script>
      </body>
    </html>`;

    const htmlJSCompact = `<!DOCTYPE html>
    <html>
      <head>
        <title>SDK JS Integration Example</title>
        <meta name="viewport" content="initial-scale=1.0">
        <meta charset="utf-8">
      </head>
      <body>
        <!-- ESEMPIO DI PULSANTE CHECKOUT -->
        <button type="button" onClick="axeptaClient.preparePayment('${this.data.paymentID}', 'compact')">Checkout</button>

        <!-- IL TAG DOVE VERRA' VISUALIZZATO L'HOSTED FORM -->
        <div id="my-axepta-sdk-pg"></div>

        <!-- LO SCRIPT DA RECUPERARE TRAMITE CDN -->
        <script src="${this.host}/sdk/axepta-pg-sdk.js"></script>

        <!-- INIZIALIZZAZIONE CLIENT E UTILIZZO -->
        <script type="text/javascript">
          let axeptaClient = new AxeptaSDKClient("${this.host}", "${this.data.licenses.js}");
        </script>
      </body>
    </html>`;

    const htmlJSinline = `<!DOCTYPE html>
    <html>
      <head>
        <title>SDK JS Integration Example</title>
        <meta name="viewport" content="initial-scale=1.0">
        <meta charset="utf-8">
      </head>
      <body>
        <!-- ${this.commentExemple} -->
        <button type="button" onClick="axeptaClient.preparePayment('${this.data.paymentID}', 'inline'); document.getElementById('submit').style.display = 'block'">Checkout</button>

        <!-- ${this.commentTag} -->
        <div id="my-axepta-sdk-pg"></div>

        <!-- ${this.commentBtnSubmit} -->
        <button id="submit" type="button" style='display: none;' onClick="axeptaClient.submit()">Submit</button>

        <!-- ${this.commentScript} -->
        <script src="${this.host}/sdk/axepta-pg-sdk.js"></script>

        <!-- ${this.commentInitialization} -->
        <script type="text/javascript">
          let axeptaClient = new AxeptaSDKClient("${this.host}", "${this.data.licenses.js}");
        </script>
      </body>
    </html>`;

    const htmlAndroid = `public class MainActivity extends AppCompatActivity {
      private FragmentPayment.ResultExecutePaymentCallback resultExecutePaymentCallback;
      @Override
      protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);initCallBackSdkPayment();
        final FragmentPayment fragmentPayment = new FragmentPayment(
          "default",
          "#000000",
          "#FF0000",
          getResources().getDimension(R.dimen.radius_button),
          getResources().getDimension(R.dimen.radius_texts),
          resultExecutePaymentCallback);
        FragmentManager fragmentManager = getSupportFragmentManager();
        FragmentTransaction fragmentTransaction = fragmentManager.beginTransaction();
        fragmentTransaction.add(R.id.container,fragmentPayment).addToBackStack(null).commit();

        fragmentPayment.setEndPointAndLicence("${this.host}","${this.data.licenses.android}");
        fragmentPayment.setPaymentID("${this.data.paymentID}");

        Button customPayButton = findViewById(R.id.custom_pay_button);
        customPayButton.setOnClickListener(new View.OnClickListener() {
          @Override
          public void onClick(View v) {
            fragmentPayment.executeCall();
          }
        });
      }

      private void initCallBackSdkPayment() {
        resultExecutePaymentCallback = new FragmentPayment.ResultExecutePaymentCallback() {
          @Override
          public void onExecuteSuccess() {
            Toast.makeText(getApplicationContext(), "Success", Toast.LENGTH_LONG).show();
          }

          @Override
          public void onExecuteFailure() {
            Toast.makeText(getApplicationContext(), "Failure", Toast.LENGTH_LONG).show();
          }
        };
      }
    }`;

    const htmlIos = `
      import UIKit
      import AxeptaSDKClient

      class ViewController: UIViewController, AxeptaSDKClientDelegate
      {
        var axepta:AxeptaSDKClient?

        @IBOutletweakvar widget:UIView!;

        overridefunc viewDidLoad()
        {
          super.viewDidLoad()
          self.axepta?.view = widget
          self.axepta = AxeptaSDKClient(backgroundContainerColor:.white,
                                        viewType:"default",
                                        widgetView:widget,
                                        lang:"IT",
                                        backgroundButtonColor:.red,
                                        borderRadiusButton:2,
                                        borderRadiusTextFields:3,
                                        viewController:self,
                                        delegato:self);
          self.axepta?.setPaymentID(payID: "${this.data.paymentID}");
          self.axepta?.setEndPointAndLicense(endPointUrl: "${this.host}",
                                             licenseKey: "${this.data.licenses.ios}");
        }

        //MARK:Delegates
        func onPaymentError(titolo: String?, messaggio: String?)
        {
          print("+++error pay+++")
        }

        func onPaymentFinished()
        {
          print("+++finish pay+++");
        }
      }`;

    switch (integrazione) {
        case 'wizard':
          this.htmlWizard = htmlWizard;
          break;

        case 'js':
          this.htmlJS = htmlJS;
          this.htmlJSinline = htmlJSinline;
          this.htmlJSCompact = htmlJSCompact;
          this.htmlJsButtonless = htmlJSButtonless;
          break;

        case 'android':
          this.htmlAndroid = htmlAndroid;
          break;

        case 'ios':
          this.htmlIos = htmlIos;
          break;

        default:
          break;
      }
    }

    createTemplate() {
      if (this.data.licenses.wizard) this.htmlScripts('wizard');
      if (this.data.licenses.js) this.htmlScripts('js');
      if (this.data.licenses.android) this.htmlScripts('android');
      if (this.data.licenses.ios) this.htmlScripts('ios');
    }

    setHostAndTemplate() {
      const filters = [{key: 'tenant', value: 'AXEPTA'}];
      this.configurationService.getConfig(filters).subscribe((data: any) => {
        const host = data.items[0] ? data.items[0].settings.find((conf) => conf.key === 'host') : null;
        if (host) {
          this.host = host.value ? host.value : environment.host;
          this.createTemplate();
        } else {
          this.host = environment.host;
          this.createTemplate();
        }
      }, (error) => {
        this.host = environment.host;
        this.createTemplate();
      });
    }

}
