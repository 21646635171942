import { MerchantService } from './../../_services/merchant.service';
import { IntegrationService } from './../../_services/integration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentService } from './../../_services/payment.service';
import { Router } from '@angular/router';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MOCKPAYMENT } from '../../_models/mock-payment';
import { MerchantData } from '../../_models/merchant-data';
import { PaymentMap } from '../../_models/payment-map';
import { Payment } from '../../_models/payment';
import {
  CardTokenErrorMatcher,
  InstrTokenErrorMatcher,
  TxTypeErrorMatcher,
} from '../../_matchers/error-matchers';
import { TxGroupValidator } from '../../_validators/tx-group-validator';

@Component({
  selector: 'app-init-form',
  templateUrl: './init-form.component.html',
  styleUrls: ['./init-form.component.scss'],
})
export class InitFormComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  form: UntypedFormGroup;
  transactTypeArr: Array<string> = [];
  addressesTypeArr: Array<any> = [];
  languages: string[] = [];
  currencies: string[] = [];
  brands: string[] = [];
  merchantID: string;
  transactionIndicatorType: string[] = [];
  instrTokenErrorMatcher = new InstrTokenErrorMatcher();
  cardTokenErrorMatcher = new CardTokenErrorMatcher();
  txTypeErrorMatcher = new TxTypeErrorMatcher();

  merchantSettings: MerchantData[];

  initPaymentSuccess = $localize`:@@snackBarMessage-PaymentCreated:Il pagamento è stato creato con successo`;
  initPaymentUnsuccess = $localize`:@@snackBarMessage-PaymentNotCreated:Il pagamento non è stato creato`;
  licenseServer: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private integrationService: IntegrationService,
    private merchantService: MerchantService,
    public cdr: ChangeDetectorRef
  ) {
    this.getLicenses();
    this.transactTypeArr = ['PURCHASE', 'AUTH', 'VERIFY'];
    this.languages = ['IT', 'EN', 'FR', 'ES', 'NL', 'DE'];
    this.currencies = [
      'EUR',
      'USD',
      'GBP',
      'AED',
      'AUD',
      'BRL',
      'CAD',
      'CHF',
      'DKK',
      'HKD',
      'JPY',
      'KWD',
      'MXN',
      'MYR',
      'NOK',
      'RUB',
      'SAR',
      'SEK',
      'SGD',
      'THB',
      'TWD',
    ];
    this.brands = [
      'NONE',
      'CREDITCARD',
      'WECHAT',
      'SATISPAY',
      'BANCOMATPAY',
      'APPLEPAY',
      'ALIPAY',
      'MYBANK',
      'PAYPAL',
    ];
    this.addressesTypeArr = [
      { key: 'SPEDIZIONE', value: $localize`:@@options-Shipping:SPEDIZIONE` },
      {
        key: 'FATTURAZIONE',
        value: $localize`:@@options-Billing:FATTURAZIONE`,
      },
    ];
    this.transactionIndicatorType = [
      'UNSCHEDULED',
      'RECURRENT',
      'NOSHOW',
      'DELAYCHARGE',
    ];
    this.merchantSettings = this.merchantService.currentMerchant.settings;
    this.form = this.buildForm();
  }

  ngOnInit() {
    this.form = this.buildForm();
  }

  buildForm() {
    return this.fb.group({
      amount: [null, [Validators.required]],
      currency: [
        '',
        [
          Validators.required,
          Validators.maxLength(3),
          Validators.pattern('[A-Za-z]+'),
        ],
      ],
      language: [
        '',
        [
          Validators.required,
          Validators.maxLength(2),
          Validators.pattern('[A-Za-z]+'),
        ],
      ],
      transaction_type: ['', Validators.required],
      notifications: this.fb.group({
        name: [
          '',
          [Validators.pattern("[A-Za-zÀ-ÿ ]+(?:(?:\\. |[' ])[A-Za-zÀ-ÿ]+)*")],
        ],
        email: ['', [Validators.email]],
      }),
      addresses: this.fb.array([]),
      products: this.fb.array([]),
      redirect_successUrl: [''],
      redirect_failureUrl: [''],
      callback_url: [''],
      additionals: this.fb.array([]),
      txGroup: this.fb.group(
        {
          payInstrToken: [
            null,
            [
              Validators.pattern('^[A-Za-z0-9@-_.]+$'),
              Validators.maxLength(32),
              Validators.minLength(1),
            ],
          ],
          payCardToken: [
            null,
            [
              Validators.pattern('^[A-Za-z0-9@-_.]+$'),
              Validators.maxLength(32),
              Validators.minLength(1),
            ],
          ],
          txIndicatorType: [null],
          tokenize: [false],
        },
        { validators: [TxGroupValidator] }
      ),
      brand: [''],
      shopID: [''],
    });
  }

  creaIndirizzo(): UntypedFormGroup {
    return this.fb.group({
      type: ['', Validators.required],
      addresseeName: ['', Validators.required],
      streetAddress_1: ['', Validators.required],
      streetAddress_2: '',
      zip: [''],
      city: [''],
      provinceState: [''],
      country: [''],
    });
  }

  creaProdotto(): UntypedFormGroup {
    return this.fb.group({
      image: [''],
      quantity: ['', Validators.required],
      description: ['', Validators.required],
      price: [null, Validators.required],
    });
  }

  creaAdditional(): UntypedFormGroup {
    return this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
    });
  }

  addAddresses(): void {
    let addresses = this.form.get('addresses') as UntypedFormArray;
    addresses.push(this.creaIndirizzo());
  }

  removeAddresses(): void {
    let addresses = this.form.get('addresses') as UntypedFormArray;
    addresses.removeAt(addresses.length - 1);
  }

  addProducts(): void {
    let products = this.form.get('products') as UntypedFormArray;
    products.push(this.creaProdotto());
  }

  removeProducts(): void {
    let products = this.form.get('products') as UntypedFormArray;
    products.removeAt(products.length - 1);
  }

  addAdditionals(): void {
    let additionals = this.form.get('additionals') as UntypedFormArray;
    additionals.push(this.creaAdditional());
  }

  removeAdditionals(): void {
    let additionals = this.form.get('additionals') as UntypedFormArray;
    additionals.removeAt(additionals.length - 1);
  }

  setTokenizeMode(mode: any) {
    if (mode.checked) {
      this.form.get('brand').setValue(null);
      this.form.get('brand').disable();
    } else {
      this.form.get('brand').enable();
    }
  }

  getBrand(brand: string) {
    return brand === 'NONE' ? $localize`:@@brand-options-None:NESSUNO` : brand;
  }

  creaPagamento() {
    const body = PaymentMap.toDomain(this.form.value as Payment);
    this.paymentService
      .initPayment(this.licenseServer, body)
      .subscribe((init) => {
        if (init) {
          this.openSnackBar(this.initPaymentSuccess, '');
          this.router.navigate(['sandbox/transactions-summary']);
        } else {
          this.openSnackBar(this.initPaymentUnsuccess, '');
        }
      });
  }

  // Message error
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  // Form DemoPayment
  demoPayment() {
    if (this.addressCtrls.length == 0) this.addAddresses();
    if (this.productCtrls.length == 0) this.addProducts();
    if (this.additionalCtrls.length == 0) this.addAdditionals();
    this.form.patchValue(MOCKPAYMENT);
  }

  getLicenses() {
    this.merchantID = this.merchantService.currentMerchant._id;
    this.integrationService
      .getServerLicense(this.merchantID)
      .subscribe((x) => (this.licenseServer = x ? x.license_key : ''));
  }

  get addressCtrls() {
    let addressArray = this.form.get('addresses') as UntypedFormArray;
    return addressArray.controls;
  }

  get additionalCtrls() {
    let additionalArray = this.form.get('additionals') as UntypedFormArray;
    return additionalArray.controls;
  }

  get productCtrls() {
    let productArray = this.form.get('products') as UntypedFormArray;
    return productArray.controls;
  }
}
