import { MerchantService } from './../../../../../_services/merchant.service';
import { IntegrationService } from './../../../../../_services/integration.service';
import { Component, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../../../_services/configuration.service';

export interface EndPoints {
  soluzione: string;
  collaudo: object;
}

@Component({
  selector: 'app-configurations-solutions',
  templateUrl: './configurations-solutions.component.html',
  styleUrls: ['./configurations-solutions.component.scss']
})

export class ConfigurationsSolutionsComponent implements OnInit {

  qry: Array<object>;
  accessEndpoint: string;
  merchantID: string;
  licenseObj: any;
  host: string = '{{host}}';

  displayedColumns = { soluzione: $localize`:@@th-Solution:Soluzioni`, collaudo: $localize`:@@th-Testing:Collaudo`};

  endpointsTest: EndPoints[] = [
    {soluzione: 'API Checkout', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
    {soluzione: 'EASY', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
    {soluzione: 'SMART', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
    {soluzione: 'In-App IOS', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
    {soluzione: 'In-App ANDROID', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}}
  ];


  constructor(
    private integrationService: IntegrationService,
    private merchantService: MerchantService,
    private configurationService: ConfigurationService
  ) {
    this.configurationService.accessEnv$.subscribe((env)=>{
      this.accessEndpoint = env;
    })
    this.merchantID = this.merchantService.currentMerchant._id;
    this.qry = [{ key: 'merchant', value: this.merchantID }];
    this.integrationService.filterIntegrationsByQuery(this.qry).subscribe((data: any) => {
      if (data && data.items) {
        this.licenseObj = data.items;
      }
    });
  }

  ngOnInit(): void {
    this.setHost();
  }

  setHost() {
    const filters = [{key: 'tenant', value: 'AXEPTA'}];

    this.configurationService.getConfig(filters).subscribe((data: any) => {
      if (data.items.length > 0) {
        const host = data.items[0].settings.find((conf: any) => conf.key === 'host');
        this.host = host ? host.value : this.host;

        this.endpointsTest = [
          {soluzione: 'API Checkout', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
          {soluzione: 'EASY', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
          {soluzione: 'SMART', collaudo: {url: `${this.host}/sdk`, icon: 'open-in-new-tab'}},
          {soluzione: 'In-App IOS', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}},
          {soluzione: 'In-App ANDROID', collaudo: {url: `${this.host}`, icon: 'open-in-new-tab'}}
        ];
      }
    });
  }

  redirect() {
    let url = this.accessEndpoint ? this.accessEndpoint : "https://pay.worldlineitalia.it/access"
    if(!this.accessEndpoint) console.log('no access endpoint found');
    window.open(url, '_blank');
  }
}
