import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MerchantService } from '../_services/merchant.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthPrivateGuard implements CanActivate {
  constructor(
    private router: Router,
    private merchantService: MerchantService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.merchantService.checkSession();
    if (this.merchantService.currentMerchant && this.merchantService.accessAllowed) {
      return true;
    } else {
      return this.merchantService.isLoggedIn().pipe(map(
        (isLoggedIn) => {
          if (isLoggedIn) {
            return true;
          } else {
            this.router.navigate(['public/home'], {});
            return false;
          }
        }
      ));
    }
  }

}
