<div style="display: flex;">
    <img *ngIf="currentPlugin?.icon" [src]="urlStrapi + (currentPlugin.icon.url | slice: 1)" width="35px" height="35px" alt="logo-image" style="margin-right: 15px;">
    <h1 class="save-line-head">{{currentPlugin?.title_version}}</h1>
</div>

<table id="plugin-table" mat-table [dataSource]="currentPlugin?.plugin_versions" style="width: 100%;">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element" style="padding-left: 10px!important;">
    <ng-container *ngIf="currentLanguage === 'it'">{{element?.title_version_IT}}</ng-container>
    <ng-container *ngIf="currentLanguage === 'en'">{{element?.title_version_EN}}</ng-container>
    <ng-container *ngIf="!currentLanguage">{{element?.title_version_EN}}</ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element">
      <span>Download </span>
      <button mat-icon-button (click)="downloadPlugin(element?.file)">
        <mat-icon svgIcon='download'></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

