import { PrivacyDialogComponent } from './../../_components/privacy-dialog/privacy-dialog.component';
import { MerchantService } from './../../_services/merchant.service';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../../_components/confirm-dialog/confirm-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderService } from '../../_services/loader.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  merchant: any;
  registrationForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.required]],
    phone: ['+39 ', [Validators.pattern('[+]\\d{1,3}\\s{1}(\\d{7,10}|\\s{0})'), Validators.required, Validators.minLength(8)]]
  });
  genericError = $localize `:@@interceptor-genericError: Errore Generico`;
  attention = $localize `:@@interceptor-attention: Attenzione`;
  successfullyRegistered = $localize `:@@successfully-registered: Registrazione avvenuta con successo, controlla la tua mail per accedere.`;
  title = $localize `:@@registration: Registrazione`;
  error = $localize `:@@error: Errore`;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private merchantService: MerchantService,
    private dialog: MatDialog,
    private router: Router,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
  }

  registration() {
    this.loaderService.startLoader();
    this.merchantService.register(this.registrationForm.value).subscribe((merchant) => {
      this.merchant = merchant;
      if (!this.dialogRef) {
        this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '450px',
          data: { type: 'ALERT', title: this.title, message: this.successfullyRegistered }
        });
        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = null;
          this.merchantService.resetCurrentMerchant();
          
          this.router.navigate(['public/home'], {});
        });
        this.loaderService.stopLoader();
      }
      },
    (err) => {
      this.loaderService.stopLoader();
      this.errorDialog(this.errorHandler(err));
    });
  }

  errorDialog(message: string): void {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: { type: 'ALERT', title: this.error, message }
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  errorHandler(error) {
    if (error.error) {
      if (error.error.message) {
        if (typeof error.error.message === 'string') {
         return error.error.message;
        } else {
          return this.genericError;
        }
      } else {
        return this.genericError;
      }
    } else {
      return error.message;
    }
  }


  openDialogPrivacy() {
    this.dialog.open(PrivacyDialogComponent, {
      panelClass: 'privacy-dialog-container',
      width: '60%'
    });
  }
}
