import { AdditionalInfo } from './additional-info';
import { Address } from './address';
import { Payment } from './payment';
import { ProductDTO } from './product';

export class PaymentDTO {
    transaction_type: string;
    transaction_timeout: string;
    brand: string;
    currency: string;
    language: string;
    amount: string;
    notifications: {
        name: string;
        email: string;
    };
    addresses: Array<Address>;
    products: Array<ProductDTO>;
    redirect_successUrl: string;
    redirect_failureUrl: string;
    callback_url: string;
    additionals: Array<AdditionalInfo>;
    shopID: string;
    addressesURI: string;
    txIndicatorType: string;
    payCardToken: string;
    tokenize: boolean;
    payInstrToken?: string;


    constructor(data: Payment) {
        this.transaction_type = data.transaction_type;
        this.transaction_timeout = data.transaction_timeout;
        this.brand = data.brand === 'NONE' ? '' : data.brand;
        this.currency = data.currency;
        this.language = data.language;
        this.amount = data.amount.toFixed(2);
        this.notifications = data.notifications;
        this.addresses = data.addresses;
        this.products = data.products.map(el => new ProductDTO(el.image, el.quantity, el.description, el.price));
        this.redirect_successUrl = data.redirect_successUrl;
        this.redirect_failureUrl = data.redirect_failureUrl;
        this.callback_url = data.callback_url;
        this.additionals = data.additionals;
        this.shopID = data.shopID;
        this.addressesURI = data.addressesURI;
        if (!data.txGroup) {return; }
        this.txIndicatorType = data.txGroup.txIndicatorType;
        this.payCardToken = data.txGroup.payCardToken;
        this.tokenize = data.txGroup.tokenize;
        if (data.txGroup.payInstrToken && !data.txGroup.payCardToken && !data.txGroup.txIndicatorType) {
            this.txIndicatorType = 'UNSCHEDULED';
        }
        if (data.txGroup.payInstrToken) {
            this.payInstrToken = data.txGroup.payInstrToken;
        }
    }
}
