import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  generalLoaderState = new Subject<boolean>();
  started = new BehaviorSubject<boolean>(false);
  loaderStatus$ = this.generalLoaderState.asObservable();

  constructor() { }

  startLoader(){
    this.started.next(true);
    this.generalLoaderState.next(this.started.value);
  }

  stopLoader(){
    this.started.next(false);
    this.generalLoaderState.next(this.started.value);
  }

}
