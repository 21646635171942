import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  private isOpenSbj: BehaviorSubject<boolean>;
  isOpen$: Observable<boolean>;
  get isOpen() {
    return this.isOpenSbj.value;
  }

  constructor() {
    this.isOpenSbj = new BehaviorSubject(true);
    this.isOpen$ = this.isOpenSbj.asObservable();
  }

  open() {
    this.isOpenSbj.next(true);
  }

  close() {
    this.isOpenSbj.next(false);
  }
}
