import { LoaderService } from './../../../../../../_services/loader.service';
import { MerchantService } from './../../../../../../_services/merchant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GatewayService } from 'src/app/_services/gateway.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-satispay-test-area',
  templateUrl: './satispay-test-area.component.html',
  styleUrls: ['./satispay-test-area.component.scss']
})
export class SatispayTestAreaComponent implements OnInit {

  gateway: any;
  form: UntypedFormGroup;
  merchantId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private gatewayService: GatewayService,
    private snackBar: MatSnackBar,
    private merchantService: MerchantService,
    private loaderService: LoaderService
  ) {
    this.form = this.buildForm(null);
  }

  ngOnInit(): void {
    this.loaderService.startLoader();
    this.merchantId = this.merchantService.currentMerchant._id;
    this.gatewayService.getGateways(this.merchantId).subscribe(resp => {
      this.gateway = resp.items.find(el => el.definitions.type === 'SPAY');
      console.log('gateway', this.gateway);
      this.form = this.buildForm(this.gateway);
      this.loaderService.stopLoader();
    }, err =>
      this.loaderService.stopLoader()
    );
  }

  buildForm(formValues): UntypedFormGroup {
    return this.fb.group({
      merchant: [(formValues ? formValues.merchant : '')],
      type : [(formValues ? formValues.type : '')],
      definitions: this.fb.group({
        mid: [(formValues && formValues.definitions ? formValues.definitions.mid : '')],
        type: [(formValues && formValues.definitions ? formValues.definitions.type : ''), ],
        tid: [(formValues && formValues.definitions ? formValues.definitions.tid : ''), ],
        ksig: [(formValues && formValues.definitions ? formValues.definitions.ksig : ''), ],
        simulator: [(formValues && formValues.definitions ? formValues.definitions.simulator : false)],
        parameters: [this.getParametersValue(formValues)]
      })
    });
  }

  getParametersValue(formValues): string | null {
    if (formValues) {
      return (formValues.definitions && formValues.definitions.parameters ?
               formValues.definitions.parameters[0] && formValues.definitions.parameters[0].value ?
                 formValues.definitions.parameters[0].value : '' : '');
    } else {
      return '';
    }
  }

  save() {
    console.log('val', this.form.value)
    if (this.form.valid) {

      let tmpForm: any = {...this.form.value};

      if(this.gateway) {

        if (tmpForm.definitions.parameters) {
          tmpForm = { ...tmpForm,
            definitions: { ...tmpForm.definitions,
              parameters: { key: 'SECURITY_TOKEN', value: tmpForm.definitions.parameters } } };
        }

        this.gatewayService.updateGateways(tmpForm, this.gateway._id).subscribe(
        (res: any) =>
          this.snackBar.open(res.message, null, { duration: 2000 })
        );
      } else {

        let obj =
        {
          definitions: {
            parameters: { key: 'SECURITY_TOKEN', value: tmpForm.definitions.parameters },
            mid: '',
            type: 'SPAY',
            isNOT3DSecure: true,
            is3DSecure: false,
            is3DSecure2: false
          },
          type: 'IGFS',
          merchant: this.merchantId,
        };

        this.gatewayService.creategateway(obj).subscribe(
        (res: any) =>
          this.snackBar.open(res.message, null, { duration: 2000 })
        );
      }

    }
  }

}
