import { LocationService } from './../../../../../_services/location.service';
import { LoaderService } from './../../../../../_services/loader.service';
import { saveAs } from 'file-saver';
import { StrapiService } from './../../../../../_services/strapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { File, Plugin } from 'src/app/_models/strapi-file';

@Component({
  selector: 'app-plugin-table',
  templateUrl: './plugin-table.component.html',
  styleUrls: ['./plugin-table.component.scss'],
})
export class PluginTableComponent implements OnInit {
  currentPlugin: Plugin;
  displayedColumns = ['name', 'download'];
  currentLanguage: string;
  urlStrapi: string;

  constructor(
    private route: ActivatedRoute,
    private strapiService: StrapiService,
    private loaderService: LoaderService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    let pluginId = this.route.snapshot.paramMap.get('id');
    this.strapiService.urlStrapi$.subscribe((endpoint) => {
      if (endpoint) {
        this.urlStrapi = endpoint;
        this.loaderService.startLoader();
        this.strapiService.getPlugin(pluginId).subscribe(
          (plugin: Plugin) => {
            this.currentPlugin = plugin;
            this.loaderService.stopLoader();
          },
          (error) => this.loaderService.stopLoader()
        );
      }
    });

    this.locationService.currentLanguage$.subscribe(
      (language: string) => (this.currentLanguage = language)
    );

  }

  downloadPlugin(file: File) {
    if (!file) {
      return;
    } else {
      this.strapiService
        .downloadFile(file.url)
        .subscribe((blob) => saveAs(blob, `${file.name}`));
    }
  }
}
