import { Component, OnInit } from '@angular/core';
import { MerchantService } from 'src/app/_services/merchant.service';
import { IntegrationService } from 'src/app/_services/integration.service';
import { PaypalService } from 'src/app/_services/paypal.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { IconRegistryService } from 'src/app/_services/icon-registry.service';
import { GatewayService } from 'src/app/_services/gateway.service';
import { ConfirmDialogComponent } from '../../../../../../_components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {

  merchantID: string;
  productionMerchantID: string;
  action_url: string;
  form: UntypedFormGroup;
  gatewayID: string;
  partnerMerchantId: string;
  isProduction: boolean = false;

  error = $localize`:@@error: Errore`;
  genericError = $localize`:@@interceptor-genericError: Errore Generico`;


  constructor(
    private merchantService: MerchantService,
    private integrationService: IntegrationService,
    private paypalService: PaypalService,
    private gatewayService: GatewayService,
    private loaderService: LoaderService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private iconRegistryService: IconRegistryService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.iconRegistryService.registryIconTransaction();
    this.productionMerchantID = this.merchantService.currentProductionMerchant ? this.merchantService.currentProductionMerchant._id : null;
    this.merchantID = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    this.activatedRoute.queryParams.subscribe((q) => {
      if (q) {
        this.partnerMerchantId = q.merchantIdInPayPal;
        this.merchantID = !this.merchantID ? q.merchantId : this.merchantID;
      }
    });
    this.activatedRoute.url.subscribe(el => {
      el.forEach(element => {
        if (element.toString().includes('production')) {
          this.isProduction = true;
        }
      });
    });

    this.initialize();
  }

  buildForm(formValues?): UntypedFormGroup {
    return this.fb.group({
      _id: [''],
      merchant: ['', [Validators.required]],
      type: ['PAYPAL', [Validators.required]],
      key: [''],
      definitions: this.fb.group(<any>{
        client_id: [(formValues ? formValues.definitions.client_id : ''), [Validators.required]]
      })
    });
  }

  initialize() {
    this.loaderService.startLoader();
    this.form = this.buildForm();
    const queries = [{ key: 'type', value: 'PAYPAL' }, { key: 'merchant', value: this.merchantID }];
    const ob$ = this.isProduction
    ? this.gatewayService.getSyncGateways(this.productionMerchantID).pipe(map(res => res.items.find(el => el.type === 'PAYPAL')))
    : this.merchantService.filterGatewaysByQuery(queries, true).pipe(map(res => res.items[0]));
    ob$.subscribe(
      gateway => {
        if (!gateway) {
          this.form.controls.merchant.setValue(this.isProduction ? this.productionMerchantID : this.merchantID);
        }
        this.patchForm(gateway);
        if (this.partnerMerchantId) {
          this.onMerchantOnBoardingCompleted();
        } else {
          this.getLicenseKeyAndCreatePartner();
        }
      },
      error => {
        this.errorDialog(this.errorHandler(error));
        this.loaderService.stopLoader();
      }
    );
  }

  onMerchantOnBoardingCompleted() {
    this.form.controls.definitions.get('client_id').setValue(this.partnerMerchantId);
    if (this.gatewayID) {
      this.updateGateway();
    } else {
      this.createGateway();
    }
  }

  createGateway() {
    const gateway = this.form.value;
    delete gateway._id;
    const ob$ = this.isProduction
    ? this.gatewayService.updateSyncGateways(gateway, this.productionMerchantID)
    : this.merchantService.newGateway(gateway).pipe(tap(
      () => this.merchantService.newGateway(gateway)
    ));
    ob$.subscribe(
      data => {
        this.handleGatewayResponse(data);
        this.patchForm(data);
      },
      error => {
        this.snackBar.open(error.message);
        this.loaderService.stopLoader();
      }
    );
  }

  updateGateway() {
    const gateway = this.form.value;
    const ob$ = this.isProduction
    ? this.gatewayService.updateSyncGateways(gateway, this.productionMerchantID)
    : this.merchantService.modifyGatewayById(this.form.value._id, gateway);
    ob$.subscribe(
      (result: any) => {
        this.handleGatewayResponse(result);
        const path = this.isProduction ? 'production' : 'sandbox';
        this.router.navigate(['/' + path + '/configuration/payment-methods/paypal']);
      },
      error => {
        this.snackBar.open(error.message);
        this.loaderService.stopLoader();
      }
    );
  }

  handleGatewayResponse(data: any) {
    this.loaderService.stopLoader();
    this.form.markAsPristine();
    this.snackBar.open(data.message, null, { duration: 2000 });
  }

  patchForm(gateway: any) {
    this.gatewayID = gateway ? gateway._id : null;
    if (gateway) {
      this.form.patchValue(gateway);
    }
  }

  getLicenseKeyAndCreatePartner() {
    this.loadExternalScript().then(() => {
      const ob$ = this.isProduction
      ? this.integrationService.getProdServerLicense()
      : this.integrationService.getServerLicense(this.merchantID);
      ob$.subscribe(
        (serverIntegration: any) => {
          const licenseServer = serverIntegration ? serverIntegration.license_key : '';
          this.createPartner(licenseServer);
        },
        error => {
          this.loaderService.stopLoader();
          this.errorDialog(this.errorHandler(error));
        }
      );
    });
  }

  createPartner(licenseServer: string) {
    const ob$ = this.isProduction
    ? this.paypalService.createPartnerSync(licenseServer)
    : this.paypalService.createPartner(licenseServer);
    ob$.subscribe(
      res => {
        this.loaderService.stopLoader();
        if (res && res.code === 200) {
          this.action_url = res.action_url;
        } else {
          this.snackBar.open(res.message, null, { duration: 2000 });
        }
      },
      error => {
        this.errorDialog(this.errorHandler(error));
        this.loaderService.stopLoader();
      }
    );
  }

  private loadExternalScript() {
    const externalScript = 'https://www.sandbox.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js';
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = externalScript;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  errorDialog(message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { type: 'ALERT', title: this.error, message }
    });
  }

  errorHandler(error) {
    this.loaderService.startLoader();
    if (error.error) {
      if (error.error.message) {
        if (typeof error.error.message === 'string') {
          return error.error.message;
        } else {
          return this.genericError;
        }
      } else {
        return this.genericError;
      }
    } else {
      return error.message;
    }
  }

}
