import { saveAs } from 'file-saver';
import { LoaderService } from './../../../../_services/loader.service';
import { LocationService } from './../../../../_services/location.service';
import { StrapiService } from './../../../../_services/strapi.service';
import { MerchantService } from './../../../../_services/merchant.service';
import { Component, OnInit } from '@angular/core';
import { File, Pdf } from 'src/app/_models/strapi-file';


@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  listPdf: Array<Pdf> = [];
  currentLanguage: string;

  constructor(
    public merchantService: MerchantService,
    private strapiService: StrapiService,
    private locationService: LocationService,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit(): void {
    this.strapiService.urlStrapi$.subscribe(endpoint => {
      if (endpoint) {
        this.loaderService.startLoader();
        this.strapiService.getDocuments().subscribe(
          pdfs => {
            this.listPdf = pdfs;
            this.loaderService.stopLoader();
          },
          error => this.loaderService.stopLoader()
          );
      }
    });
    this.locationService.currentLanguage$.subscribe(resp => this.currentLanguage = resp);
  }

  openPdf(file: File) {

    if(!file) {
      return;
    } else {
      this.strapiService.downloadFile(file.url).subscribe(blob => {
        saveAs(blob, `${file.name}`);
      }
    );
    }

  }

  showPdf(pdf: Pdf) {
    if (!pdf) return false;

    return pdf.visibility === 'public' || 
      (pdf.visibility === 'private' && (this.merchantService.currentMerchantRoleOneOf(["USER", "SANDBOX_USER"])));
  }

}
