import { IOperationDTO } from './ioperation-dto';
import { PaymentDTO } from './payment-dto';
import { Operation } from './operation';
import { Payment } from './payment';
import { Transaction } from './transaction';

export class PaymentMap {
    public static toView(domainObj: IOperationDTO): Operation {
        if (domainObj.payment_type === 'RECURRENT') {
            return new Operation(domainObj, 'RECURRENT');
        }
        if (!domainObj.transactions || domainObj.transactions.length < 1) {
            return new Operation(domainObj, domainObj.state);
        }
        const transactionInit = domainObj.transactions.find(el => el.operation_type === 'INIT');
        const transaction = transactionInit ? transactionInit : domainObj.transactions[domainObj.transactions.length - 1];

        if (!transaction.outcome) {
            const state = domainObj.state === 'COMPLETED' ? 'MISSING_DATA' : domainObj.state;
            return new Operation(domainObj, state, transaction)
        }

        const gateway = this.checkGatewayType(transaction);
        const state = domainObj.state === 'COMPLETED' ? this.statoTransazione(transaction) : domainObj.state

        switch (gateway) {
            case 'IGFS':
                return Operation.createFromIGFS(domainObj, transaction, state);
            case 'IGFS_2':
                return Operation.createFromIGFS2(domainObj, transaction, state);      
            case 'COMPUTOP':
                return Operation.createFromComputop(domainObj, transaction, state);
            case 'MYBANK':
                return Operation.createFromMyBank(domainObj, transaction, state)
            case 'PAYPAL':
                return Operation.createFromPayPal(domainObj, transaction, state);
        }
      
    }

    public static toDomain(formValue: Payment) {
        return new PaymentDTO(formValue);
    }

    static checkGatewayType(transaction: any) {
        if (transaction.gateway !== 'IGFS') {
          return transaction.gateway;
        }
        if (transaction.outcome.txRes || transaction.outcome.transStatus) {
          return 'IGFS_2';
        } else {
          return 'IGFS';
        }
    }

    static statoTransazione(obj: Transaction) {
        return obj.state === 'PG_000' || obj.state === 'PG000' ? 'success' : 'failed';
    }
}
