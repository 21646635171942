import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import {ConfigurationService} from '../../_services/configuration.service';
import {IntegrationService} from '../../_services/integration.service';
import {MerchantService} from '../../_services/merchant.service';

@Component({
  selector: 'app-select-multimerchant',
  templateUrl: './select-multimerchant.component.html',
  styleUrls: ['./select-multimerchant.component.scss']
})
export class SelectMultimerchantComponent implements OnInit {
  _licenseArray: any;

  constructor(
    private merchantService: MerchantService,
    private integrationService: IntegrationService
  ) {}


  @Input() set licenseArray(arr) {
    if (!arr) { return; }
    this._licenseArray = arr;
    this.createOption();
  }

  get licenseArray() {return this._licenseArray}

  @Output() slectMerchant = new EventEmitter();
  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.integrationService.getProductionLicense().subscribe((license: any) => {
      this.merchantService.currentProductionMerchant = license.items[0] ? license.items[0] : null;
      if (this.merchantService.currentProductionMerchant) {
      this.slectMerchant.emit(this.merchantService.currentProductionMerchant);
      this.myControl.setValue(this.merchantService.currentProductionMerchant.name +' - '+ this.merchantService.currentProductionMerchant.signboard + ' - MID ' + this.merchantService.currentProductionMerchant.merid);
      }
    });
  }

  createOption() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): any {
    let filterValue = value.toLowerCase();
    return this._licenseArray.filter(
      option => {
        if (option && option.name.toLowerCase().includes(filterValue) || option.signboard.includes(filterValue) || option.merid.includes(filterValue)) {
          return option;
        }
      });
  }

  currentMerchant(merchant) {
    this.slectMerchant.emit(merchant);
  }

  openSelectAllOptions(event: any) {
    this.createOption();
  }

}
