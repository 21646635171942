<h1 class="save-line-head">Download</h1>
<h4 i18n='@@intro-download'>In quest'area è possibile scaricare Plugin e integrazioni SDK in-App.</h4>

<div class="flex-row">
    <div style="width: 50%; margin-right: 25px;">
        <a routerLink="plugin">
            <mat-card class="card">
                <mat-icon svgIcon='plugin' style="margin-right: 15px;"></mat-icon>
                <ng-container>Plugin</ng-container>
                <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right
                </mat-icon>
            </mat-card>
        </a>
    </div>
    <div style="width: 50%;">
        <a routerLink="sdk">
            <mat-card class="card">
                <mat-icon svgIcon='box' style="margin-right: 15px;"></mat-icon>
                <ng-container>SDK per In-App</ng-container>
                <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right
                </mat-icon>
            </mat-card>
        </a>
    </div>
</div>