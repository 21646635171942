import { StrapiService } from './../../_services/strapi.service';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { File, Pdf } from 'src/app/_models/strapi-file';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-privacy-dialog',
  templateUrl: './privacy-dialog.component.html',
  styleUrls: ['./privacy-dialog.component.scss']
})
export class PrivacyDialogComponent implements OnInit {

  privacyPdf: Pdf ;

  constructor(
    public dialogRef: MatDialogRef<MatDialogClose>,
    private strapiService: StrapiService
  ) {}

  ngOnInit(): void {
    this.strapiService.getDocuments().subscribe(response => this.privacyPdf = response.find(pdf => pdf.visibility === 'privacy_policy'));
  }

  close() {
    this.dialogRef.close();
  }

  openPdf(file: File) {

    if(!file) {
      return;
    } else {
      this.strapiService.downloadFile(file.url).subscribe(blob => {
        saveAs(blob, `${file.name}`);
      }
    );
    }

  }

}
