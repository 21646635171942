import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CardTokenErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.touched && (
      (form.form.controls.txGroup.errors && form.form.controls.txGroup.errors.noCardToken) 
      || control.errors
      );
    }
}

export class InstrTokenErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.touched && (
      (form.form.controls.txGroup.errors && form.form.controls.txGroup.errors.noInstrToken)
      || control.errors
      );
    }
}

export class TxTypeErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.touched && form.form.controls.txGroup.errors && form.form.controls.txGroup.errors.noTxType;
    }
}