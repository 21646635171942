<h1 class="save-line-head">Plugin</h1>

<div class="row">
  <div class="col-6" *ngFor="let plugin of plugins">
    <mat-card class="card" *ngIf="plugin.plugin_versions.length === 0" (click)="downloadPlugin(plugin.file)">
      <img *ngIf="plugin.icon" [src]="urlStrapi + (plugin.icon.url | slice: 1)" width="30px" alt="" style="margin-right: 8px;">
      <ng-container>{{plugin.title_version}}</ng-container>
      <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
    </mat-card>

    <a [routerLink]="[plugin.title_version | lowercase, plugin.id]" *ngIf="plugin.plugin_versions.length > 0">
      <mat-card class="card">
        <img *ngIf="plugin.icon" [src]="urlStrapi + (plugin.icon.url | slice: 1)" width="30px" alt="" style="margin-right: 8px;">
        <ng-container>{{plugin.title_version}}</ng-container>
        <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
    </a>
  </div>
</div>
