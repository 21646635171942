import { LocationService } from './../../_services/location.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MerchantService } from '../../_services/merchant.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  currentLanguage: string;
  constructor(
    public merchantService: MerchantService,
    private router: Router,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.locationService.currentLanguage$.subscribe(resp => this.currentLanguage = resp);
  }


  onChangeLanguage(language: string) {
    let myHref = window.location.href;

    const languages = ['/it/', '/en/', '/fr/'];
    languages.forEach(l => {
      if (myHref.search(l) !== -1) {
        this.currentLanguage = language;
        myHref = myHref.replace(l, '/' + language + '/');
        window.location.replace(myHref);
        this.locationService.setLanguage(this.currentLanguage);
      }
    });

  }

  navigateTo(path: string) {
    this.router.navigateByUrl(path);
  }

  redirect(url) {
    window.open(url, '_blank');
  }

  logout() {
    this.merchantService.logout();
  }

  navigateToSandbox() {
    let href = window.location.href.split('/');
    let ix = href.indexOf('public');
    if (ix === -1) {return; }
    href.splice(ix);
    href.push('sandbox');
    console.log('url: ', href);
    window.location.href = href.join('/');
  }

  leads(){
    this.merchantService.leads().subscribe(res=>{
      if(res){
        this.redirect(res.url)
      }
    })
  }

}
