<div [formGroup]="parts" class="intl-phone-input-container">
    <mat-select matPrefix formControlName="area" class="intl-phone-input-area" aria-label="Area code" (selectionChange)="_handleInput()">
      <mat-select-trigger>
        <div class="flag flag-{{parts.controls.area.value}}"></div>
      </mat-select-trigger>
      <mat-option *ngFor="let country of countries" [value]="country.code">
        <div class="flag flag-{{country.code}}"></div>
        <span> {{ country.name | titlecase }}</span>
      </mat-option>
    </mat-select>
    <input class="intl-phone-input-number" formControlName="subscriber" aria-label="Subscriber" (input)="_handleInput()" [placeholder]="_placeholder">
  </div>
  