import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MerchantService } from '../_services/merchant.service';

@Injectable({
  providedIn: 'root'
})
export class ProdGuard implements CanActivate {
  constructor(
    private merchantService: MerchantService,
    private router: Router,
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.merchantService.checkSession();
      if (this.merchantService.currentMerchant && this.merchantService.accessAllowed) {
        return true;
      } else {
        return this.merchantService.isLoggedIn().pipe(map(
          (isLoggedIn) => {
            if (isLoggedIn && this.merchantService.currentMerchant && this.merchantService.currentMerchant.role === 'USER') {
              return true;
            } else {
              this.router.navigate(['public/home'], {});
              return false;
            }
          }
        ));
      }
    }
  }
