import { MerchantService } from './../../_services/merchant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

interface Integration {
  active: boolean;
  createdAt: string;
  license_key: string;
  merchant: string;
  type: string;
  updatedAt: string;
  _id: string;
}

@Component({
  selector: 'app-license-container',
  templateUrl: './license-container.component.html',
  styleUrls: ['./license-container.component.scss']
})
export class LicenseContainerComponent implements OnInit, OnChanges {

  @Input() licenseArray: any;

  btnActive: string = 'Easy';
  activeKey: boolean = false;
  activeKeyEasy: boolean = false;
  activeKeyApp: boolean = false;

  licenseServer: string;
  licenseWizard: string;
  licenseJS: string;
  licenseAndroid: string;
  licenseIos: string;

  licenseCopied = $localize`:@@snackBarMessage-CopiedLicense:Licenza copiata`;
  licenseNotEnable = $localize`:@@snackBarMessage-LicenseNotEnable:Licenza non abilitata`;

  constructor(
    private snackBar: MatSnackBar,
    public merchantService: MerchantService
  ) {

  }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.licenseArray) {
      const integrations = this.licenseArray;
      integrations.forEach((element: Integration) => {
        switch (element.type) {
          case 'SERVER':
            this.licenseServer = element.license_key;
            break;
          case 'WIZARD':
            this.licenseWizard = element.license_key;
            break;
          case 'JS':
            this.licenseJS = element.license_key;
            break;
          case 'ANDROID':
            this.licenseAndroid = element.license_key;
            break;
          case 'IOS':
            this.licenseIos = element.license_key;
            break;
        }
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  copyLicense(license: string){
    const listener = (e) => {
      e.clipboardData.setData('text/plain', (license ? license : ''));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.openSnackBar(license ? this.licenseCopied : this.licenseNotEnable, '');
  }

}
