<form>
    <mat-form-field class="select-multimerchant" appearance="outline">
        <input (focus)="openSelectAllOptions($event)" type="text" placeholder="Scegli merchant" i18n-placeholder='@@placeholder-chooseMerchant' matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-icon class='arrowIcon' matSuffix>keyboard_arrow_down</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" class='license-select'>
            <mat-option style="padding: 0; font-size: 15px;" *ngFor="let merchantOption of filteredOptions | async" [value]="merchantOption.name + ' - ' + merchantOption.signboard + ' - MID ' + merchantOption.merid" (click)="currentMerchant(merchantOption)">
                <div style="padding: 0 8px;">{{merchantOption.name}} - {{merchantOption.signboard}} - MID {{merchantOption.merid}}</div>
                <mat-divider></mat-divider>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
