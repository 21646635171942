import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {

  constructor(
    private http: HttpClient
  ) { }

  createPartner(license: string){
    let headers = {
      "x-license-key": license,
      "Content-Type":"application/json"
    }
    return this.http.post<any>('/develop/v1/payments/paypal/createPartner', {}, {headers})
  }

  createPartnerSync(license: string){
    let headers = {
      "x-license-key": license,
      "Content-Type":"application/json"
    }
    return this.http.post<any>('/develop/v1/payments/paypal/createPartnerSync', {}, {headers})
  }
}
