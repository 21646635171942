import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MerchantService} from '../../../../_services/merchant.service';

@Component({
  selector: 'app-prod-transactions-summary',
  templateUrl: './prod-transactions-summary.component.html',
  styleUrls: ['./prod-transactions-summary.component.scss']
})
export class ProdTransactionsSummaryComponent implements OnInit {

  constructor(
    private merchantService: MerchantService,
    private router: Router
  ) {
    if (!this.merchantService.currentProductionMerchant) {
      this.router.navigate(['production/configuration/solutions'], {});
    }
  }

  ngOnInit(): void {
  }

  redirect(url) {
    window.open(url, '_blank');
  }

}
