<div mat-dialog-title style="display: flex; justify-content: space-between; border-bottom: 1px solid #DDDDDD;">
    <h3 i18n='@@th-identifiers' align="center">Identificativi</h3>
    <mat-icon style="cursor: pointer;" (click)='close()'>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
  <div style="display: flex; justify-content: space-between;">
    <h4><span style="font-weight: 400;" i18n='@@idpayment'>ID pagamento</span> : {{operationInfo.paymentID}}</h4>
    <div>
      <mat-icon class="dark-icon" style="cursor: pointer;" i18n-matTooltip='@@matTooltip-copyID' svgIcon='copy' matTooltip="Copia ID" matTooltipPosition="above" (click)='copyID(operationInfo.paymentID)'></mat-icon>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between;">
      <h4><span style="font-weight: 400;">Shop ID</span> : {{operationInfo.shop_id}}</h4>
      <div>
          <mat-icon class="dark-icon" style="cursor: pointer;" i18n-matTooltip='@@matTooltip-copyID' svgIcon='copy' matTooltip="Copia ID" matTooltipPosition="above" (click)='copyID(operationInfo.shop_id)'></mat-icon>
      </div>
  </div>
</mat-dialog-content>
