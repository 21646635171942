import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wechatpay',
  templateUrl: './wechatpay.component.html',
  styleUrls: ['./wechatpay.component.scss']
})
export class WechatpayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
