import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private theme: BehaviorSubject<string>;
  public themeApplied: Observable<string>;

  constructor() {
    let localStorageTheme = localStorage.getItem('theme');
    if (localStorageTheme) {
      this.theme = new BehaviorSubject<string>(localStorageTheme)
    } else {
      this.theme = new BehaviorSubject<string>('light-theme');
      localStorage.setItem('theme', 'light-theme');
    }
    this.themeApplied = this.theme.asObservable();

  }


  toggleTheme() {
    let newTheme = this.theme.value === 'light-theme' ? 'dark-theme' : 'light-theme';
    this.theme.next(newTheme);
    localStorage.setItem('theme', newTheme);
  }

}
