import { LoaderService } from './../../_services/loader.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  loaderSubscriber: Subscription;
  started: boolean;

  constructor(private loader: LoaderService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loaderSubscriber = this.loader.loaderStatus$.subscribe(bool => {
      this.started = bool;
      this.cdr.detectChanges();
    });
  }

   ngOnDestroy() {
    if (this.loaderSubscriber !== undefined) {
      this.loaderSubscriber.unsubscribe();
    }
   }

}
