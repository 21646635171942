<h1 i18n='@@title-Documentation' class="save-line-head">Documentazione</h1>
<h4 i18n='@@text-DocumentsDescription'>Qualunque sia la tua tipologia di eCommerce, in questa sezione troverai tutte le
  informazioni utili per integrarti con Worldline.</h4>

<!-- <h4 class='text-intro'>
  <showdown *ngIf="currentLanguage === 'it'" [value]='textIntro.textIntroIT'></showdown>
  <showdown *ngIf="currentLanguage === 'en'" [value]='textIntro.textIntroEN'></showdown>
</h4> -->

<div class="row">
  <ng-container *ngFor="let pdfHome of listPdfHome">
    <div class="col-6">
      <mat-card class="card" (click)="openPdf(pdfHome.file)">
        <mat-icon svgIcon='download' style="margin-right: 15px;"></mat-icon>
        <ng-container *ngIf="currentLanguage === 'it'">{{pdfHome.title_version_IT}}</ng-container>
        <ng-container *ngIf="currentLanguage === 'en'">{{pdfHome.title_version_EN}}</ng-container>
        <ng-container *ngIf="!currentLanguage">{{pdfHome.title_version_EN}}</ng-container>
        <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
    </div>
  </ng-container>
</div>

<div class="flex-row" *ngIf="!merchantService.accessAllowed">
  <div style="width: 50%; margin-right: 25px;">
      <mat-card class="card" (click)="navigateToSandbox()">
        <mat-icon svgIcon='gestisci' style="margin-right: 15px;"></mat-icon>
        <ng-container i18n='@@card-StartTesting'>Inizia il test</ng-container>
        <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
  </div>
  <div style="width: 50%;"></div>
</div>
