import { MerchantService } from './_services/merchant.service';
import { IconRegistryService } from './_services/icon-registry.service';
import { ThemeService } from './_services/theme.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, Inject, LOCALE_ID, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DrawerService } from './_services/drawer.service';
import { ConfigurationService } from './_services/configuration.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('rightDrawer', {static: false}) rightDrawer: MatDrawer;
  title = 'pg-develop-ng';
  theme: string;
  showOpener: boolean;
  myRoute: Array<string> = [];

  constructor(
    @Inject(LOCALE_ID) protected locale: string,
    private themeService: ThemeService,
    private iconRegistryService: IconRegistryService,
    public merchantService: MerchantService,
    private router: Router,
    private renderer: Renderer2,
    private overlayContainer: OverlayContainer,
    public drawerService: DrawerService,
    private confService: ConfigurationService
  ) {
    // Icon registry
    this.iconRegistryService.registryIcon();
  }

  ngOnInit() {
    this.confService.getAccessEnv().subscribe((env:any)=>{
      this.confService.accessEnv$.next(env.accessEnv)
    })
    this.router.events.subscribe(
      value => {
        if (value instanceof NavigationEnd) {
          this.myRoute = this.router.url.split('/');
          if (this.myRoute[1] === 'public') {
            this.showOpener = false;
            this.drawerService.open();
            this.rightDrawer.open();
          }
        }
      }
    );
    // subscribe to theme changes and toggle theme css class on body and overlay container
    this.themeService.themeApplied.subscribe(
      theme => {
        if (this.theme) {
          this.renderer.removeClass(document.body, this.theme);
          this.overlayContainer.getContainerElement().classList.remove(this.theme);
        }
        this.renderer.addClass(document.body, theme);
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.theme = theme;
      }
    );

  }

  // Change Theme
  toggleTheme() {
    this.themeService.toggleTheme();
  }

  drawerToggle() {
    if (!this.rightDrawer.opened) {
      this.showOpener = false;
    }
    this.rightDrawer.toggle();
    this.rightDrawer.opened ? this.drawerService.open() : this.drawerService.close();
    this.rightDrawer._animationEnd.subscribe(
      value => {
        this.showOpener = true;
        if (!this.rightDrawer.opened) {
        }
    });
  }

  redirect(url: string) {
    window.open(url, '_blank');
  }

}
