<h1 class="save-line-head" i18n='@@title-PaymentMethods'>Metodi di pagamento</h1>

<div *ngIf="loaded">
    <div class="flex-row">
        <div style="width: 50%; margin-right: 25px;" *ngIf="typeList.includes('APPLEPAY')"> <!-- Temporary FALSE -->
            <a routerLink="applepay">
                <mat-card class="card">
                    <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
                    <ng-container>Apple Pay</ng-container>
                    <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
                </mat-card>
            </a>
        </div>
        <div style="width: 50%; margin-right: 25px;" *ngIf="typeList.includes('PAYPAL')">
            <a routerLink="paypal">
                <mat-card class="card">
                    <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
                    <ng-container>PayPal</ng-container>
                    <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
                </mat-card>
            </a>
        </div>
        <div style="width: 50%;" *ngIf="typeList.includes('SPAY')">
            <a routerLink="satispay">
                <mat-card class="card">
                    <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
                    <ng-container>Satispay</ng-container>
                    <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
                </mat-card>
            </a>
        </div>
    </div>
</div>

<div *ngIf='loaded && typeList.length === 0'>
    <h3 i18n='@@nogateWay'>Nessun metodo di pagamento alternativo disponibile.</h3>
</div>
