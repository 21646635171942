<div class="content-test" *ngIf="activeLicenseDiv">
    <div class="row-content-test">
        <h4>License Key API:
            <ng-container *ngIf="activeKey === 'licenseServer'"> {{licenseServer ? licenseServer : ' N/A '}}</ng-container>
            <ng-container *ngIf="activeKey !== 'licenseServer'"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon i18n-matTooltip='@@matTooltip-ShowApi-Key' matTooltip="Visualizza API-KEY " matTooltipPosition="above" (click)="setActiveKey('licenseServer')" *ngIf='!activeKey || activeKey !== "licenseServer"' svgIcon='show' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='setActiveKey(null)' *ngIf='activeKey === "licenseServer" && activeKey' svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseServer)'></mat-icon>
        </div>
    </div>
    <!-- licenseWizard -->
    <div class="row-content-test">
        <h4>License Key Easy Checkout:
            <ng-container *ngIf="activeKey === 'licenseWizard'">{{licenseWizard ? licenseWizard : ' N/A '}}</ng-container>
            <ng-container *ngIf="activeKey !== 'licenseWizard'"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon i18n-matTooltip='@@matTooltip-ShowApi-Key' matTooltip="Visualizza API-KEY " matTooltipPosition="above" (click)='setActiveKey("licenseWizard")' *ngIf='!activeKey || activeKey !== "licenseWizard"' svgIcon='show'
                style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='setActiveKey(null)' *ngIf='activeKey === "licenseWizard" && activeKey'  svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseWizard)'></mat-icon>
        </div>
    </div>
    <!-- Smart -->
    <div class="row-content-test">
        <h4>License Key Smart Checkout:
            <ng-container *ngIf="activeKey === 'licenseJS'">{{licenseJS ? licenseJS : ' N/A '}}</ng-container>
            <ng-container *ngIf="activeKey !== 'licenseJS'"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon svgIcon='show' style="margin-right: 8px;" (click)='setActiveKey("licenseJS")' *ngIf='!activeKey || activeKey !== "licenseJS"'></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='setActiveKey(null)' *ngIf='activeKey === "licenseJS" && activeKey' svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseJS)'></mat-icon>
        </div>
    </div>
    <!-- In-App -->
    <div class="row-content-test">
        <h4>License Key In-App Checkout(SDK iOS):
            <ng-container *ngIf="activeKey === 'licenseIos'">{{licenseIos ? licenseIos : ' N/A '}}</ng-container>
            <ng-container *ngIf="activeKey !== 'licenseIos'"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon (click)='setActiveKey("licenseIos")' *ngIf='!activeKey || activeKey !== "licenseIos"' svgIcon='show'
                style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='setActiveKey(null)' *ngIf='activeKey === "licenseIos" && activeKey' svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseIos)'></mat-icon>
        </div>
    </div>
    <div class="row-content-test">
        <h4>License Key In-App Checkout(SDK Android):
            <ng-container *ngIf="activeKey === 'licenseAndroid'">{{licenseAndroid ? licenseAndroid : ' N/A '}}</ng-container>
            <ng-container *ngIf="activeKey !== 'licenseAndroid'"> ******* ******* ******* *******</ng-container>
        </h4>
        <div>
            <mat-icon (click)='setActiveKey("licenseAndroid")' *ngIf='!activeKey || activeKey !== "licenseAndroid"' svgIcon='show'
                style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-HideApi-Key' matTooltip="Nascondi API-KEY" matTooltipPosition="above" (click)='setActiveKey(null)' *ngIf='activeKey === "licenseAndroid" && activeKey' svgIcon='hide' style="margin-right: 8px;"></mat-icon>
            <mat-icon i18n-matTooltip='@@matTooltip-copyLicense' svgIcon='copy' matTooltip="Copia licenza" matTooltipPosition="above" (click)='copyLicense(licenseAndroid)'></mat-icon>
        </div>
    </div>
</div>
