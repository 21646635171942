import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alternative-payments',
  templateUrl: './alternative-payments.component.html',
  styleUrls: ['./alternative-payments.component.scss']
})
export class AlternativePaymentsComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
