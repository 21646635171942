<h1 class="save-line-head" i18n='@@title-PaymentMethods'>Metodi di pagamento</h1>

<div class="flex-row" >
  <div style="width: 50%; margin-right: 25px;" *ngIf="isPayPalActive">
        <a routerLink="paypal">
            <mat-card class="card">
                <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
                <ng-container>PayPal</ng-container>
                <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right
                </mat-icon>
            </mat-card>
        </a>
    </div>
    <div style="width: 50%;" *ngIf="isIGFSActive">
      <a routerLink="satispay">
        <mat-card class="card">
            <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
            <ng-container>Satispay</ng-container>
            <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right
            </mat-icon>
        </mat-card>
    </a>
    </div>
</div>


<div *ngIf='!isPayPalActive'>
    <h3 i18n='@@nogateWay'>Nessun metodo di pagamento alternativo disponibile.</h3>
</div>
