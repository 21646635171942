import { GatewayService } from './../_services/gateway.service';
import { MerchantService } from 'src/app/_services/merchant.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthSettingsGuard implements CanActivate {

  currentMerchantSettingsProduction: any;
  typeListProduction: string[] = [];
  merchantID: string;
  productionMerchantID: string;

  constructor(
    private merchantService: MerchantService,
    private router: Router,
    private gatewayService: GatewayService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentMerchant = this.merchantService.currentMerchant;
    this.merchantID = this.merchantService.currentMerchant ? this.merchantService.currentMerchant._id : null;
    this.productionMerchantID = this.merchantService.currentProductionMerchant ? this.merchantService.currentProductionMerchant._id : null;

    const firstPath = next.url[0].path;
    const lastPath = next.url[3].path;

    if (firstPath === 'sandbox') {

      if (currentMerchant && currentMerchant.settings && currentMerchant.settings.isPAYPALActive) {
        return true;
      } else {
        return this.router.createUrlTree(['sandbox/configuration/payment-methods']);
      }

    }

    if (firstPath === 'production') {

      const ob$ = [this.merchantService.getSyncSettings(this.productionMerchantID), this.gatewayService.getSyncGateways(this.productionMerchantID)];

      return forkJoin(ob$).pipe(map(
        (response: any) => {

          this.typeListProduction = response[1].items.map(el => {
            const type = el.definitions.type ? el.definitions.type : el.type ? el.type : null;

            if (type === 'SPAY') {
              return response[0].item.isIGFSActive &&
                el.definitions.mid && el.definitions.tid && el.definitions.ksig ? 'satispay' : null;
            }

            if (type === 'APPLEPAY') {
              return response[0].item.isIGFSActive ? false : null;
            }
          });
          response[0].item.isPAYPALActive ? this.typeListProduction.push('paypal') : null;

          return this.typeListProduction.includes(lastPath) ? true :
            this.router.createUrlTree(['production/configuration/payment-methods']);
        }
      ));
    }
  }

}
