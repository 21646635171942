import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {

  iconList = ['home', 'light', 'dark', 'logout', 'search', 'email', 'crea', 'show', 'copy', 'hide', 'open-in-new-tab',
              'credit-card', 'check', 'gestisci' , 'plugin' , 'flag', 'credit-card', 'abbonamenti', 'preautorizzazione',
              '3dsecure', 'esito', 'webhook', 'notifiche', 'rimborsi', 'sicurezza', 'box', 'user', 'password', 'configurazione',
              'light-bulb', 'download', 'elenco', 'recurrent', 'outlink'];
  imagesPath = 'assets/icon_axepta_dev/';

  iconListTransaction = [
    {key: 'verde', value: 'scudo-verde'},
    {key: 'rosso', value: 'scudo-rosso'},
    {key: 'success', value: 'completed'},
    {key: 'failed', value: 'error'},
    {key: 'INIT', value: 'init'},
    {key: 'PENDING', value: 'pending'},
    {key: 'PROGRESS', value: 'pending'},
    {key: 'integrazioni', value: 'integrazioni-action'},
    {key: 'test', value: 'test'},
    {key: 'confirm', value: 'confirm'},
    {key: 'credit', value: 'credit'},
    {key: 'void', value: 'void'},
    {key: 'token', value: 'token'},
    {key: 'calendario', value: 'calendario'}
  ];

  imagesPathTransactionIcon = 'assets/images/icons/';


  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) { }

  registryIcon() {
    // Icon registry
    this.iconList.forEach((iconName) => {
      this.iconRegistry.addSvgIcon(
        iconName,
        this.sanitizer.bypassSecurityTrustResourceUrl(this.imagesPath + iconName + '.svg'));
    });
  }

  registryIconTransaction(){
    this.iconListTransaction.forEach((iconName) => {
      this.iconRegistry.addSvgIcon(
        iconName.key,
        this.sanitizer.bypassSecurityTrustResourceUrl(this.imagesPathTransactionIcon + iconName.value + '.svg'));
    });
  }

}
