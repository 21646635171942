<mat-form-field class="searcinput" appearance="outline">
    <input matInput placeholder="Cerca" i18n-placeholder='@@placeholder-Search' [formControl]="myControl" [matAutocomplete]="auto">
    <mat-icon matSuffix class="searchIcon" svgIcon='search' style="transform: translateY(3px);"></mat-icon>
    <!-- Autocomplete container -->
    <mat-autocomplete #auto="matAutocomplete">
      <ng-container *ngFor="let option of filteredOptions | async">
        <mat-option *ngIf="option?.visibility !== 'private' || (option?.visibility === 'private' && merchantService.currentMerchant?.role === 'USER')" 
        [value]="currentLanguage === 'it' ? option?.title_version_IT : option?.title_version_EN" style='padding:0;'>
          <div style='display:flex;justify-content: space-between; align-items:center' (click)="openPdf(option?.file)">
            <span *ngIf="currentLanguage === 'it'" style="padding-left: 0.75em; font-weight: 400;">{{option?.title_version_IT}}</span>
            <span *ngIf="currentLanguage === 'en'" style="padding-left: 0.75em; font-weight: 400;">{{option?.title_version_EN}}</span>
            <span *ngIf="!currentLanguage" style="padding-left: 0.75em; font-weight: 400;">{{option?.title_version_EN}}</span>
            <mat-icon style="text-align:right;margin:0">keyboard_arrow_right</mat-icon>
          </div>
          <mat-divider></mat-divider>
        </mat-option>
      </ng-container>
      
    </mat-autocomplete>
  </mat-form-field>
