import { MerchantService } from './../../_services/merchant.service';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-left-drawer-menu',
  templateUrl: './left-drawer-menu.component.html',
  styleUrls: ['./left-drawer-menu.component.scss']
})
export class LeftDrawerMenuComponent implements OnInit {

  constructor(
    public merchantService: MerchantService
  ) { }

  ngOnInit(): void {

  }

}
