<h1 class="save-line-head">SDK per In-App</h1>
<ng-container *ngIf="!isIosActive && !isAndroidActive">
  <h4 i18n='@@text-sdkapp'>Le modalità d'integrazione relative a In-App Android e In-App-iOS non risultano attive su questa utenza.</h4>
</ng-container>
<div class="row">
    <ng-container *ngFor="let sdk of downloadableSdk">
      <div class="col-6" *ngIf="(isIosActive && sdk.sdk_type === 'ios') || (isAndroidActive && sdk.sdk_type === 'android')">
        <mat-card class="card" style="justify-content: start;" (click)="download(sdk.file)">
          <mat-icon svgIcon='box' style="margin-right: 15px;"></mat-icon>
          <ng-container>{{sdk.title_version}}</ng-container>
          <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
      </mat-card>
    </div>
    </ng-container>

</div>