<h1 class="save-line-head">Pagamenti alternativi</h1>

<div class="flex-row">
    <div style="width: 50%; margin-right: 25px;">
        <a routerLink="alipay">
            <mat-card class="card">
                <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
                <ng-container>Alipay</ng-container>
                <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right
                </mat-icon>
            </mat-card>
        </a>
    </div>
    <div style="width: 50%;">
        <a routerLink="wechat-pay">
            <mat-card class="card">
                <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
                <ng-container>WeChat Pay</ng-container>
                <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right
                </mat-icon>
            </mat-card>
        </a>
    </div>
</div>