<!-- Table Shared-->
<table mat-table [dataSource]="dataSource" [attr.aria-label]="ariaLabel">
    <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(columnHeader); let i = index">
      <th mat-header-cell *matHeaderCellDef [attr.aria-label]="columnHeader[tableData]"> {{columnHeader[tableData]}}</th>
      <td mat-cell *matCellDef="let element"[attr.aria-label]="element ? element[tableData].url : element[tableData]">
        <ng-container *ngIf="element[tableData].url">
          <a target="_blank" href={{element[tableData].url}} style="text-decoration: none; color: inherit">
            {{element[tableData].url}}
            <mat-icon svgIcon='{{element[tableData].icon}}' style="width: 18px; height: 18px;"></mat-icon>
          </a>
        </ng-container>

        <ng-container *ngIf="!(element[tableData].url)">
          {{element[tableData]}}
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader)"></tr>
    <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader)"></tr>
</table>
