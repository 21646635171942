<h1 class="save-line-head" i18n='@@title-Configuration'>Configurazione</h1>

<div class="flex-row">
    <div style="width: 50%; margin-right: 25px;">
        <a routerLink="solutions">
            <mat-card class="card">
            <mat-icon svgIcon='light-bulb' style="margin-right: 15px;"></mat-icon>
            <ng-container i18n='@@title-Solutions'>Soluzioni</ng-container>
            <mat-icon class="material-icons" style="flex: 1 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
        </mat-card>
        </a>
    </div>
    <div style="width: 50%;">
        <a routerLink="payment-methods">
            <mat-card class="card">
            <mat-icon svgIcon='credit-card' style="margin-right: 15px;"></mat-icon>
            <ng-container  i18n='@@title-PaymentMethods'>Metodi di pagamento</ng-container>
            <mat-icon class="material-icons" style="flex: 5 1 auto; text-align: end;">keyboard_arrow_right</mat-icon>
        </mat-card>
        </a>
    </div>
</div>